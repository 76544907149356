import ContentLoader from "react-content-loader";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { SidebarSkeletonWrapper } from "@Components/Sidebar/components/MainSideBar/MainSideBar.styled";

export default function SideBarProfileSkeleton() {
  const {
    i18n: { language },
  } = useTranslation();
  const [direction, setDirection] = useState(false);

  useEffect(() => {
    if (language === "ar") {
      setDirection(true);
    } else {
      setDirection(false);
    }
  }, [language]);

  return (
    <SidebarSkeletonWrapper direction={direction}>
      <ContentLoader speed={2} width={"100%"} height="52px" backgroundColor="#ffffff33" foregroundColor="#ffffff66">
        <rect x="0" y="5" rx="20" ry="20" width="44px" height="44px" />
        <rect x="50" y="12" rx="4" ry="4" width="70%" height="10px" />
        <rect x="50" y="32" rx="4" ry="4" width="50%" height="10px" />
      </ContentLoader>
    </SidebarSkeletonWrapper>
  );
}
