export const SheetIcon = () => {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 11.3047H6.41406C6.64323 11.3047 6.82812 11.2344 6.96875 11.0938C7.10938 10.9531 7.17969 10.7734 7.17969 10.5547V9.10938C7.17969 8.88542 7.10938 8.70573 6.96875 8.57031C6.82812 8.42969 6.64323 8.35938 6.41406 8.35938H4.5C4.27083 8.35938 4.08594 8.42969 3.94531 8.57031C3.80469 8.70573 3.73438 8.88542 3.73438 9.10938V10.5547C3.73438 10.7734 3.80469 10.9531 3.94531 11.0938C4.08594 11.2344 4.27083 11.3047 4.5 11.3047ZM1.625 5.57812H18.7266V3.80469H1.625V5.57812ZM3.44531 14.0391H16.8984C17.7161 14.0391 18.3281 13.8359 18.7344 13.4297C19.1458 13.0286 19.3516 12.4271 19.3516 11.625V3.11719C19.3516 2.3151 19.1458 1.71094 18.7344 1.30469C18.3281 0.898438 17.7161 0.695312 16.8984 0.695312H3.44531C2.6276 0.695312 2.01302 0.898438 1.60156 1.30469C1.19531 1.70573 0.992188 2.3099 0.992188 3.11719V11.625C0.992188 12.4271 1.19531 13.0286 1.60156 13.4297C2.01302 13.8359 2.6276 14.0391 3.44531 14.0391ZM3.46094 12.7812C3.07031 12.7812 2.77083 12.6797 2.5625 12.4766C2.35417 12.2682 2.25 11.9609 2.25 11.5547V3.1875C2.25 2.78125 2.35417 2.47396 2.5625 2.26562C2.77083 2.05729 3.07031 1.95312 3.46094 1.95312H16.8828C17.2682 1.95312 17.5651 2.05729 17.7734 2.26562C17.987 2.47396 18.0938 2.78125 18.0938 3.1875V11.5547C18.0938 11.9609 17.987 12.2682 17.7734 12.4766C17.5651 12.6797 17.2682 12.7812 16.8828 12.7812H3.46094Z"
        fill="#AE8CCC"
      />
    </svg>
  );
};
