import { CommonButton } from "@Components/common/CommonButton";
import Rodal from "rodal";
import styled from "styled-components";

export const StyledRodal = styled(Rodal)<{ readonly isDelete?: boolean }>`
  .rodal-mask {
    background: rgba(0, 0, 0, 0.3);
  }
  .rodal-dialog {
    min-width: ${({ isDelete }) => (isDelete ? "382px" : "534px")} !important;
    min-height: ${({ isDelete }) => (isDelete ? "246px" : "664px")};
    max-height: ${({ isDelete }) => (isDelete ? "246px" : "664px")};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 20px;
  }
`;

export const HeaderTitle = styled.span`
  flex: 1;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray6};
`;

export const Headerwapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PopupBody = styled.div<{ readonly hasError?: boolean }>`
  margin-right: 4px;
  padding: 16px 24px;
  min-height: ${({ hasError }) => (hasError ? "460px" : "485px")};
  max-height: ${({ hasError }) => (hasError ? "460px" : "485px")};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray5};
    border-radius: 9px;
  }
`;

export const PopupFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 24px;
  margin-top: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray6};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const CancleButton = styled(CommonButton)`
  flex: 1;
`;

export const SaveButton = styled(CommonButton)`
  flex: 1;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

export const ActionButtonsWrapper = styled.div`
  padding-top: 16px;
  margin-top: 16px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray6};
  }
`;

export const EditPriceMessage = styled.p`
  margin: 16px 0px;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
`;

export const AddCourseMessage = styled(EditPriceMessage)`
  margin: 0px;
`;

export const DeleteSubject = styled(CommonButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #fde5e5;
  box-shadow: none;
`;

export const DeleteTitle = styled.span<{ readonly isDisabled?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.white : theme.colors.red)};
  text-transform: capitalize;
`;

export const ErrorType = styled.span<{ readonly center?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
  padding: 6px;
  margin: 0 auto;
  display: flex;
  justify-content: ${({ center }) => (center ? "flex-start" : "center")};
  margin: 0 auto;
`;
