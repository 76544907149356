import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import {
  AlertButton,
  AlertPopupButtons,
  AlertPopupHeader,
  AlertPopupMessage,
  StyledAlertRodal,
} from "./EmptySectionAlertPopup.styled";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
};

const EmptySectionAlertPopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");

  return (
    <StyledAlertRodal visible={isOpen} onClose={() => closePopup(false)}>
      <AlertPopupHeader>{tCommon("empty_section")}</AlertPopupHeader>
      <AlertPopupMessage>{tCommon("empty_section_message")}</AlertPopupMessage>
      <AlertPopupButtons>
        <AlertButton variant="fullWidth" onClick={() => closePopup(false)}>
          {tCommon("ok")}
        </AlertButton>
      </AlertPopupButtons>
    </StyledAlertRodal>
  );
};

export default EmptySectionAlertPopup;
