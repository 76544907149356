export const CameraIcon = () => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.49707 11.3533H10.3005C11.0947 11.3533 11.7154 11.1341 12.1624 10.6957C12.6138 10.253 12.8395 9.63452 12.8395 8.84025V3.15666C12.8395 2.36673 12.6138 1.75258 12.1624 1.31421C11.7154 0.871501 11.0947 0.650146 10.3005 0.650146H3.49707C2.73752 0.650146 2.12337 0.871501 1.65462 1.31421C1.19021 1.75258 0.958008 2.36673 0.958008 3.15666V8.84025C0.958008 9.63452 1.18153 10.253 1.62858 10.6957C2.07997 11.1341 2.7028 11.3533 3.49707 11.3533ZM3.67936 10.3702C3.14551 10.3702 2.73318 10.2313 2.44238 9.95353C2.15158 9.67575 2.00618 9.25475 2.00618 8.69051V3.31291C2.00618 2.74867 2.15158 2.32766 2.44238 2.04989C2.73318 1.76777 3.14551 1.62671 3.67936 1.62671H10.1182C10.652 1.62671 11.0643 1.76777 11.3551 2.04989C11.6459 2.32766 11.7913 2.74867 11.7913 3.31291V8.69051C11.7913 9.25475 11.6459 9.67575 11.3551 9.95353C11.0643 10.2313 10.652 10.3702 10.1182 10.3702H3.67936ZM12.6898 4.17879V5.41577L15.7887 2.85718C15.8191 2.83548 15.8452 2.81812 15.8669 2.80509C15.8929 2.78773 15.9189 2.77905 15.945 2.77905C16.0188 2.77905 16.0557 2.83114 16.0557 2.9353V9.06812C16.0557 9.16794 16.0188 9.21785 15.945 9.21785C15.9189 9.21785 15.8929 9.21134 15.8669 9.19832C15.8452 9.18096 15.8191 9.1636 15.7887 9.14624L12.6898 6.58765V7.81812L15.3005 10.0317C15.4307 10.1402 15.5652 10.2291 15.7041 10.2986C15.843 10.3637 15.9797 10.3962 16.1143 10.3962C16.4007 10.3962 16.6307 10.3008 16.8044 10.1098C16.978 9.91881 17.0648 9.67141 17.0648 9.36759V2.63582C17.0648 2.332 16.978 2.08461 16.8044 1.89364C16.6307 1.70266 16.4007 1.60718 16.1143 1.60718C15.9797 1.60718 15.843 1.6419 15.7041 1.71134C15.5652 1.77645 15.4307 1.86325 15.3005 1.97176L12.6898 4.17879Z"
        fill="#13AF72"
      />
    </svg>
  );
};
