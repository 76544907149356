export const RightArrowIcon = ({ arrowColor }: { arrowColor?: any }) => {
  return (
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.57031 5.2857C5.57031 5.14138 5.51127 5.01018 5.40632 4.89867L1.35239 0.870979C1.25399 0.766024 1.11624 0.713545 0.965355 0.713545C0.657049 0.713545 0.427455 0.943137 0.427455 1.25145C0.427455 1.40232 0.4865 1.53351 0.584893 1.63847L4.24524 5.2857L0.584893 8.93292C0.4865 9.03788 0.427455 9.17564 0.427455 9.31995C0.427455 9.62826 0.657049 9.85785 0.965355 9.85785C1.11624 9.85785 1.25399 9.80537 1.35239 9.70041L5.40632 5.67273C5.51127 5.56121 5.57031 5.43001 5.57031 5.2857Z"
        fill={arrowColor ? "#454545" : "#8E8E8E"}
      />
    </svg>
  );
};
