import Sidebar from "@Components/Sidebar";
import LanguageMenu from "@Components/Sidebar/components/LanguageMenu";
import TeacherLangMenu from "@Components/Sidebar/components/TeacherLangMenu";
import React, { useEffect, useRef, useState } from "react";
import { BackGround, LayoutWrapper, PageContent } from "./Layout.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function Layout({ children }: Props) {
  const divRef = useRef(null);
  const { userData, subjectSidebar } = userInfo();
  const { isSectionsSorted, worningSectionsSortPopup, worningSortType } = useTablesFilters();
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [openLangMenu, setOpenLangMenu] = useState(false);

  useEffect(() => {
    if (isSectionsSorted && worningSectionsSortPopup && worningSortType === "sections") {
      divRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isSectionsSorted, worningSectionsSortPopup, worningSortType]);

  return (
    <LayoutWrapper>
      <Sidebar setOpenLogoutMenu={setOpenLogoutMenu} />
      <PageContent ref={divRef}>{children}</PageContent>
      {openLogoutMenu && (
        <BackGround
          onClick={() => {
            setOpenLogoutMenu(false);
            setOpenLangMenu(false);
          }}
        />
      )}
      {userData?.roles.includes(ADMIN) ? (
        <LanguageMenu
          openLogoutMenu={openLogoutMenu}
          setOpenLogoutMenu={setOpenLogoutMenu}
          openLangMenu={openLangMenu}
          setOpenLangMenu={setOpenLangMenu}
        />
      ) : subjectSidebar && !userData?.roles.includes(ADMIN) ? (
        <LanguageMenu
          openLogoutMenu={openLogoutMenu}
          setOpenLogoutMenu={setOpenLogoutMenu}
          openLangMenu={openLangMenu}
          setOpenLangMenu={setOpenLangMenu}
        />
      ) : !subjectSidebar && !userData?.roles.includes(ADMIN) ? (
        <TeacherLangMenu
          openLogoutMenu={openLogoutMenu}
          setOpenLogoutMenu={setOpenLogoutMenu}
          openLangMenu={openLangMenu}
          setOpenLangMenu={setOpenLangMenu}
        />
      ) : (
        <></>
      )}
    </LayoutWrapper>
  );
}
