import { SortingIconContainer } from "@Components/TableLayout/TableLayout.styled";

type Props = {
  arrowTopColor: string;
  arrowBottomColor: string;
};

const SortingIcon = ({ arrowTopColor, arrowBottomColor }: Props) => {
  return (
    <SortingIconContainer>
      <svg width="7" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.56569 5.43431L9.63432 1.36569C10.1383 0.861714 9.78135 0 9.06863 0H0.931371C0.218647 0 -0.138286 0.861714 0.365686 1.36569L4.43431 5.43431C4.74673 5.74673 5.25327 5.74673 5.56569 5.43431Z"
          fill={arrowTopColor}
        />
      </svg>
      <svg width="7" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.56569 5.43431L9.63432 1.36569C10.1383 0.861714 9.78135 0 9.06863 0H0.931371C0.218647 0 -0.138286 0.861714 0.365686 1.36569L4.43431 5.43431C4.74673 5.74673 5.25327 5.74673 5.56569 5.43431Z"
          fill={arrowBottomColor}
        />
      </svg>
    </SortingIconContainer>
  );
};

export default SortingIcon;
