export const SubjectIcon = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.85187 17.37H11.1487C11.956 17.37 12.5602 17.1643 12.9612 16.7528C13.3675 16.3466 13.5706 15.7372 13.5706 14.9247V7.83093C13.5706 7.49239 13.5524 7.20854 13.5159 6.97937C13.4795 6.7502 13.4066 6.54187 13.2972 6.35437C13.193 6.16166 13.0368 5.95854 12.8284 5.745L8.52374 1.36218C8.32583 1.15906 8.13312 1.00541 7.94562 0.901245C7.75812 0.79187 7.55239 0.718953 7.32843 0.682495C7.10968 0.640828 6.85447 0.619995 6.56281 0.619995H2.85187C2.04458 0.619995 1.43781 0.825724 1.03156 1.23718C0.630514 1.64864 0.429993 2.26062 0.429993 3.07312V14.9247C0.429993 15.7424 0.630514 16.3544 1.03156 16.7606C1.43781 17.1669 2.04458 17.37 2.85187 17.37ZM2.90656 16.1122C2.50551 16.1122 2.20083 16.008 1.99249 15.7997C1.78937 15.5913 1.68781 15.2919 1.68781 14.9012V3.09656C1.68781 2.71114 1.78937 2.41166 1.99249 2.19812C2.20083 1.98458 2.50812 1.87781 2.91437 1.87781H6.39093V6.41687C6.39093 6.90645 6.51333 7.27364 6.75812 7.51843C7.00291 7.76322 7.3701 7.88562 7.85968 7.88562H12.3128V14.9012C12.3128 15.2919 12.2086 15.5913 12.0003 15.7997C11.7972 16.008 11.4925 16.1122 11.0862 16.1122H2.90656ZM8.00031 6.70593C7.84406 6.70593 7.73208 6.67208 7.66437 6.60437C7.60187 6.53666 7.57062 6.42468 7.57062 6.26843V2.12L12.0706 6.70593H8.00031Z"
        fill="white"
      />
    </svg>
  );
};
