import styled from "styled-components";

export const NoResultWrapper = styled.div<{ readonly isCoursePage?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ isCoursePage }) => (isCoursePage ? "50vh" : "70vh")};
`;

export const LampIconContainer = styled.div`
  margin-bottom: 68px;
`;

export const NoResultMessage = styled.p`
  max-width: 384px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`;
