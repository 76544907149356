//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import { FileTypeWrapper, LectureNameWrapper, SectionErrorMessage } from "./Section.styled";
import { TeachersDatasContext } from "context/Teachers.context";
import { ArrowDownIcon } from "images/icons/ArrowDownIcon";
import { DragDropIcon } from "images/icons/DragDropIcon";
import { EditIcon } from "images/icons/EditIcon";
import moment from "moment";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import { secondsToHms } from "utils/Time";
import CircularProgress from "@mui/material/CircularProgress";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import {
  Dot,
  EditIconWrapper,
  IntroDurationWrapper,
  SectionActiosnWrapper,
  SwitchButton,
  SwitchContainer,
  Switcher,
} from "../Introduction/Introduction.styled";
import {
  ArrowWrapper,
  EditButton,
  LectureTypeName,
  SectionContainer,
  SectionInfo,
  SectionName,
  SectionWrapper,
  StyledTable,
  TableBody,
  TableBodyth,
  TableBodyTr,
  TableHead,
  TableHeadTh,
  TableHeadTr,
  TeacherName,
} from "./Section.styled";
import { _GetLecture, _UpdateFileLecture, _UpdateVideoLecture } from "@Services/Lecture";
import { useQuery } from "react-query";
import { useTablesFilters } from "context/TablesFilters.context";
import { _UpdateSection } from "@Services/Sections";
import { userInfo } from "context/UserInfo.context";

type Props = {
  editSection: Dispatch<SetStateAction<boolean>>;
  editLecture: Dispatch<SetStateAction<boolean>>;
  refProvid?: any;
  snapShots?: any;
  data: any;
  keyId: string;
  keyName: string;
};

const Section = ({ refProvid, editSection, editLecture, snapShots, data, keyId, keyName }: Props) => {
  const [visibleLectureId, setVisibleLectureId] = useState(0);
  const [lectureState, setLectureState] = useState(null);
  const [changeState, setChangeState] = useState(false);
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [isSectionLoading, setIsSectionLoading] = useState(false);
  const [changeSectionStateError, setChangeSectionStateError] = useState(null);
  const { userData } = userInfo();
  const {
    setLectureId,
    setLectureType,
    lectureType,
    courseId,
    refetchCourseDetails,
    teacherId,
    setSectionId,
    courseDetails,
  } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const [openLecture, setOpenLecture] = useState(
    courseDetails?.sections?.filter((section) => section?.is_introductory === false).length === 1 ? true : false
  );
  const getSectionDetails = courseDetails?.sections?.filter((item) => item.id === Number(keyId));
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const { data: getLectureData } = useQuery(
    ["getLectureData", visibleLectureId],
    () => _GetLecture(visibleLectureId, lectureType),
    { enabled: visibleLectureId !== 0 }
  );

  useEffect(() => {
    if (visibleLectureId && getLectureData && lectureType) {
      setIsLoading(true);
      if (lectureType === "file") {
        _UpdateFileLecture(
          visibleLectureId,
          {
            course_id: courseId,
            section_id: getLectureData?.section_id,
            file_name: getLectureData?.file_name,
            file_url: getLectureData?.file_url,
            display_name: {
              en: getLectureData?.display_name?.en,
              tr: getLectureData?.display_name?.tr,
              ar: getLectureData?.display_name?.ar,
            },
            is_file_changed: false,
            is_active: !lectureState,
          },
          teacherId
        )
          .then(() => refetchCourseDetails())
          .finally(() => setIsLoading(false));
      } else {
        _UpdateVideoLecture(
          visibleLectureId,
          {
            course_id: courseId,
            section_id: getLectureData?.section_id,
            video_id: getLectureData?.vimeo_video_id,
            vimeo_account: getLectureData?.vimeo_account,
            display_name: {
              en: getLectureData?.display_name?.en,
              tr: getLectureData?.display_name?.tr,
              ar: getLectureData?.display_name?.ar,
            },
            is_video_changed: false,
            is_active: !lectureState,
          },
          teacherId
        )
          .then(() => refetchCourseDetails())
          .finally(() => setIsLoading(false));
      }
    }
  }, [visibleLectureId, getLectureData, changeState]);

  const changeSectionActiveStatus = (sectionKeyId: number) => {
    setIsSectionLoading(true);
    _UpdateSection(
      {
        vimeo_folder_id: getSectionDetails[0]?.vimeo_folder_id ? getSectionDetails[0]?.vimeo_folder_id : null,
        vimeo_account: getSectionDetails[0]?.vimeo_folder_id ? getSectionDetails[0]?.vimeo_account : "",
        display_name: {
          en: getSectionDetails[0]?.display_name?.en ? getSectionDetails[0]?.display_name?.en : "",
          tr: getSectionDetails[0]?.display_name?.tr ? getSectionDetails[0]?.display_name?.tr : "",
          ar: getSectionDetails[0]?.display_name?.ar ? getSectionDetails[0]?.display_name?.ar : "",
        },
        is_active: !getSectionDetails[0]?.is_active,
      },
      sectionKeyId,
      teacherId
    )
      .then(() => {
        refetchCourseDetails();
      })
      .catch((err) => setChangeSectionStateError(err?.response.data.message))
      .finally(() => {
        setIsSectionLoading(false);
        setTimeout(() => {
          setChangeSectionStateError(null);
        }, 3000);
      });
  };

  return (
    <SectionContainer ref={refProvid.innerRef} {...refProvid.draggableProps}>
      <SectionWrapper isOpened={openLecture} isDragging={snapShots.isDragging}>
        <SectionInfo>
          <div {...refProvid.dragHandleProps}>
            <DragDropIcon color={snapShots.isDragging} />
          </div>
          <SectionName>{keyName}</SectionName>
          <EditButton
            onClick={(e) => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                editSection(true);
                setSectionId(keyId);
              }
            }}
            variant="lightseagule"
          >
            {tCommon("edit")}
          </EditButton>
        </SectionInfo>
        <SectionActiosnWrapper>
          <ArrowWrapper flex onClick={() => setOpenLecture(!openLecture)}>
            <SectionErrorMessage hasError={Boolean(changeSectionStateError)}>
              {changeSectionStateError}
            </SectionErrorMessage>
          </ArrowWrapper>
          <SwitchContainer isDisabled={isRegularInstructor}>
            {isSectionLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <SwitchButton
                isDisabled={isRegularInstructor}
                isActive={getSectionDetails[0]?.is_active}
                onClick={() => {
                  if (userData?.roles.includes(ADMIN) || userData?.roles.includes(PRO_INSTRUCTOR)) {
                    changeSectionActiveStatus(Number(keyId));
                  }
                }}
              >
                <Switcher />
              </SwitchButton>
            )}
          </SwitchContainer>
          <ArrowWrapper onClick={() => setOpenLecture(!openLecture)}>
            <ArrowDownIcon />
          </ArrowWrapper>
        </SectionActiosnWrapper>
      </SectionWrapper>
      <Collapse isOpened={openLecture}>
        <StyledTable>
          {openLecture && (
            <Droppable droppableId={keyId} type="QUOTE" direction="vertical" isCombineEnabled={false}>
              {(dropProvided, dropSnapshot) => (
                <TableBody
                  isDraggingOver={dropSnapshot.isDraggingOver}
                  isDropDisabled={false}
                  isdraggingfrom={Boolean(dropSnapshot.draggingFromThisWith)}
                  {...dropProvided.droppableProps}
                >
                  <TableHead ref={dropProvided.innerRef}>
                    <TableHeadTr>
                      <TableHeadTh>{tCommon("lecture_name")}</TableHeadTh>
                      <TableHeadTh>{tCommon("date_added")}</TableHeadTh>
                      <TableHeadTh>{tCommon("watch")}</TableHeadTh>
                      <TableHeadTh>{tCommon("visibility")}</TableHeadTh>
                      {/* <TableHeadTh>{tCommon("free")}</TableHeadTh> */}
                      <TableHeadTh></TableHeadTh>
                    </TableHeadTr>
                    {data?.columns[keyId]?.map((quote, index) => (
                      <Draggable
                        key={quote.id}
                        draggableId={String(quote.id)}
                        index={index}
                        shouldRespectForceTouch={false}
                      >
                        {(dragProvided, dragSnapshot) => (
                          <TableBodyTr
                            isDragging={dragSnapshot.isDragging}
                            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                            ref={dragProvided.innerRef}
                            {...dragProvided.draggableProps}
                            key={quote.id}
                          >
                            <TableBodyth>
                              <LectureNameWrapper>
                                <span {...dragProvided.dragHandleProps}>
                                  <DragDropIcon color={dragSnapshot.isDragging} />
                                </span>
                                <FileTypeWrapper>
                                  <TeacherName>{quote.name}</TeacherName>
                                  {quote?.video?.id ? (
                                    <Fragment>
                                      {quote?.duration === 0 ? (
                                        <TeacherName>{tCommon("video_optamizing")}</TeacherName>
                                      ) : (
                                        <IntroDurationWrapper>
                                          <LectureTypeName>{tCommon("video")}</LectureTypeName>
                                          <Dot />
                                          <LectureTypeName>{secondsToHms(quote?.video.duration)}</LectureTypeName>
                                        </IntroDurationWrapper>
                                      )}
                                    </Fragment>
                                  ) : quote?.file?.id ? (
                                    <IntroDurationWrapper>
                                      <LectureTypeName>{tCommon("file")}</LectureTypeName>
                                    </IntroDurationWrapper>
                                  ) : (
                                    <></>
                                  )}
                                </FileTypeWrapper>
                              </LectureNameWrapper>
                            </TableBodyth>
                            <TableBodyth>
                              <TeacherName>{moment(quote.created_at).format("DD/MM/YYYY")}</TeacherName>
                            </TableBodyth>
                            <TableBodyth>
                              {quote?.file?.id ? (
                                <TeacherName>-</TeacherName>
                              ) : (
                                <TeacherName>{quote?.watched_percentage ? quote?.watched_percentage : 0}%</TeacherName>
                              )}
                            </TableBodyth>
                            <TableBodyth>
                              <SwitchContainer>
                                {isLoading && quote.id === visibleLectureId ? (
                                  <CircularProgress color="inherit" size={24} />
                                ) : (
                                  <SwitchButton
                                    isDisabled={isRegularInstructor}
                                    isActive={quote.is_active}
                                    onClick={() => {
                                      if (userData?.roles.includes(ADMIN) || userData?.roles.includes(PRO_INSTRUCTOR)) {
                                        if (isSectionsSorted) {
                                          setWorningSectionsSortPopup(true);
                                        } else {
                                          setLectureType(quote.file ? "file" : quote.video ? "video" : "");
                                          setLectureState(quote.is_active);
                                          setVisibleLectureId(quote.id);
                                          setChangeState(!changeState);
                                        }
                                      }
                                    }}
                                  >
                                    <Switcher />
                                  </SwitchButton>
                                )}
                              </SwitchContainer>
                            </TableBodyth>
                            {/* <TableBodyth>
                            {quote.is_free ? (
                              <EditWrapper>
                                <FreePrice>{tCommon("free")}</FreePrice>
                              </EditWrapper>
                            ) : (
                              <>-</>
                            )}
                          </TableBodyth> */}
                            <TableBodyth>
                              <EditIconWrapper
                                onClick={() => {
                                  if (isSectionsSorted) {
                                    setWorningSectionsSortPopup(true);
                                  } else {
                                    editLecture(true);
                                    setLectureId(quote.id);
                                    setLectureType(
                                      quote.file && Object.keys(quote.file).length !== 0
                                        ? "file"
                                        : quote.video && Object.keys(quote.video).length !== 0
                                        ? "video"
                                        : ""
                                    );
                                  }
                                }}
                              >
                                <EditIcon color />
                              </EditIconWrapper>
                            </TableBodyth>
                          </TableBodyTr>
                        )}
                      </Draggable>
                    ))}
                    {dropProvided.placeholder}
                  </TableHead>
                </TableBody>
              )}
            </Droppable>
          )}
        </StyledTable>
      </Collapse>
    </SectionContainer>
  );
};

export default Section;
