import ContentLoader from "react-content-loader";

export default function SubjectCourseSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="120px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="20" rx="8" ry="8" width="24%" height="72px" />
      <rect x="26%" y="20" rx="8" ry="8" width="24%" height="72px" />
      <rect x="51%" y="20" rx="8" ry="8" width="24%" height="72px" />
      <rect x="76%" y="20" rx="8" ry="8" width="24%" height="72px" />
    </ContentLoader>
  );
}
