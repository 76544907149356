//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import { _GetStatus } from "@Services/Common";
import { Fragment } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { _GetLecture, _UpdateFileLecture, _UpdateVideoLecture } from "@Services/Lecture";
import Section from "./Section/SectionLayout";

type Props = {
  newData: any;
  onDragEnd: any;
  editLecture: any;
  editSection: any;
};

const DNDD = ({ editLecture, editSection, newData, onDragEnd }: Props) => {
  return (
    <Fragment>
      {newData && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="board" type="COLUMN" direction="vertical" isCombineEnabled={false}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {newData?.ordered?.map((key, index) => (
                  <Draggable key={key[1]} draggableId={key[1]} index={index}>
                    {(provided, snapshot) => (
                      <Section
                        refProvid={provided}
                        snapShots={snapshot}
                        data={newData}
                        keyId={key[1]}
                        keyName={key[0]}
                        editSection={editSection}
                        editLecture={editLecture}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Fragment>
  );
};

export default DNDD;
