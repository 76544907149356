export const NoteIcon = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.54362 13.9749H9.45768C10.1304 13.9749 10.6339 13.8035 10.9681 13.4606C11.3066 13.1221 11.4759 12.6143 11.4759 11.9372V6.02572C11.4759 5.7436 11.4607 5.50705 11.4303 5.31608C11.4 5.12511 11.3392 4.9515 11.248 4.79525C11.1612 4.63466 11.031 4.46539 10.8574 4.28743L7.27018 0.635091C7.10525 0.46582 6.94466 0.337782 6.78841 0.250977C6.63216 0.159831 6.46072 0.0990668 6.27409 0.0686849C6.0918 0.0339627 5.87912 0.0166016 5.63607 0.0166016H2.54362C1.87088 0.0166016 1.36523 0.188043 1.02669 0.530924C0.692491 0.873806 0.525391 1.38379 0.525391 2.06087V11.9372C0.525391 12.6186 0.692491 13.1286 1.02669 13.4671C1.36523 13.8057 1.87088 13.9749 2.54362 13.9749ZM2.58919 12.9268C2.25499 12.9268 2.00109 12.84 1.82747 12.6663C1.6582 12.4927 1.57357 12.2432 1.57357 11.9176V2.0804C1.57357 1.75922 1.6582 1.50966 1.82747 1.33171C2.00109 1.15375 2.25716 1.06478 2.5957 1.06478H5.49284V4.84733C5.49284 5.25532 5.59483 5.56131 5.79883 5.7653C6.00282 5.96929 6.30881 6.07129 6.7168 6.07129H10.4277V11.9176C10.4277 12.2432 10.3409 12.4927 10.1673 12.6663C9.99805 12.84 9.74414 12.9268 9.4056 12.9268H2.58919ZM6.83398 5.08822C6.70378 5.08822 6.61046 5.06 6.55404 5.00358C6.50195 4.94716 6.47591 4.85384 6.47591 4.72363V1.2666L10.2259 5.08822H6.83398Z"
        fill="#F25151"
      />
    </svg>
  );
};
