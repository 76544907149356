import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import {
  ButtonContainer,
  CenterOpt,
  Form,
  HeaderContainer,
  InputsHolder,
  OtpError,
  OtpHandler,
  OtpHandlerContainer,
  OtpStyledRodal,
  Paragraph,
  ParagraphInner,
  ParagraphPlus,
  StepContainer,
  Timer,
  Title,
} from "./VerificationOtpPopup.styled";
import { Controller, useForm } from "react-hook-form";
import { convDigits } from "utils/Time";
import { phoneAuthHandler, phoneSMSHandlerWithRecaptcha } from "@Services/Phone";
import { Profile } from "types/Teachers";
import { _UpdateTeacherProfile } from "@Services/Teachers";
import { TeachersDatasContext } from "context/Teachers.context";
import { useTranslation } from "react-i18next";
import { ReCaptchaWrapper } from "@Components/common/Forms";
import ReCAPTCHA from "react-google-recaptcha";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  profileData: Profile;
  closeProfilePopup: Dispatch<SetStateAction<boolean>>;
  setSubmitValidation: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setValidationPopup: Dispatch<SetStateAction<boolean>>;
}

const VerificationOtpPopup = ({
  isOpen,
  profileData,
  closeProfilePopup,
  setSubmitValidation,
  setIsLoading,
  setValidationPopup,
}: Props) => {
  const { t: tAuth } = useTranslation("common");
  const [value, setCurrentValue] = useState("");
  const [counter, setCounter] = useState(null);
  const [otpError, setOtpError] = useState(null);
  const { handleSubmit, control } = useForm();
  const { refetchUserData } = userInfo();
  const { teacherId, refetchProfile } = useContext(TeachersDatasContext);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const onSubmit = (dataForm) => {
    phoneAuthHandler({
      phone: profileData?.phone,
      code: value,
    })
      .then(() => {
        setValidationPopup(false);
        _UpdateTeacherProfile(teacherId, profileData)
          .then(() => {
            closeProfilePopup(false);
            refetchProfile();
            refetchUserData();
          })
          .catch((err) => setSubmitValidation(err?.response.data.message));
      })
      .catch((err) => {
        setOtpError(err?.message);
      })
      .finally(() => setIsLoading(false));
  };

  const onChangeRecapcha = (value) => {
    setRecaptchaToken(value);
  };

  const onErroredRecapcha = () => {
    setRecaptchaToken(null);
  };

  const resendHandler = () => {
    phoneSMSHandlerWithRecaptcha({
      phone: profileData?.phone,
      recaptcha: recaptchaToken,
    })
      .then(() => {
        setCounter(20);
      })
      .catch((err) => {
        setOtpError(err?.message);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setCounter(20);
    }
  }, [isOpen]);

  useEffect(() => {
    counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1);
        if (counter === 1) setRecaptchaToken(null);
      }, 1000);
    value.length == 6 && handleSubmit(onSubmit)();
  }, [value, counter]);

  return (
    <OtpStyledRodal visible={isOpen}>
      <StepContainer>
        <HeaderContainer>
          <Title>{tAuth("Auth_enterVerificationCode")}</Title>
          <Paragraph>
            {tAuth("Auth_verificationCodeIsSentTo")}{" "}
            <ParagraphInner direction="ltr">+{profileData?.phone.replace("-", " ")}</ParagraphInner>
          </Paragraph>
          <ParagraphPlus
            onClick={() => {
              setValidationPopup(false);
              setIsLoading(false);
              setRecaptchaToken(null);
            }}
            textColor={"seagule"}
          >
            {tAuth("Auth_changeNumber")}
          </ParagraphPlus>
        </HeaderContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputsHolder>
            <CenterOpt>
              <OtpHandlerContainer>
                <Controller
                  render={() => (
                    <OtpHandler
                      value={value}
                      numInputs={6}
                      onChange={(val) => setCurrentValue(val)}
                      separator={<span> </span>}
                    />
                  )}
                  name="opt"
                  control={control}
                  defaultValue={value}
                />
              </OtpHandlerContainer>
            </CenterOpt>
            {otpError !== null && <OtpError>{otpError}</OtpError>}
          </InputsHolder>
        </Form>
        {counter == 0 && (
          <ReCaptchaWrapper>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RE_CAPTCHA}
              onChange={onChangeRecapcha}
              onErrored={onErroredRecapcha}
            />
          </ReCaptchaWrapper>
        )}
        {counter != 0 ? (
          <Paragraph>
            Resend in <Timer>0:{convDigits(counter)}</Timer>
          </Paragraph>
        ) : (
          <ButtonContainer onClick={() => recaptchaToken && resendHandler()}>
            <button disabled={!recaptchaToken} className="not-fill">
              {tAuth("Auth_resendVerificationCodeIn")}
            </button>
          </ButtonContainer>
        )}
      </StepContainer>
    </OtpStyledRodal>
  );
};

export default VerificationOtpPopup;
