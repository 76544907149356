import { useContext, Dispatch, SetStateAction, useEffect, useState, useRef } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import { _UpdateTeacherProfile } from "@Services/Teachers";
import { useForm } from "react-hook-form";
import { Profile } from "types/Teachers";
import PopupsLayout from "@Components/PopupsLayout";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import "react-phone-input-2/lib/material.css";
import { phoneSMSHandlerWithRecaptcha } from "@Services/Phone";
import VerificationOtpPopup from "../VerificationOtpPopup";
import {
  FormContainer,
  FormWrapper,
  Input,
  TrackingChapters,
  Wrapper,
  PopupContainer,
  ReCaptchaWrapper,
  ReCaptchaContainer,
  ReCaptchaError,
} from "@Components/common/Forms";
import PhoneInput from "@Components/PhoneInput";
import { parsePhoneNumber } from "react-phone-number-input";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const EditProfilePopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const recapchaRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { userData } = userInfo();
  const { teacherId, teacherProfile, refetchProfile } = useContext(TeachersDatasContext);
  const isAuthorized = userData?.roles.includes(ADMIN);
  // PHONE STATE
  const [validationPopup, setValidationPopup] = useState(false);
  const [userProfileData, setUserProfileData] = useState<Profile>(null);
  const [phoneValidationError, setPhoneValidationError] = useState(null);
  const [selectedCode, setSelectedCode] = useState("");
  const [countryShortCode, setCountryShortCode] = useState(null);
  const [disabledPhoneInput, setDisabledPhoneInput] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<Profile>();

  useEffect(() => {
    reset({
      display_name: teacherProfile?.display_name,
      name: teacherProfile?.name,
      bio: teacherProfile?.bio,
      phone:
        teacherProfile?.phone?.split("-").length === 2
          ? teacherProfile?.phone?.split("-")[1]
          : teacherProfile?.phone?.split("-")[0],
      email: teacherProfile?.email,
      bank_name: teacherProfile?.bank_name,
      iban: teacherProfile?.iban,
    });
    setSelectedCode(teacherProfile?.phone?.split("-").length === 2 ? teacherProfile?.phone?.split("-")[0] : "?");
    setCountryShortCode(parsePhoneNumber(`+${teacherProfile?.phone?.split("-").join("")}`)?.country);
    setDisabledPhoneInput(userData?.is_phone_verified && teacherProfile?.phone?.split("-").length === 2);
    setSubmitValidation("");
    setPhoneValidationError(null);
    setRecaptchaError(false);
  }, [teacherProfile, isOpen]);

  const onChangeRecapcha = (value) => {
    setRecaptchaToken(value);
  };

  const resetRecaptcha = () => {
    if (recapchaRef.current) {
      recapchaRef.current.reset();
      setRecaptchaToken(null);
      setRecaptchaError(false);
    }
  };

  const onErroredRecapcha = () => {
    setRecaptchaToken(null);
    setRecaptchaError(true);
  };

  useEffect(() => {
    if (!isOpen || Boolean(isOpen && !validationPopup)) {
      resetRecaptcha();
    }
  }, [validationPopup, isOpen]);

  const submitForm = (data: Profile) => {
    setIsLoading(true);
    const { phone } = data;
    if (
      isAuthorized ||
      (!isAuthorized && userData?.is_phone_verified && teacherProfile?.phone?.split("-").length === 2)
    ) {
      _UpdateTeacherProfile(teacherId, { ...data, phone: selectedCode + `${selectedCode && "-"}` + phone })
        .then(() => {
          closePopup(false);
          refetchProfile();
        })
        .catch((err) => setSubmitValidation(err?.response.data.message))
        .finally(() => setIsLoading(false));
    } else {
      if (!recaptchaToken) {
        setRecaptchaError(true);
        setIsLoading(false);
        return;
      }
      phoneSMSHandlerWithRecaptcha({
        phone: selectedCode + `${selectedCode && "-"}` + phone,
        recaptcha: recaptchaToken,
      })
        .then(() => {
          setValidationPopup(true);
          setUserProfileData({ ...data, phone: selectedCode + `${selectedCode && "-"}` + phone });
        })
        .catch((error) => {
          setPhoneValidationError(error?.response.data.message);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_profile")}
          buttonTitle={tCommon("save_changes")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("name")}
              {...register("display_name", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.display_name)}
              helperText={errors.display_name && errors.display_name?.message}
            />
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("username")}
              {...register("name", {
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name?.message}
            />
            <Wrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("bio")}
                inputProps={{ maxLength: 300 }}
                {...register("bio", {
                  required: tCommon("required_message"),
                })}
                multiline
                error={Boolean(errors.bio) || Boolean(watch("bio")?.length === 300)}
                helperText={errors.bio && errors.bio?.message}
              />
              {watch("bio")?.length > 0 && (
                <TrackingChapters hasError={Boolean(watch("bio")?.length === 300)}>
                  {watch("bio")?.length}/300 {tCommon("character")}
                </TrackingChapters>
              )}
            </Wrapper>
            <PhoneInput
              isOpen={isOpen}
              register={register}
              setValue={setValue}
              errors={errors}
              disabledPhoneInput={disabledPhoneInput}
              phoneValidationError={phoneValidationError}
              setSelectedCode={setSelectedCode}
              selectedCode={selectedCode}
              countryShortCode={countryShortCode}
            />
            {!userData?.roles.includes("admin") && (
              <ReCaptchaContainer>
                {(!userData?.is_phone_verified || teacherProfile?.phone?.split("-").length !== 2) && (
                  <ReCaptchaWrapper>
                    <ReCAPTCHA
                      ref={recapchaRef}
                      sitekey={process.env.REACT_APP_RE_CAPTCHA}
                      onChange={onChangeRecapcha}
                      onErrored={onErroredRecapcha}
                    />
                    {recaptchaError && <ReCaptchaError>Recaptcha is required!</ReCaptchaError>}
                  </ReCaptchaWrapper>
                )}
              </ReCaptchaContainer>
            )}
            <Input
              id="filled-basic"
              variant="filled"
              label={tCommon("email")}
              {...register("email", {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: tCommon("enter_valid_email"),
                },
                required: tCommon("required_message"),
              })}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email?.message}
            />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
      <VerificationOtpPopup
        isOpen={validationPopup}
        profileData={userProfileData}
        closeProfilePopup={closePopup}
        setSubmitValidation={setSubmitValidation}
        setIsLoading={setIsLoading}
        setValidationPopup={setValidationPopup}
      />
    </PopupContainer>
  );
};

export default EditProfilePopup;
