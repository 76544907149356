import styled, { css } from "styled-components";

export const SubjectSidebar = styled.div<{ readonly isAdmin?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: all 0.3s ease-in;
  width: 100%;
  max-width: 215px;
  min-width: 215px;
  background: linear-gradient(191.41deg, #0095ff 0%, #126b8d 52.08%, #093c50 96.35%);
  z-index: 10;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.65);
    backdrop-filter: blur(22.5px);
  }
`;

export const SubjectSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 24px 16px;
  z-index: 10;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SubjectTitle = styled.h4`
  margin-bottom: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.white};
`;

export const OverView = styled.div<{ readonly overviewActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 184px;
  height: 48px;
  gap: 12.18px;
  transition: all 0.2s ease;
  padding: 0px 16px;
  margin-bottom: 36px;
  border-radius: 8px;
  ${({ overviewActive }) =>
    overviewActive &&
    css`
      width: 184px;
      height: 48px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.14) 100%);
    `}
`;

export const OverViewTitle = styled.h4<{ readonly overviewActive?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.off_white};
  ${({ overviewActive }) =>
    overviewActive &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}
`;

export const Courses = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`;

export const CoursesTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CoursesIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 6.69px;
  cursor: pointer;
`;

export const CoursesSaveSort = styled.div<{ readonly isSection?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isSection }) => (isSection ? "28px" : "16px")};
  cursor: pointer;
`;

export const AddCourseButton = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.seagule};
`;

export const CoursesList = styled.div``;

export const ChaptersWrapper = styled.div<{ readonly isDraged?: boolean }>`
  display: flex;
  gap: 9.62px;
  padding: 8px 0px;
  position: relative;
  width: 183px;
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 1px;
    background-color: ${({ isDraged, theme }) => (isDraged ? theme.colors.seagule : "rgba(255, 255, 255, 0.1)")};
  }
`;
export const ChapterIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 9.62px;
`;

export const ChapterTitle = styled.h4<{ readonly active?: boolean }>`
  transition: all 0.3s ease;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.off_white)};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ChapterState = styled.span`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  width: 59px;
  height: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(245, 245, 245, 0.05);
  border-radius: 20px;
`;

export const CoursesContainer = styled.div<{ readonly active?: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 14px;
  padding: 8px;
  ${({ active }) =>
    active &&
    css`
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.14) 100%);
      border-radius: 8px;
    `}
`;

export const CoursesInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
`;

export const EmptyCourses = styled.p`
  margin-top: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
`;
