import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import PopupsLayout from "@Components/PopupsLayout";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import { VideoPosition } from "../AddLecturePopup/AddLecturePopup.styled";
import { _CreateIntroduction } from "@Services/Lecture";
import { useTranslation } from "react-i18next";
import Vimeo from "@u-wave/react-vimeo";
import { Lecture } from "types/Lecture";
import { PRO_INSTRUCTOR, ADMIN } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const EditIntroductionPopup = ({ isOpen, closePopup }: Props) => {
  const { userData } = userInfo();
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { courseId, courseDetails, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);
  const [introductionSection, setIntroductionSection] = useState([]);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  useEffect(() => {
    if (courseDetails) {
      setIntroductionSection(courseDetails?.sections.filter((section) => section?.is_introductory === true));
    }
  }, [courseDetails]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<Lecture>();

  const isVideoChanged =
    watch("video_id") && watch("video_id") !== introductionSection[0]?.lectures[0]?.video?.vimeo_video_id;

  useEffect(() => {
    reset({
      vimeo_account: introductionSection[0]?.lectures[0]?.video?.vimeo_account,
      video_id: introductionSection[0]?.lectures[0]?.video?.vimeo_video_id,
    });
    setSubmitValidation("");
    reset();
  }, [introductionSection, isOpen]);

  const submitForm = (data: Lecture) => {
    setIsLoading(true);
    _CreateIntroduction(
      introductionSection[0]?.lectures[0]?.id,
      {
        ...data,
        video_id: Number(watch("video_id")),
        section_id: introductionSection && introductionSection[0]?.id,
        course_id: courseId,
        is_video_changed: isVideoChanged,
      },
      teacherId
    )
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_introduction")}
          buttonTitle={tCommon("save_changes")}
          buttonState={!isDirty}
          noteMessage={tCommon("intro_note_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {introductionSection && (
              <Fragment>
                <InputsWrapper>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                    <Select
                      disabled
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      value="video"
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="video">{tCommon("video")}</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl variant="filled" fullWidth disabled={isRegularInstructor}>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                    <Controller
                      name="vimeo_account"
                      control={control}
                      defaultValue={
                        introductionSection[0]?.lectures[0]?.video?.vimeo_account
                          ? introductionSection[0]?.lectures[0]?.video?.vimeo_account
                          : ""
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={MenuProps}
                          {...register("vimeo_account")}
                        >
                          <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                          <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </InputsWrapper>
                <Fragment>
                  {introductionSection[0]?.lectures[0]?.video?.vimeo_video_id && (
                    <VideoPosition>
                      <Vimeo video={introductionSection[0]?.lectures[0]?.video?.vimeo_video_id} controls />
                    </VideoPosition>
                  )}
                  <Input
                    id="filled-basic"
                    variant="filled"
                    type="number"
                    className="noArrows"
                    disabled={isRegularInstructor}
                    label={tCommon("vimeo_video_id")}
                    {...register("video_id", {
                      required: tCommon("required_message"),
                      valueAsNumber: true,
                    })}
                    error={Boolean(errors.video_id)}
                    helperText={errors.video_id && errors.video_id?.message}
                  />
                </Fragment>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    disabled
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    value="introduction"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="introduction">{tCommon("introduction")}</MenuItem>
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditIntroductionPopup;
