export const secondsToHms = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);
  const hDisplay = h + "hr ";
  const mDisplay = m + "min ";
  const sDisplay = s + "s";
  if (h !== 0 && m !== 0) {
    return hDisplay + mDisplay;
  } else if (h === 0 && m !== 0) {
    return mDisplay;
  } else if (h === 0 && m === 0 && s !== 0) {
    return sDisplay;
  } else {
    return 0;
  }
};

// Convert number to 2 digits ---> ex: 8 = 08
export const convDigits = (num: number) => `${Math.trunc(num / 10)}${Math.trunc(num % 10)}`;
