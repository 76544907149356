import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  AlertButton,
  AlertPopupButtons,
  AlertPopupHeader,
  AlertPopupMessage,
  StyledAlertRodal,
} from "../EmptySectionAlertPopup/EmptySectionAlertPopup.styled";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
};

const VimeoVideoReadyPopup = ({ isOpen, closePopup }: Props) => {
  const { refetchCourseDetails } = useContext(TeachersDatasContext);
  const { t: tCommon } = useTranslation("common");

  return (
    <StyledAlertRodal isVimeoVideoReady visible={isOpen} onClose={() => closePopup(false)}>
      <AlertPopupHeader>{tCommon("congratulations")}</AlertPopupHeader>
      <AlertPopupMessage>{tCommon("congratulations_message")}</AlertPopupMessage>
      <AlertPopupButtons isVimeoVideoReady>
        <AlertButton
          variant="fullWidth"
          onClick={() => {
            closePopup(false);
            refetchCourseDetails();
          }}
        >
          {tCommon("okay")}
        </AlertButton>
      </AlertPopupButtons>
    </StyledAlertRodal>
  );
};

export default VimeoVideoReadyPopup;
