import styled from "styled-components";

export const TeacherListContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.gray7};
`;

export const TeacherListHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
  }
`;

export const ListHeader = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  line-height: 56px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const GlobalFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16.07px;
  gap: 12.07px;
  width: 312px;
  height: 52px;
  background: ${({ theme }) => theme.colors.gray5_old};
  backdrop-filter: blur(10px);
  border-radius: 8px;
`;

export const FilterButton = styled.div<{ readonly showFilters?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12.82px;
  width: 140px;
  height: 52px;
  background: ${({ showFilters, theme }) =>
    showFilters ? `${theme.colors.seagullNeutrals}` : `${theme.colors.white}`};
  color: ${({ showFilters, theme }) => (showFilters ? `${theme.colors.seagule}` : `${theme.colors.nero}`)};
  transition: all 0.2s ease;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  cursor: pointer;
`;

export const ButtonTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
`;

export const StyledInput = styled.input`
  background-color: transparent;
  padding: 5px 0px;
  width: 280px;
  border: none;
  outline: none;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  &::placeholder {
    color: rgba(69, 69, 69, 0.7);
  }
`;
