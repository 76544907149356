import Axios from "@Configs/Axios";

export const _GetBreadcrumb = (id: number) =>
  Axios.get(`/v1/breadcrumbs/${id}/hierarchy`).then(({ data }) => data.data);

export const _GetCurrencies = () => Axios.get(`/v1/currencies`).then(({ data }) => data.data);

export const _GetCategories = (categoryId?: number) =>
  Axios.get(`/v1/onboarding-steps/categories${categoryId ? "/" + categoryId : ""}`).then(({ data }) => data.data);

export const _UploadVemioVideo = (data: any) =>
  Axios.post(`https://api.vimeo.com/me/videos`, data, {
    headers: {
      Authorization: "bearer 42f4cbbf5fbbf1be99396270a839cd5d",
      "Content-Type": "application/json",
      Accept: "application/vnd.vimeo.*+json;version=3.4",
    },
  });

export const _GetUserProfile = () => Axios.get(`/v1/users/profile`).then(({ data }) => data.data);

export const _GetUploadUrl = (data: any) =>
  Axios.post(`/v1/s3/get-signed-upload-url`, data).then(({ data }) => data.data);

export const _PutPdfFile = (url: string, data: any) => Axios.put(url, data);

export const _PutImageFile = (url, data) =>
  fetch(url, {
    method: "PUT",
    body: data,
  });

export const _DeleteUploadFile = (data) => Axios.delete(`/v1/s3`, data);

export const _DeleteMediaFile = (id) => Axios.delete(`/v1/subjects/media/${id}`);

export const _CreateMediaFile = (id) => Axios.post(`/v1/subjects/${id}/media`);

export const _GetStatus = (id: number, token: string) =>
  fetch(`https://api.vimeo.com/me/videos/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }).then((response) => response.json());
