import React, { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { _GetCategories } from "@Services/Common";
import { useQuery } from "react-query";
import { Category } from "types/Category";
import { useTranslation } from "react-i18next";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import {
  ArrowWrapper,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  RecursiveMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { RightArrowIcon } from "images/icons/RightArrowIcon";
import { FILTER_TYPE_TEACHERS, FILTER_TYPE_SUBJECTS } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  depth: number;
  categoryType: string;
  hoveredCategory: Category;
  series: string[];
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setBreadCrumb: Dispatch<SetStateAction<string>>;
  breadCrumb?: string;
};

export default function RecursiveExploreMenu({
  hoveredCategory,
  depth,
  categoryType,
  series,
  setIsDropdownOpen,
  setBreadCrumb,
  breadCrumb,
}: Props) {
  const {
    i18n: { language },
  } = useTranslation();
  const { setTeacherCategoryIdFilter, setSubjectCategoryIdFilter } = useTablesFilters();
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const getCatLink = (catSlug: string) => setBreadCrumb(`${series.join(" / ")} / ${catSlug}`);

  const { data: categoriesData, isLoading } = useQuery(
    [["onboarding-categories", language], { categoryId: hoveredCategory.id }],
    () => _GetCategories(hoveredCategory.id),
    {
      refetchOnMount: false,
      staleTime: 5 * 1000 * 60,
    }
  );

  const handleShallowRouting = (e: React.MouseEvent<Element, MouseEvent>, catId: number, catName: string) => {
    if (categoryType === FILTER_TYPE_TEACHERS) {
      setTeacherCategoryIdFilter(catId);
    } else {
      setSubjectCategoryIdFilter(catId);
    }
    setIsDropdownOpen(false);
    getCatLink(catName);
  };

  useEffect(() => {
    setRootCategoryHover(undefined);
  }, [hoveredCategory]);

  return (
    <Fragment>
      <RecursiveMenuWrapper depth={depth} breadCrumb={breadCrumb}>
        <MainExploreDropdown isOpen={Boolean(hoveredCategory)} position="left" isRecursive>
          {isLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categoriesData?.options.map((cat: any) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    onClick={(e) => handleShallowRouting(e, cat.id, cat.name)}
                    onMouseEnter={() => setRootCategoryHover(cat)}
                  >
                    <ExploreDropdownContent>
                      {cat.emoji ? <span className="emojie">{cat.emoji}&nbsp;</span> : ""}
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </RecursiveMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && depth < 3 && (
        <RecursiveExploreMenu
          depth={depth + 1}
          categoryType={categoryType === FILTER_TYPE_TEACHERS ? FILTER_TYPE_TEACHERS : FILTER_TYPE_SUBJECTS}
          hoveredCategory={rootCategoryHover}
          series={[...series, rootCategoryHover.name]}
          setIsDropdownOpen={setIsDropdownOpen}
          setBreadCrumb={setBreadCrumb}
          breadCrumb={breadCrumb}
        />
      )}
    </Fragment>
  );
}
