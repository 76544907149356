export const BaimsLogo = () => {
  return (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.9971 43.3946C10.1998 43.3946 0.601562 33.7964 0.601562 21.9991C0.601562 10.2017 10.1998 0.603516 21.9971 0.603516C33.7945 0.603516 43.3927 10.2017 43.3927 21.9991C43.3927 33.7964 33.7945 43.3946 21.9971 43.3946ZM21.9971 2.95818C11.4985 2.95818 2.95623 11.4995 2.95623 21.9991C2.95623 32.4977 11.4976 41.04 21.9971 41.04C32.4958 41.04 41.038 32.4986 41.038 21.9991C41.038 11.5004 32.4958 2.95818 21.9971 2.95818Z"
        fill="white"
      />
      <path
        d="M21.9979 36.8531C13.8076 36.8531 7.14453 30.19 7.14453 21.9998C7.14453 13.8096 13.8076 7.14648 21.9979 7.14648C30.1881 7.14648 36.8512 13.8096 36.8512 21.9998C36.8512 30.19 30.1881 36.8531 21.9979 36.8531ZM21.9979 9.50204C15.1063 9.50204 9.50009 15.1083 9.50009 21.9998C9.50009 28.8914 15.1063 34.4976 21.9979 34.4976C28.8894 34.4976 34.4956 28.8914 34.4956 21.9998C34.4956 15.1083 28.8894 9.50204 21.9979 9.50204Z"
        fill="white"
      />
      <path
        d="M21.9977 30.3099C17.4155 30.3099 13.6875 26.5819 13.6875 21.9997C13.6875 17.4175 17.4155 13.6895 21.9977 13.6895C26.5799 13.6895 30.3079 17.4175 30.3079 21.9997C30.3079 26.5819 26.5799 30.3099 21.9977 30.3099ZM21.9977 16.0441C18.7142 16.0441 16.0422 18.7152 16.0422 21.9997C16.0422 25.2832 18.7133 27.9552 21.9977 27.9552C25.2822 27.9552 27.9533 25.2841 27.9533 21.9997C27.9533 18.7161 25.2813 16.0441 21.9977 16.0441Z"
        fill="white"
      />
    </svg>
  );
};
