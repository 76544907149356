export const BoldCloseIcon = () => {
  return (
    <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.515314 12.9897C0.609064 13.0783 0.715835 13.1382 0.835627 13.1694C0.955418 13.2007 1.07521 13.2007 1.195 13.1694C1.31479 13.1382 1.41896 13.0783 1.5075 12.9897L6.5075 7.98975L11.5075 12.9897C11.596 13.0783 11.7002 13.1382 11.82 13.1694C11.9398 13.2007 12.0596 13.2007 12.1794 13.1694C12.3044 13.1434 12.4111 13.0835 12.4997 12.9897C12.5882 12.9012 12.6455 12.797 12.6716 12.6772C12.7028 12.5575 12.7028 12.4377 12.6716 12.3179C12.6455 12.1981 12.5882 12.0939 12.4997 12.0054L7.49969 6.99756L12.4997 1.99756C12.5882 1.90902 12.6481 1.80485 12.6794 1.68506C12.7106 1.56527 12.7106 1.44548 12.6794 1.32568C12.6481 1.20589 12.5882 1.10173 12.4997 1.01318C12.4059 0.919434 12.2992 0.859538 12.1794 0.833496C12.0596 0.802246 11.9398 0.802246 11.82 0.833496C11.7002 0.859538 11.596 0.919434 11.5075 1.01318L6.5075 6.01318L1.5075 1.01318C1.41896 0.919434 1.31219 0.859538 1.18719 0.833496C1.0674 0.802246 0.947606 0.802246 0.827814 0.833496C0.708023 0.859538 0.603856 0.919434 0.515314 1.01318C0.426773 1.10173 0.366877 1.20589 0.335627 1.32568C0.309585 1.44548 0.309585 1.56527 0.335627 1.68506C0.366877 1.80485 0.426773 1.90902 0.515314 1.99756L5.51531 6.99756L0.515314 12.0054C0.426773 12.0939 0.366877 12.1981 0.335627 12.3179C0.304377 12.4377 0.301773 12.5575 0.327814 12.6772C0.359064 12.797 0.421564 12.9012 0.515314 12.9897Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
