import theme from "@Style/Theme";

export const RoundedDownArrowIcon = ({ color }: { color: boolean }) => {
  return (
    <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00933 6.00098C6.17773 6.00098 6.33084 5.93208 6.46097 5.8096L11.1611 1.07884C11.2836 0.964024 11.3448 0.803269 11.3448 0.627197C11.3448 0.267417 11.0769 -0.000508308 10.7171 -0.000508308C10.5411 -0.000508308 10.388 0.0683937 10.2655 0.183215L6.00933 4.45468L1.75318 0.183215C1.63069 0.0683937 1.46994 -0.000508308 1.30153 -0.000508308C0.941754 -0.000508308 0.673828 0.267417 0.673828 0.627197C0.673828 0.803269 0.735065 0.964024 0.857552 1.07884L5.55768 5.8096C5.68782 5.93208 5.84092 6.00098 6.00933 6.00098Z"
        fill={color ? theme.colors.gray8 : theme.colors.seagule}
      />
    </svg>
  );
};
