import React from "react";

const CheckIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.9987" cy="19.9997" r="16.6667" fill="#45AAF2" />
      <path
        d="M18.4752 26.9869C18.8536 26.9869 19.1544 26.8219 19.3679 26.492L26.4422 15.3033C26.6071 15.051 26.6654 14.8472 26.6654 14.6434C26.6654 14.1679 26.3548 13.8477 25.8696 13.8477C25.5203 13.8477 25.3262 13.9641 25.1127 14.3037L18.4363 25.017L14.9235 20.2814C14.7003 19.9806 14.4868 19.8544 14.1666 19.8544C13.6814 19.8544 13.332 20.1941 13.332 20.6793C13.332 20.8831 13.4194 21.1063 13.5843 21.3198L17.5533 26.4726C17.825 26.8219 18.0967 26.9869 18.4752 26.9869Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
