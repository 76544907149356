import React from "react";

const LampIcon = () => {
  return (
    <svg width="70" height="51" viewBox="0 0 70 51" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.2218 1.18071C30.4263 2.57145 24.0597 6.85924 20.2125 12.6703C17.0745 13.7058 14.4274 15.7218 12.6132 18.3259C5.48059 19.3998 0.223653 23.8119 0.639887 28.6062C1.05162 33.3522 6.87802 36.8024 13.9942 36.7474C16.5394 39.4473 20.0782 41.1947 23.9776 41.4535C29.8022 47.191 38.2855 50.5908 46.7784 49.3883C59.7722 47.5483 70.9244 35.2015 69.154 21.775C67.2263 7.15497 50.9152 -1.6222 37.2218 1.18071Z"
        fill="#D9D9D9"
      />
      <path
        d="M18.8877 23.9423C19.1748 14.5979 27.0732 6.74539 35.8612 4.89062C47.0889 2.52058 60.4904 9.78881 62.1045 21.9616C63.5869 33.1406 54.4648 43.4537 43.8089 45.0199C31.1668 46.8781 18.514 36.1096 18.8877 23.9423Z"
        fill="#FEC76C"
      />
      <path
        d="M16.257 19.2547C22.7843 18.478 28.4965 21.2912 29.0149 25.5384C29.5333 29.7852 24.6622 33.8575 18.1348 34.634C11.6072 35.4107 5.89552 32.5977 5.37715 28.3506C4.85878 24.1037 9.72962 20.0315 16.257 19.2547Z"
        fill="#F69F5A"
      />
      <path
        d="M11.9895 29.5151C12.5274 29.9307 12.7664 30.515 12.5236 30.8199C12.2804 31.125 11.6472 31.035 11.1096 30.6194C10.5716 30.2039 10.3326 29.6195 10.5755 29.3147C10.8186 29.0096 11.4515 29.0993 11.9895 29.5151Z"
        fill="#CD844A"
      />
      <path
        d="M11.5201 25.6683C11.9427 25.1385 12.0341 24.5149 11.7245 24.2759C11.4151 24.0366 10.8217 24.2723 10.3994 24.8021C9.97711 25.3319 9.8854 25.9556 10.195 26.1948C10.5044 26.4341 11.0976 26.1984 11.5201 25.6683Z"
        fill="#CD844A"
      />
      <path
        d="M24.1278 13.8052C30.9935 12.9882 37.2284 17.8073 38.0539 24.5688C38.8793 31.3303 33.9826 37.4738 27.1166 38.2908C20.2511 39.1079 14.0163 34.2888 13.1908 27.5273C12.3654 20.7658 17.2621 14.6223 24.1278 13.8052Z"
        fill="#FEC76C"
      />
      <path
        d="M18.8336 30.5821C19.8314 31.3531 20.2745 32.4369 19.8236 33.0027C19.3727 33.5684 18.1985 33.402 17.2007 32.6307C16.2029 31.8597 15.7598 30.7761 16.2106 30.2103C16.6618 29.6446 17.8361 29.811 18.8336 30.5821Z"
        fill="#023447"
      />
      <path
        d="M17.6058 30.4388C17.9505 30.7054 18.0513 31.1461 17.8302 31.4232C17.6095 31.7004 17.1507 31.7091 16.8057 31.4425C16.4606 31.1758 16.3604 30.7351 16.5814 30.458C16.8025 30.1809 17.2607 30.1721 17.6058 30.4388Z"
        fill="#FAFAFA"
      />
      <path
        d="M17.9623 23.4462C18.7458 22.4633 18.9155 21.3067 18.3413 20.8627C17.7672 20.419 16.6667 20.8563 15.8832 21.8392C15.0995 22.8224 14.9298 23.979 15.504 24.4227C16.0781 24.8665 17.1789 24.4294 17.9623 23.4462Z"
        fill="#023447"
      />
      <path
        d="M16.6467 22.5825C16.9917 22.8488 17.0922 23.2898 16.8712 23.5669C16.6504 23.8441 16.1916 23.8525 15.8466 23.5859C15.5018 23.3192 15.4014 22.8786 15.6221 22.6014C15.8431 22.3243 16.3019 22.3158 16.6467 22.5825Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default LampIcon;
