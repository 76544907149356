import { QueryClient } from "react-query";

const QueryClientConfig = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 2,
      // ! Fetch a query only once until page refresh using React Query
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 24 * 60 * 60 * 1000,
    },
  },
});

export default QueryClientConfig;
