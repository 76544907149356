import TextField from "@mui/material/TextField";
import styled from "styled-components";
import theme from "@Style/Theme";

export const PopupContainer = styled.div``;

export const FormContainer = styled.form``;

export const Wrapper = styled.div``;

export const TrackingChapters = styled.p<{ readonly hasError?: boolean }>`
  padding: 8px 16px 0;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ hasError, theme }) => (hasError ? theme.colors.red : theme.colors.gray3)};
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .MuiFormControl-root {
    width: 100%;
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .MuiInputLabel-formControl {
    top: 3px;
    left: 4px;
    right: 4px;
    font-weight: ${({ theme }) => theme.fontWeights.font300};
    font-family: inherit;
  }
  .Mui-error {
    color: ${({ theme }) => theme.colors.red} !important;
    border-color: ${({ theme }) => theme.colors.red} !important;
    background: ${({ theme }) => theme.colors.sunSet} !important;
    &.MuiFormLabel-root {
      background: transparent !important;
    }
  }
  .Mui-disabled {
    background: ${({ theme }) => theme.colors.gray6} !important;
    border-color: ${({ theme }) => theme.colors.gray6} !important;
    color: ${({ theme }) => theme.colors.gray3} !important;
    opacity: 0.8;
    &.MuiFormLabel-root {
      background: transparent !important;
      color: ${({ theme }) => theme.colors.gray3} !important;
    }
  }
  .MuiFilledInput-root.Mui-focused {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.seagule};
  }
  .MuiFilledInput-input {
    font-size: ${({ theme }) => theme.fontSizes.body16};
    color: ${({ theme }) => theme.colors.nero};
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    padding: 30px 16px 10px;
    border-radius: 8px;
  }
  .MuiInputBase-inputMultiline {
    padding: 0px 4px;
    line-height: 1.8;
  }
  .MuiFilledInput-root {
    border: 1.2px solid ${({ theme }) => theme.colors.gray6};
    background: ${({ theme }) => theme.colors.gray6};
    color: ${({ theme }) => theme.colors.gray2};
    border-radius: 10px;
    min-height: 64px;
  }
  .MuiFormLabel-root {
    font-weight: ${({ theme }) => theme.fontWeights.font400} !important;
    font-size: ${({ theme }) => theme.fontSizes.body16} !important;
    line-height: 28px;
    color: ${({ theme }) => theme.colors.gray2} !important;
  }
  .MuiSelect-icon {
    font-size: ${({ theme }) => theme.fontSizes.h5};
  }
  .MuiFormHelperText-contained.Mui-error {
    font-size: ${({ theme }) => theme.fontSizes.body14};
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.red} !important;
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    background-color: transparent !important;
  }
  .MuiInputLabel-shrink.Mui-focused,
  .MuiInputLabel-shrink {
    font-size: ${({ theme }) => theme.fontSizes.body14} !important;
    font-weight: ${({ theme }) => theme.fontWeights.font400} !important;
    line-height: 24px;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: 0 !important;
  }
  .MuiSelect-select:focus {
    background-color: ${({ theme }) => theme.colors.white} !important;
    border-radius: 8px;
  }
  .MuiTypography-root {
    font-weight: ${({ theme }) => theme.fontWeights.font400} !important;
    font-size: ${({ theme }) => theme.fontSizes.body16} !important;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray2} !important;
  }
  .MuiInputAdornment-root {
    margin-right: 2px;
    margin-top: 19px !important;
  }
  .no_padding {
    .MuiFilledInput-input.Mui-disabled {
      padding: 30px 0px 10px;
    }
    .MuiFilledInput-input {
      padding: 30px 0px 10px;
    }
  }
`;

export const Input = styled(TextField)``;

export const ReCaptchaContainer = styled.div``;

export const ReCaptchaWrapper = styled.div``;

export const ReCaptchaError = styled.span`
  display: block;
  padding: 8px 16px 0;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
`;

export const MenuProps = {
  sx: {
    "&& .MuiPaper-root": {
      maxHeight: "250px",
      borderRadius: "8px",
      scrollbarWidth: "none",
      padding: "0px 8px",
    },
    "&& .MuiPaper-root::-webkit-scrollbar": {
      display: "none !important",
    },
    "&& .MuiButtonBase-root": {
      padding: "8px 12px !important",
      fontSize: "16px",
      backgroundColor: "transparent",
    },
    "&& .MuiMenuItem-root": {
      color: `${theme.colors.gray1}`,
    },
    "&& .MuiButtonBase-root:hover": {
      background: `${theme.colors.gray6}`,
      borderRadius: "8px",
      color: `${theme.colors.nero}`,
    },
    "&& .Mui-selected": {
      color: `${theme.colors.seagule}`,
      backgroundColor: "transparent",
    },
    "&& .Mui-selected:hover": {
      color: `${theme.colors.nero}`,
      background: `${theme.colors.gray6}`,
      borderRadius: "8px",
    },
    "&& .Mui-selected:focus:hover": {
      color: `${theme.colors.nero}`,
      background: `${theme.colors.gray6}`,
      borderRadius: "8px",
    },
    "&& .Mui-selected:focus": {
      color: `${theme.colors.seagule}`,
      backgroundColor: "white !important",
    },
  },
};
