import { LampIconContainer, NoResultMessage, NoResultWrapper } from "./NoResultsMessage.styled";
import LampIcon from "images/icons/LampIcon";

type Props = {
  message: string;
  isCoursePage?: boolean;
};

const NoResultsMessage = ({ message, isCoursePage }: Props) => {
  return (
    <NoResultWrapper isCoursePage={isCoursePage}>
      <LampIconContainer>
        <LampIcon />
      </LampIconContainer>
      <NoResultMessage>{message}</NoResultMessage>
    </NoResultWrapper>
  );
};

export default NoResultsMessage;
