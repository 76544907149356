const DownloadIcon = () => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.26925 14.531H9.73669C10.4181 14.531 10.9281 14.3617 11.2666 14.0232C11.6095 13.6846 11.781 13.1812 11.781 12.5128V6.01536C11.781 5.34696 11.6095 4.84349 11.2666 4.50495C10.9281 4.1664 10.4181 3.99713 9.73669 3.99713H7.92029V5.04531H9.71716C10.0383 5.04531 10.2879 5.13212 10.4659 5.30573C10.6438 5.47934 10.7328 5.73542 10.7328 6.07396V12.4542C10.7328 12.7927 10.6438 13.0488 10.4659 13.2224C10.2879 13.396 10.0383 13.4828 9.71716 13.4828H2.28227C1.95675 13.4828 1.70718 13.396 1.53357 13.2224C1.35996 13.0488 1.27315 12.7927 1.27315 12.4542V6.07396C1.27315 5.73542 1.35996 5.47934 1.53357 5.30573C1.70718 5.13212 1.95675 5.04531 2.28227 5.04531H4.08565V3.99713H2.26925C1.58782 3.99713 1.07567 4.1664 0.732788 4.50495C0.394246 4.83915 0.224976 5.34262 0.224976 6.01536V12.5128C0.224976 13.1855 0.394246 13.689 0.732788 14.0232C1.07567 14.3617 1.58782 14.531 2.26925 14.531ZM5.99972 10.2471C6.06916 10.2471 6.13209 10.2363 6.18852 10.2146C6.24928 10.1885 6.31222 10.143 6.37732 10.0779L8.57784 7.94896C8.68201 7.84913 8.73409 7.73628 8.73409 7.61042C8.73409 7.47587 8.68852 7.36736 8.59737 7.28489C8.50623 7.19809 8.39338 7.15469 8.25883 7.15469C8.1156 7.15469 7.99841 7.20677 7.90727 7.31094L6.91768 8.35911L6.48149 8.82135L6.52055 7.84479V0.982811C6.52055 0.843922 6.46847 0.724564 6.3643 0.624738C6.26447 0.524912 6.14294 0.474998 5.99972 0.474998C5.86083 0.474998 5.7393 0.524912 5.63513 0.624738C5.53531 0.724564 5.48539 0.843922 5.48539 0.982811V7.84479L5.52445 8.82135L5.08175 8.35911L4.09867 7.31094C4.00753 7.20677 3.88817 7.15469 3.7406 7.15469C3.60171 7.15469 3.48886 7.19809 3.40206 7.28489C3.31525 7.36736 3.27185 7.47587 3.27185 7.61042C3.27185 7.73628 3.32176 7.84913 3.42159 7.94896L5.62211 10.0779C5.69156 10.143 5.75449 10.1885 5.81091 10.2146C5.87168 10.2363 5.93461 10.2471 5.99972 10.2471Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};
export default DownloadIcon;
