import { TeachersDatasContext } from "context/Teachers.context";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddLangButton,
  AddLangMenu,
  AddLangWrapper,
  CourseDetailsContainer,
  CourseLangWrapper,
  LangMenuArrow,
  LangName,
  LangTabName,
  LangTabsWrappwer,
  LangTabsWrappwers,
  MenuLangName,
  RadioBoxWrapper,
  TabContainer,
  TabsContainer,
} from "./MultibleLanguagesInputs.styled";
import { RoundedCloseIcon } from "images/icons/RoundedCloseIcon";
import { RoundedDownArrowIcon } from "images/icons/RoundedDownArrowIcon";
import { BigPlusIcon } from "images/icons/BigPlusIcon";
import { FormWrapper, Input, TrackingChapters, Wrapper } from "@Components/common/Forms";
import { Dispatch, SetStateAction } from "react";
import { userInfo } from "context/UserInfo.context";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";

interface Props {
  data?: any;
  isOpen: boolean;
  register: any;
  errors: any;
  setValue?: any;
  watch?: any;
  isDisabled?: boolean;
  isSection?: boolean;
  islecture?: boolean;
  isSubject?: boolean;
  setDisablebutton?: Dispatch<SetStateAction<boolean>>;
}

const MultibleLanguagesInputs = ({
  data,
  isOpen,
  register,
  errors,
  setValue,
  watch,
  isDisabled,
  isSection,
  islecture,
  isSubject,
  setDisablebutton,
}: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const { userData } = userInfo();
  const { courseId } = useContext(TeachersDatasContext);
  const [openAddLandMenu, setOpenAddLandMenu] = useState(false);
  const [openChangeLandMenu, setOpenChangeLandMenu] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    language === "ar" ? "Arabic" : language === "en" ? "English" : language === "tr" ? "Türkçe" : "English"
  );
  const [currentLangArray, setCurrentLangArray] = useState([currentLang]);
  const [languageArray, setLanguageArray] = useState(["English", "Arabic", "Türkçe"]);
  const [addLanguagemenu, setAddLanguagemenu] = useState<string[]>([]);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);
  const isNotAdmin = !userData?.roles.includes(ADMIN);

  useEffect(() => {
    if (
      (data?.display_name?.en || data?.lang_name?.en) &&
      (data?.display_name?.tr || data?.lang_name?.tr) &&
      (data?.display_name?.ar || data?.lang_name?.ar)
    ) {
      setCurrentLangArray(
        language === "en"
          ? ["English", "Arabic", "Türkçe"]
          : language === "ar"
          ? ["Arabic", "English", "Türkçe"]
          : language === "tr"
          ? ["Türkçe", "English", "Arabic"]
          : []
      );
      setCurrentLang(
        language === "en" ? "English" : language === "ar" ? "Arabic" : language === "tr" ? "Türkçe" : "English"
      );
      setAddLanguagemenu([]);
    } else if (
      (!data?.display_name?.en || !data?.lang_name?.en) &&
      (data?.display_name?.tr || data?.lang_name?.tr) &&
      (data?.display_name?.ar || data?.lang_name?.ar)
    ) {
      setCurrentLangArray(
        language === "en"
          ? ["Arabic", "Türkçe"]
          : language === "ar"
          ? ["Arabic", "Türkçe"]
          : language === "tr"
          ? ["Türkçe", "Arabic"]
          : []
      );
      setCurrentLang(language === "ar" ? "Arabic" : language === "tr" ? "Türkçe" : "Arabic");
      setAddLanguagemenu(["English"]);
    } else if (
      (data?.display_name?.en || data?.lang_name?.en) &&
      (!data?.display_name?.tr || !data?.lang_name?.tr) &&
      (data?.display_name?.ar || data?.lang_name?.ar)
    ) {
      setCurrentLangArray(
        language === "en"
          ? ["English", "Arabic"]
          : language === "ar"
          ? ["Arabic", "English"]
          : language === "tr"
          ? ["English", "Arabic"]
          : []
      );
      setCurrentLang(language === "en" ? "English" : language === "ar" ? "Arabic" : "English");
      setAddLanguagemenu(["Türkçe"]);
    } else if (
      (data?.display_name?.en || data?.lang_name?.en) &&
      (data?.display_name?.tr || data?.lang_name?.tr) &&
      (!data?.display_name?.ar || !data?.lang_name?.ar)
    ) {
      setCurrentLangArray(
        language === "en"
          ? ["English", "Türkçe"]
          : language === "ar"
          ? ["English", "Türkçe"]
          : language === "tr"
          ? ["Türkçe", "English"]
          : []
      );
      setCurrentLang(language === "en" ? "English" : language === "tr" ? "Türkçe" : "English");
      setAddLanguagemenu(["Arabic"]);
    } else if (
      (data?.display_name?.en || data?.lang_name?.en) &&
      (!data?.display_name?.tr || !data?.lang_name?.tr) &&
      (!data?.display_name?.ar || !data?.lang_name?.ar)
    ) {
      setCurrentLangArray(["English"]);
      setCurrentLang("English");
      setAddLanguagemenu(["Arabic", "Türkçe"]);
    } else if (
      (!data?.display_name?.en || !data?.lang_name?.en) &&
      (data?.display_name?.tr || data?.lang_name?.tr) &&
      (!data?.display_name?.ar || !data?.lang_name?.ar)
    ) {
      setCurrentLangArray(["Türkçe"]);
      setCurrentLang("Türkçe");
      setAddLanguagemenu(["English", "Arabic"]);
    } else if (
      (!data?.display_name?.en || !data?.lang_name?.en) &&
      (!data?.display_name?.tr || !data?.lang_name?.tr) &&
      (data?.display_name?.ar || data?.lang_name?.ar)
    ) {
      setCurrentLangArray(["Arabic"]);
      setCurrentLang("Arabic");
      setAddLanguagemenu(["English", "Türkçe"]);
    } else {
      setCurrentLang(
        language === "ar" ? "Arabic" : language === "en" ? "English" : language === "tr" ? "Türkçe" : "English"
      );
      setCurrentLangArray([
        language === "ar" ? "Arabic" : language === "en" ? "English" : language === "tr" ? "Türkçe" : "English",
      ]);
      const addLangList = languageArray.filter((lang) => lang !== currentLang);
      setAddLanguagemenu(addLangList);
    }
  }, [language, isOpen, data]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentLang(
        language === "ar" ? "Arabic" : language === "en" ? "English" : language === "tr" ? "Türkçe" : "English"
      );
      setCurrentLangArray([
        language === "ar" ? "Arabic" : language === "en" ? "English" : language === "tr" ? "Türkçe" : "English",
      ]);
      setLanguageArray(["English", "Arabic", "Türkçe"]);
      setAddLanguagemenu([]);
      setOpenAddLandMenu(false);
      setOpenChangeLandMenu(false);
    }
  }, [isOpen, courseId]);

  const changeLanguage = (data: any) => {
    const singleLanguage = languageArray.filter((lang) => lang === data);
    const addLangList = languageArray.filter((lang) => lang !== data);
    setCurrentLang(singleLanguage[0]);
    setCurrentLangArray([singleLanguage[0]]);
    setAddLanguagemenu(addLangList);
  };

  const addLanguage = (data: any) => {
    setAddLanguagemenu(addLanguagemenu.filter((lang) => lang !== data));
    currentLangArray.push(data);
  };

  const removeLanguage = (data: any) => {
    setCurrentLangArray(currentLangArray.filter((lang) => lang !== data));
    setAddLanguagemenu([...addLanguagemenu, data]);
    setDisablebutton(false);
    if (isSubject) {
      setValue(`label.${data === "Arabic" ? "ar" : data === "English" ? "en" : data === "Türkçe" ? "tr" : null}`, "");
      setValue(
        `lang_name.${data === "Arabic" ? "ar" : data === "English" ? "en" : data === "Türkçe" ? "tr" : null}`,
        ""
      );
    } else {
      setValue(
        `display_name.${data === "Arabic" ? "ar" : data === "English" ? "en" : data === "Türkçe" ? "tr" : null}`,
        ""
      );
    }
    if (!isSection && !islecture) {
      setValue(
        `description.${data === "Arabic" ? "ar" : data === "English" ? "en" : data === "Türkçe" ? "tr" : null}`,
        ""
      );
    }
    setCurrentLang(currentLangArray[0]);
  };

  useEffect(() => {
    if (currentLangArray?.length === 1) {
      setCurrentLang(currentLangArray[0]);
    }
  }, [currentLangArray]);

  return (
    <CourseDetailsContainer>
      <CourseLangWrapper>
        <LangTabsWrappwer>
          {currentLangArray.length > 1 ? (
            <Fragment>
              {currentLangArray.map((lang, index) => (
                <TabsContainer onClick={() => setOpenAddLandMenu(false)} isSelected={lang === currentLang} key={index}>
                  <LangTabName isSelected={lang === currentLang} onClick={() => setCurrentLang(lang)}>
                    {lang}
                  </LangTabName>
                  <LangMenuArrow isOpen={openChangeLandMenu} onClick={() => removeLanguage(lang)}>
                    <RoundedCloseIcon xColor={lang === currentLang} />
                  </LangMenuArrow>
                </TabsContainer>
              ))}
            </Fragment>
          ) : (
            <TabContainer
              isDisabled={isDisabled}
              onClick={() => {
                if (!isDisabled) {
                  setOpenChangeLandMenu(!openChangeLandMenu);
                  setOpenAddLandMenu(false);
                }
              }}
            >
              <LangName isDisabled={isDisabled}>{currentLang}</LangName>
              <LangMenuArrow isDisabled={isDisabled} isOpen={openChangeLandMenu}>
                <RoundedDownArrowIcon color={isDisabled} />
              </LangMenuArrow>
            </TabContainer>
          )}
          <AddLangMenu isOpen={openChangeLandMenu}>
            {languageArray.map((lang: string, index: number) => (
              <RadioBoxWrapper key={index}>
                <input
                  type="radio"
                  name="rating"
                  id={`edit_course_name_${index}`}
                  value={lang}
                  checked={currentLang === lang}
                  onChange={(e) => {
                    changeLanguage(e.currentTarget.value);
                    setOpenChangeLandMenu(false);
                  }}
                />
                <MenuLangName htmlFor={`edit_course_name_${index}`}>{lang}</MenuLangName>
              </RadioBoxWrapper>
            ))}
          </AddLangMenu>
        </LangTabsWrappwer>

        <LangTabsWrappwers>
          <AddLangWrapper
            isDisabled={currentLangArray.length === 3 ? true : false || isDisabled}
            onClick={() => {
              if (!isDisabled) {
                setOpenAddLandMenu(
                  addLanguagemenu.length !== 0 || currentLangArray.length !== 3 ? !openAddLandMenu : false
                );
                setOpenChangeLandMenu(false);
              }
            }}
          >
            <BigPlusIcon isDisabled={currentLangArray.length === 3 ? true : false || isDisabled} />
            <AddLangButton isDisabled={currentLangArray.length === 3 ? true : false || isDisabled}>
              {tCommon("add_languages")}
            </AddLangButton>
          </AddLangWrapper>
          <AddLangMenu isLeft isOpen={openAddLandMenu} isDisabled={isDisabled}>
            {addLanguagemenu.map((lang: string, index: number) => (
              <MenuLangName
                onClick={() => {
                  addLanguage(lang);
                  setOpenAddLandMenu(false);
                }}
                key={index}
              >
                {lang}
              </MenuLangName>
            ))}
          </AddLangMenu>
        </LangTabsWrappwers>
      </CourseLangWrapper>
      {currentLang === "English" && (
        <FormWrapper>
          {isSubject ? (
            <>
              <Input
                id="filled-basic"
                variant="filled"
                disabled={isDisabled}
                label={tCommon("subject_name")}
                {...register("lang_name.en", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.lang_name?.en)}
                helperText={errors.lang_name?.en && errors.lang_name?.en?.message}
              />
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  disabled={isDisabled}
                  label={tCommon("about_subject")}
                  multiline
                  inputProps={{ maxLength: 300 }}
                  {...register("description.en", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.description?.en) || Boolean(watch("description.en")?.length === 300)}
                  helperText={errors.description?.en && errors.description?.en?.message}
                />
                <TrackingChapters hasError={Boolean(watch("description.en")?.length === 300)}>
                  {watch("description.en")?.length}/300 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={tCommon("label")}
                  multiline
                  disabled={isNotAdmin}
                  inputProps={{ maxLength: 25 }}
                  {...register("label.en")}
                  error={Boolean(errors.label?.en) || Boolean(watch("label.en")?.length > 25)}
                  helperText={errors.label?.en && errors.label?.en?.message}
                />
                <TrackingChapters hasError={Boolean(watch("label.en")?.length > 25)}>
                  {watch("label.en")?.length || 0}/25 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
            </>
          ) : (
            <>
              {isSection || islecture ? (
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={isSection ? tCommon("section_name") : islecture ? tCommon("name") : tCommon("public_name")}
                  disabled={isDisabled}
                  {...register("display_name.en", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.display_name?.en)}
                  helperText={errors.display_name?.en && errors.display_name.en?.message}
                />
              ) : (
                <Fragment>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("public_name")}
                    disabled={isDisabled}
                    {...register("display_name.en", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.display_name?.en)}
                    helperText={errors.display_name?.en && errors.display_name.en?.message}
                  />
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("detailed_description")}
                    disabled={isDisabled}
                    {...register("description.en", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.description?.en)}
                    helperText={errors.description?.en && errors.description.en?.message}
                  />
                </Fragment>
              )}
            </>
          )}
        </FormWrapper>
      )}
      {currentLang === "Arabic" && (
        <FormWrapper>
          {isSubject ? (
            <>
              <Input
                id="filled-basic"
                variant="filled"
                disabled={isDisabled}
                label={tCommon("subject_name")}
                {...register("lang_name.ar", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.lang_name?.ar)}
                helperText={errors.lang_name?.ar && errors.lang_name?.ar?.message}
              />
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  disabled={isDisabled}
                  label={tCommon("about_subject")}
                  multiline
                  inputProps={{ maxLength: 300 }}
                  {...register("description.ar", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.description?.ar) || Boolean(watch("description.ar")?.length === 300)}
                  helperText={errors.description?.ar && errors.description?.ar?.message}
                />
                <TrackingChapters hasError={Boolean(watch("description.ar")?.length === 300)}>
                  {watch("description.ar")?.length}/300 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={tCommon("label")}
                  multiline
                  disabled={isNotAdmin}
                  inputProps={{ maxLength: 25 }}
                  {...register("label.ar")}
                  error={Boolean(errors.label?.ar) || Boolean(watch("label.ar")?.length > 25)}
                  helperText={errors.label?.ar && errors.label?.ar?.message}
                />
                <TrackingChapters hasError={Boolean(watch("label.ar")?.length > 25)}>
                  {watch("label.ar")?.length || 0}/25 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
            </>
          ) : (
            <>
              {isSection || islecture ? (
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={isSection ? tCommon("section_name") : islecture ? tCommon("name") : tCommon("public_name")}
                  disabled={isDisabled}
                  {...register("display_name.ar", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.display_name?.ar)}
                  helperText={errors.display_name?.ar && errors.display_name.ar?.message}
                />
              ) : (
                <Fragment>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("public_name")}
                    disabled={isDisabled}
                    {...register("display_name.ar", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.display_name?.ar)}
                    helperText={errors.display_name?.ar && errors.display_name.ar?.message}
                  />
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("detailed_description")}
                    disabled={isDisabled}
                    {...register("description.ar", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.description?.ar)}
                    helperText={errors.description?.ar && errors.description.ar?.message}
                  />
                </Fragment>
              )}
            </>
          )}
        </FormWrapper>
      )}
      {currentLang === "Türkçe" && (
        <FormWrapper>
          {isSubject ? (
            <>
              <Input
                id="filled-basic"
                variant="filled"
                disabled={isDisabled}
                label={tCommon("subject_name")}
                {...register("lang_name.tr", {
                  required: tCommon("required_message"),
                })}
                error={Boolean(errors.lang_name?.tr)}
                helperText={errors.lang_name?.tr && errors.lang_name?.tr?.message}
              />
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  disabled={isDisabled}
                  label={tCommon("about_subject")}
                  multiline
                  inputProps={{ maxLength: 300 }}
                  {...register("description.tr", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.description?.tr) || Boolean(watch("description.tr")?.length === 300)}
                  helperText={errors.description?.tr && errors.description?.tr?.message}
                />
                <TrackingChapters hasError={Boolean(watch("description.tr")?.length === 300)}>
                  {watch("description.tr")?.length}/300 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
              <Wrapper>
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={tCommon("label")}
                  multiline
                  disabled={isNotAdmin}
                  inputProps={{ maxLength: 25 }}
                  {...register("label.tr")}
                  error={Boolean(errors.label?.tr) || Boolean(watch("label.tr")?.length > 25)}
                  helperText={errors.label?.tr && errors.label?.tr?.message}
                />
                <TrackingChapters hasError={Boolean(watch("label.tr")?.length > 25)}>
                  {watch("label.tr")?.length || 0}/25 {tCommon("character")}
                </TrackingChapters>
              </Wrapper>
            </>
          ) : (
            <>
              {isSection || islecture ? (
                <Input
                  id="filled-basic"
                  variant="filled"
                  label={isSection ? tCommon("section_name") : islecture ? tCommon("name") : tCommon("public_name")}
                  disabled={isDisabled}
                  {...register("display_name.tr", {
                    required: tCommon("required_message"),
                  })}
                  error={Boolean(errors.display_name?.tr)}
                  helperText={errors.display_name?.tr && errors.display_name.tr?.message}
                />
              ) : (
                <Fragment>
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("public_name")}
                    disabled={isDisabled}
                    {...register("display_name.tr", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.display_name?.tr)}
                    helperText={errors.display_name?.tr && errors.display_name.tr?.message}
                  />
                  <Input
                    id="filled-basic"
                    variant="filled"
                    label={tCommon("detailed_description")}
                    disabled={isDisabled}
                    {...register("description.tr", {
                      required: tCommon("required_message"),
                    })}
                    error={Boolean(errors.description?.tr)}
                    helperText={errors.description?.tr && errors.description.tr?.message}
                  />
                </Fragment>
              )}
            </>
          )}
        </FormWrapper>
      )}
    </CourseDetailsContainer>
  );
};

export default MultibleLanguagesInputs;
