import Axios from "@Configs/Axios";
import { Lecture, VideoLecture } from "types/Lecture";

export const _CreateLecture = (data: Lecture, instructorId: number | null, lectureTpe: string) =>
  Axios.post(`/v1/lectures/${lectureTpe}`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _UpdateLecture = (id: number | null, data: Lecture, instructorId: number | null, lectureTpe: string) =>
  Axios.patch(`/v1/lectures/${id}/${lectureTpe}`, data, {
    headers: {
      lecture_id: id,
      instructor_id: instructorId,
    },
  });

export const _CreateIntroduction = (id: number, data: Lecture, instructorId: number | null) =>
  Axios.patch(`/v1/lectures/${id}/video/introduction`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _CreateFileLecture = (data: Lecture, instructorId: number | null, lectureTpe: string) =>
  Axios.post(`/v1/lectures/file`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _CreateVideoLecture = (data: Lecture, instructorId: number | null) =>
  Axios.post(`/v1/lectures/video`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _UpdateFileLecture = (id: number | null, data: Lecture, instructorId: number | null) =>
  Axios.patch(`/v1/lectures/${id}/file`, data, {
    headers: {
      lecture_id: id,
      instructor_id: instructorId,
    },
  });

export const _GetFileLecture = (id: number | null) =>
  Axios.get(`/v1/lectures/${id}/file`).then(({ data }) => data.data);

export const _GetFileDownloadLink = (s3FileName: string) =>
  Axios.get(`/v1/s3/get-signed-download-url?fileName=${s3FileName}`).then(({ data }) => data.data);

export const _UpdateVideoLecture = (id: number | null, data: VideoLecture, instructorId: number | null) =>
  Axios.patch(`/v1/lectures/${id}/video`, data, {
    headers: {
      lecture_id: id,
      instructor_id: instructorId,
    },
  });

export const _DeleteLecture = (id: number | null, type: string, data, instructorId: number | null) =>
  Axios.delete(`/v1/lectures/${id}/${type}`, {
    headers: { lecture_id: id, instructor_id: instructorId },
    data,
  });

export const _GetVideoLecture = (id: number | null) =>
  Axios.get(`/v1/lectures/${id}/video`).then(({ data }) => data.data);

export const _GetLecture = (id: number | null, type: string) =>
  Axios.get(`/v1/lectures/${id}/${type}`).then(({ data }) => data.data);
