import styled, { css } from "styled-components";

export const PersonalContent = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const BioContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ProfileContentContainer = styled.div`
  padding-top: 20px;
`;

export const ProfileBio = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  width: 100%;
  min-height: 100px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray5_old};
  border-radius: 8px;
`;

export const BioTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const BioDescription = styled.p<{ readonly capitalize?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  ${({ capitalize }) =>
    capitalize &&
    css`
      text-transform: uppercase;
    `};
`;

export const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  width: 84px;
  height: 40px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.colors.seagule};
  border-radius: 100px;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.seagule};
`;
