import Axios from "@Configs/Axios";
import { Course, SortCoursesData } from "types/Courses";

export const _SortingCourses = (id: number | null, data?: SortCoursesData) =>
  Axios.post(`/v1/courses/${id}/sort`, data);

export const _CreateCourse = (data: Course) => Axios.post(`/v1/courses`, data);

export const _UpdateCourse = (id: number | null, data: Course, instructorId: number | null) =>
  Axios.patch(`/v1/courses/${id}`, data, {
    headers: {
      course_id: id,
      instructor_id: instructorId,
    },
  });

export const _DeleteCourse = (id: number | null, instructorId: number | null) =>
  Axios.delete(`/v1/courses/${id}`, {
    headers: {
      course_id: id,
      instructor_id: instructorId,
    },
  });

export const _GetCourseData = (id: number | null) => Axios.get(`/v1/courses/${id}`).then((data) => data.data.data);

export const _GetCourseDetails = (id: number | null) =>
  Axios.get(`/v1/courses/${id}/details`).then((data) => data.data.data);

export const _GetCourseSections = (id: number | null) =>
  Axios.get(`/v1/courses/${id}/sections`).then((data) => data.data.data);
