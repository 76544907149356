const GridConfig = {
  gridColumns: 12, // default 12
  breakpoints: {
    // defaults below
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 767,
    sm: 576,
    xs: 360,
    // or you can use aliases
    // veryGiant: 1440,
    // giant: 1200,
    // desktop: 992,
    // tablet: 768,
    // phone: 576,
    // smaller: 575,
  },
  row: {
    padding: 12, // default 15
  },
  col: {
    padding: 12, // default 15
  },
  container: {
    padding: 12, // default 15
    maxWidth: {
      // defaults below
      xxl: 1160,
      xl: 1160,
      lg: 980,
      md: 740,
      sm: 560,
      xs: 575,
      // or you can use aliases
      // veryGiant: 1141,
      // giant: 1140,
      // desktop: 960,
      // tablet: 720,
      // phone: 540,
      // smaller: 540,
    },
  },
};
export default GridConfig;
