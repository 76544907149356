export const TextBaimsLogo = () => {
  return (
    <svg width="99" height="44" viewBox="0 0 99 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.1758 43.5014C10.3863 43.5014 0.794281 33.9094 0.794281 22.1198C0.794281 10.3303 10.3863 0.738281 22.1758 0.738281C33.9654 0.738281 43.5574 10.3303 43.5574 22.1198C43.5574 33.9094 33.9666 43.5014 22.1758 43.5014ZM22.1758 3.09228C11.6843 3.09228 3.14828 11.6283 3.14828 22.1198C3.14828 32.6114 11.6843 41.1474 22.1758 41.1474C32.6674 41.1474 41.2034 32.6114 41.2034 22.1198C41.2046 11.6283 32.6686 3.09228 22.1758 3.09228Z"
        fill="white"
      />
      <path
        d="M22.1763 36.9645C13.9923 36.9645 7.33359 30.3058 7.33359 22.1206C7.33359 13.9354 13.9923 7.27673 22.1775 7.27673C30.3627 7.27673 37.0201 13.9354 37.0201 22.1206C37.0201 30.3058 30.3615 36.9645 22.1763 36.9645ZM22.1763 9.63073C15.289 9.63073 9.68637 15.2334 9.68637 22.1206C9.68637 29.0078 15.289 34.6105 22.1763 34.6105C29.0635 34.6105 34.6661 29.0078 34.6661 22.1206C34.6661 15.2334 29.0635 9.63073 22.1763 9.63073Z"
        fill="white"
      />
      <path
        d="M22.1752 30.4251C17.5955 30.4251 13.8702 26.6997 13.8702 22.1201C13.8702 17.5404 17.5955 13.8151 22.1752 13.8151C26.7549 13.8151 30.4802 17.5404 30.4802 22.1201C30.4802 26.6997 26.7549 30.4251 22.1752 30.4251ZM22.1752 16.1691C18.8935 16.1691 16.2242 18.8384 16.2242 22.1201C16.2242 25.4017 18.8935 28.0711 22.1752 28.0711C25.4569 28.0711 28.1262 25.4017 28.1262 22.1201C28.1262 18.8384 25.4569 16.1691 22.1752 16.1691Z"
        fill="white"
      />
      <path
        d="M52.1288 28.0515V16.262H56.6412C57.4931 16.262 58.202 16.3952 58.7679 16.6616C59.3338 16.9281 59.7579 17.2923 60.0402 17.7519C60.3225 18.2126 60.4631 18.7345 60.4631 19.3175C60.4631 19.7978 60.3714 20.2073 60.1869 20.5471C60.0023 20.8868 59.7554 21.1618 59.445 21.3733C59.1345 21.5847 58.7862 21.7363 58.4024 21.828V21.9429C58.8204 21.9661 59.2225 22.0932 59.6088 22.3254C59.995 22.5576 60.3103 22.8852 60.556 23.3069C60.8016 23.7285 60.9239 24.2394 60.9239 24.8383C60.9239 25.4482 60.776 25.9957 60.4802 26.4822C60.1844 26.9674 59.7395 27.35 59.1443 27.6311C58.5491 27.911 57.8011 28.0515 56.8991 28.0515H52.1288ZM54.264 21.2755H56.3772C56.7463 21.2755 57.0775 21.2071 57.3733 21.0714C57.6691 20.9357 57.9025 20.7414 58.0724 20.4896C58.2435 20.2379 58.3291 19.9396 58.3291 19.595C58.3291 19.1379 58.169 18.7626 57.8488 18.4669C57.5285 18.1711 57.0531 18.0232 56.4236 18.0232H54.2652V21.2755H54.264ZM54.264 26.2671H56.5605C57.3354 26.2671 57.8952 26.118 58.2386 25.821C58.5821 25.524 58.7532 25.1414 58.7532 24.6721C58.7532 24.3225 58.6664 24.0072 58.4941 23.7248C58.3218 23.4425 58.0761 23.2201 57.7571 23.0575C57.4381 22.895 57.058 22.8131 56.6168 22.8131H54.2628V26.2671H54.264Z"
        fill="white"
      />
      <path
        d="M65.2364 28.0538C64.6767 28.0538 64.1731 27.9536 63.7258 27.7519C63.2784 27.5503 62.9252 27.2521 62.6661 26.8573C62.407 26.4625 62.2774 25.9748 62.2774 25.3955C62.2774 24.8968 62.3691 24.4837 62.5537 24.1574C62.7382 23.8311 62.9888 23.5707 63.3078 23.3739C63.6268 23.1784 63.9861 23.0293 64.387 22.9278C64.7879 22.8264 65.2034 22.7518 65.6337 22.7066C66.1519 22.6528 66.5723 22.6039 66.895 22.5599C67.2177 22.5159 67.4523 22.4463 67.6002 22.3522C67.7481 22.2581 67.8214 22.1114 67.8214 21.9122V21.8779C67.8214 21.4441 67.6931 21.1079 67.4352 20.8708C67.1785 20.6325 66.8082 20.5139 66.3242 20.5139C65.8133 20.5139 65.41 20.6252 65.1118 20.8476C64.8148 21.0701 64.6143 21.3328 64.5104 21.6359L62.5647 21.3597C62.7187 20.8219 62.9717 20.3722 63.3249 20.0104C63.6781 19.6474 64.1095 19.3748 64.6204 19.1927C65.1313 19.0106 65.6948 18.9189 66.3132 18.9189C66.7398 18.9189 67.1627 18.9691 67.5855 19.0693C68.0084 19.1695 68.3934 19.3333 68.743 19.5618C69.0925 19.7904 69.3737 20.0996 69.5863 20.4919C69.799 20.8831 69.9053 21.3732 69.9053 21.9598V27.8778H67.9021V26.6629H67.8337C67.7065 26.9086 67.5293 27.1384 67.3008 27.3511C67.0722 27.5637 66.7862 27.7348 66.4428 27.8632C66.0993 27.9915 65.6972 28.0538 65.2364 28.0538ZM65.7779 26.5224C66.1959 26.5224 66.5589 26.4393 66.8657 26.2718C67.1724 26.1044 67.4095 25.8832 67.577 25.6069C67.7444 25.3307 67.8275 25.0288 67.8275 24.7037V23.6612C67.7628 23.7149 67.6515 23.7651 67.4963 23.8103C67.3411 23.8567 67.1675 23.8971 66.9757 23.9313C66.7838 23.9655 66.5943 23.9961 66.4061 24.0229C66.2179 24.0498 66.0553 24.0731 65.9172 24.0914C65.6068 24.1342 65.3281 24.2026 65.0824 24.2992C64.8368 24.3957 64.6424 24.5289 64.5007 24.6988C64.3589 24.8699 64.288 25.0899 64.288 25.3576C64.288 25.7414 64.4285 26.0311 64.7084 26.2266C64.9871 26.4246 65.344 26.5224 65.7779 26.5224Z"
        fill="white"
      />
      <path
        d="M73.053 18.4563C72.7194 18.4563 72.4346 18.3463 72.1987 18.1251C71.9628 17.9039 71.8442 17.6386 71.8442 17.3282C71.8442 17.0141 71.9628 16.7464 72.2011 16.5252C72.4395 16.304 72.723 16.194 73.053 16.194C73.383 16.194 73.6666 16.304 73.9025 16.5252C74.1384 16.7464 74.2569 17.0141 74.2569 17.3282C74.2569 17.6386 74.1384 17.9051 73.9025 18.1251C73.6666 18.3463 73.383 18.4563 73.053 18.4563ZM72.0056 28.0532V19.2116H74.0895V28.0532H72.0056Z"
        fill="white"
      />
      <path
        d="M76.2315 28.0556V19.214H78.2238V20.7161H78.3276C78.5122 20.2101 78.8165 19.8129 79.2431 19.5269C79.6684 19.2409 80.1781 19.0979 80.7684 19.0979C81.3673 19.0979 81.8733 19.2433 82.2852 19.533C82.6971 19.8227 82.988 20.2175 83.1579 20.7161H83.2495C83.4451 20.2248 83.7763 19.8325 84.2432 19.5391C84.7101 19.2458 85.2625 19.0991 85.9042 19.0991C86.7182 19.0991 87.3819 19.3558 87.8964 19.8703C88.411 20.3849 88.6676 21.1353 88.6676 22.1217V28.0568H86.5776V22.4443C86.5776 21.8956 86.4322 21.4935 86.1401 21.238C85.848 20.9826 85.4911 20.8555 85.0694 20.8555C84.5671 20.8555 84.1748 21.0119 83.8924 21.3248C83.6101 21.6377 83.4695 22.0435 83.4695 22.5421V28.0568H81.426V22.3576C81.426 21.9005 81.2891 21.5362 81.0141 21.2637C80.7391 20.9911 80.3822 20.8555 79.941 20.8555C79.6415 20.8555 79.369 20.9312 79.1233 21.0828C78.8776 21.2343 78.6821 21.447 78.5366 21.722C78.3912 21.997 78.3179 22.316 78.3179 22.6802V28.0568L76.2315 28.0556Z"
        fill="white"
      />
      <path
        d="M97.7722 21.3743L95.8729 21.582C95.8191 21.3902 95.7262 21.2105 95.5942 21.0406C95.4622 20.8719 95.2838 20.735 95.0613 20.6324C94.8389 20.5285 94.5663 20.4772 94.2436 20.4772C93.8098 20.4772 93.4468 20.5713 93.1534 20.7595C92.8601 20.9477 92.7146 21.1909 92.7183 21.4904C92.7146 21.747 92.8088 21.9573 93.0031 22.1174C93.1962 22.2787 93.5176 22.4107 93.9674 22.5146L95.4756 22.8373C96.3116 23.0182 96.935 23.3042 97.3432 23.6953C97.7514 24.0864 97.958 24.5985 97.9616 25.2328C97.958 25.7889 97.7954 26.279 97.4752 26.7032C97.155 27.1273 96.7101 27.4585 96.143 27.6956C95.5759 27.9327 94.9232 28.0525 94.1862 28.0525C93.1045 28.0525 92.2331 27.8252 91.5731 27.3705C90.9131 26.9158 90.5195 26.2815 90.3924 25.4675L92.425 25.2719C92.5166 25.6716 92.7122 25.9723 93.0116 26.1752C93.3111 26.378 93.701 26.4807 94.1801 26.4807C94.6751 26.4807 95.0735 26.3793 95.3742 26.1752C95.6749 25.9723 95.8264 25.7205 95.8264 25.421C95.8264 25.168 95.7299 24.959 95.5355 24.794C95.3412 24.629 95.0418 24.5019 94.6348 24.4139L93.1265 24.0974C92.2783 23.9214 91.6513 23.6219 91.2443 23.2027C90.8373 22.7823 90.6357 22.2494 90.6393 21.6053C90.6357 21.0602 90.7848 20.5872 91.0854 20.1863C91.3861 19.7854 91.8065 19.4749 92.3468 19.2537C92.8858 19.0325 93.5091 18.9225 94.2143 18.9225C95.2508 18.9225 96.0672 19.1437 96.6636 19.5849C97.2601 20.0262 97.6292 20.6226 97.7722 21.3743Z"
        fill="white"
      />
    </svg>
  );
};
