import Layout from "@Components/Layout";
import NotFound from "@Components/NotFound";
import { Routes, Route } from "react-router-dom";
import Course from "routes/Course";
import Overview from "routes/Overview";
import Subjects from "routes/Subjects";
import Teachers from "routes/Teachers";
import Profile from "routes/Profile";

export default function PagesRoute() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Teachers />} />
        <Route path="/:teacherid/subjects" element={<Subjects />} />
        <Route path="/:teacherid/profile" element={<Profile />} />
        <Route path="/:subjectid/overview" element={<Overview />} />
        <Route path="/:courseid/course" element={<Course />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}
