export const OverViewIcon = ({ overviewActive }: { overviewActive: any }) => {
  return (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.732483 10.1267H4.19342C4.54759 10.1267 4.76113 9.97049 4.83405 9.65799L6.34186 2.72049H5.74811L8.06061 16.6111C8.08144 16.7517 8.14134 16.8559 8.2403 16.9236C8.34446 16.9913 8.45905 17.0252 8.58405 17.0252C8.71425 17.0252 8.83144 16.9913 8.93561 16.9236C9.03977 16.8559 9.10488 16.7517 9.13092 16.6111L11.3731 6.08768L10.8809 6.07987L11.584 9.59549C11.6518 9.94966 11.8653 10.1267 12.2247 10.1267H15.2559C15.4122 10.1267 15.545 10.0747 15.6544 9.97049C15.7637 9.86112 15.8184 9.73091 15.8184 9.57987C15.8184 9.42362 15.7637 9.29341 15.6544 9.18924C15.5502 9.07987 15.4174 9.02518 15.2559 9.02518H11.5684L12.6934 9.93924L11.5919 5.04862C11.5606 4.9132 11.4955 4.81424 11.3965 4.75174C11.2976 4.68403 11.1882 4.65018 11.0684 4.65018C10.9486 4.65018 10.8366 4.68664 10.7325 4.75955C10.6335 4.82726 10.5684 4.93143 10.5372 5.07205L8.25592 15.2674H8.81061L6.52155 1.38455C6.50071 1.24914 6.44342 1.14757 6.34967 1.07987C6.25592 1.00695 6.14915 0.970492 6.02936 0.970492C5.91477 0.965283 5.808 0.999137 5.70905 1.07205C5.61009 1.13976 5.54498 1.24393 5.51373 1.38455L3.66217 9.93924L4.78717 9.02518H0.732483C0.571025 9.02518 0.435608 9.07987 0.326233 9.18924C0.222066 9.29862 0.169983 9.42882 0.169983 9.57987C0.169983 9.73091 0.222066 9.86112 0.326233 9.97049C0.435608 10.0747 0.571025 10.1267 0.732483 10.1267Z"
        fill={overviewActive ? "white" : "rgba(255, 255, 255, 0.7)"}
        fillOpacity="0.7"
      />
    </svg>
  );
};
