export const LogoutIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.35187 17.3799H11.6487C12.456 17.3799 13.0602 17.1768 13.4612 16.7705C13.8675 16.3643 14.0706 15.7523 14.0706 14.9346V11.3096H12.8128V14.9111C12.8128 15.3018 12.7086 15.6012 12.5003 15.8096C12.2972 16.0179 11.9925 16.1221 11.5862 16.1221H3.41437C3.00812 16.1221 2.70083 16.0179 2.49249 15.8096C2.28937 15.6012 2.18781 15.3018 2.18781 14.9111V3.10645C2.18781 2.71582 2.28937 2.41634 2.49249 2.20801C2.70083 1.99447 3.00812 1.8877 3.41437 1.8877H11.5862C11.9925 1.8877 12.2972 1.99447 12.5003 2.20801C12.7086 2.41634 12.8128 2.71582 12.8128 3.10645V6.7002H14.0706V3.08301C14.0706 2.27051 13.8675 1.65853 13.4612 1.24707C13.0602 0.835612 12.456 0.629883 11.6487 0.629883H3.35187C2.54458 0.629883 1.93781 0.835612 1.53156 1.24707C1.13051 1.65853 0.929993 2.27051 0.929993 3.08301V14.9346C0.929993 15.7523 1.13051 16.3643 1.53156 16.7705C1.93781 17.1768 2.54458 17.3799 3.35187 17.3799ZM8.15656 9.62207H16.18L17.3519 9.5752L16.7972 10.1064L15.5394 11.2861C15.4144 11.3955 15.3519 11.5361 15.3519 11.708C15.3519 11.8695 15.4013 12.0049 15.5003 12.1143C15.6045 12.2236 15.7373 12.2783 15.8987 12.2783C16.0498 12.2783 16.1878 12.2184 16.3128 12.0986L18.8597 9.45801C18.9378 9.37988 18.9899 9.30436 19.0159 9.23145C19.0472 9.15853 19.0628 9.08301 19.0628 9.00488C19.0628 8.92676 19.0472 8.85124 19.0159 8.77832C18.9899 8.7054 18.9378 8.62988 18.8597 8.55176L16.3128 5.91113C16.1878 5.78613 16.0498 5.72363 15.8987 5.72363C15.7373 5.72363 15.6045 5.77832 15.5003 5.8877C15.4013 5.99186 15.3519 6.12467 15.3519 6.28613C15.3519 6.46322 15.4144 6.60645 15.5394 6.71582L16.7972 7.90332L17.3519 8.43457L16.18 8.37988H8.15656C7.9951 8.37988 7.85447 8.44238 7.73468 8.56738C7.61489 8.69238 7.55499 8.83822 7.55499 9.00488C7.55499 9.17155 7.61489 9.31738 7.73468 9.44238C7.85447 9.56217 7.9951 9.62207 8.15656 9.62207Z"
        fill="#F25151"
      />
    </svg>
  );
};
