import Axios from "@Configs/Axios";
import { Section } from "types/Sections";

export const _SortingSectionsAndLectures = (Course_id: number | null, instructorId: number | null, data: any) =>
  Axios.post(`/v1/courses/${Course_id}/sort/sections-and-lectures`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _CreateSection = (data: Section, instructorId: number | null) =>
  Axios.post(`/v1/sections`, data, {
    headers: {
      instructor_id: instructorId,
    },
  });

export const _UpdateSection = (data: Section, id: number | null, instructorId: number | null) =>
  Axios.patch(`/v1/sections/${id}`, data, {
    headers: {
      section_id: id,
      instructor_id: instructorId,
    },
  });

export const _DeleteSection = (id: number | null, instructorId: number | null) =>
  Axios.delete(`/v1/sections/${id}`, {
    headers: {
      section_id: id,
      instructor_id: instructorId,
    },
  });

export const _GetSections = (id: number | null) => Axios.get(`/v1/sections/${id}`).then(({ data }) => data.data);
