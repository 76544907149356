import { EditIcon } from "images/icons/EditIcon";
import {
  AddButton,
  ButtonText,
  HeaderContainer,
  HeaderTitle,
  LastUpdate,
  SubjectsHeaderTitle,
} from "./RegularHeader.styled";
import { Dispatch, SetStateAction } from "react";

type Props = {
  icon?: boolean;
  title: string;
  lastUpdate: string;
  buttonTitle: string;
  openPopup: Dispatch<SetStateAction<boolean>>;
};

const RegularHeader = ({ icon, title, lastUpdate, buttonTitle, openPopup }: Props) => {
  return (
    <HeaderContainer>
      <SubjectsHeaderTitle>
        <HeaderTitle>{title}</HeaderTitle>
        {lastUpdate !== null && <LastUpdate>{lastUpdate}</LastUpdate>}
      </SubjectsHeaderTitle>
      <AddButton onClick={() => openPopup(true)}>
        {icon && <EditIcon />}
        <ButtonText>{buttonTitle}</ButtonText>
      </AddButton>
    </HeaderContainer>
  );
};

export default RegularHeader;
