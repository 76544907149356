import { TeachersDatasContext } from "context/Teachers.context";
import { DragDropIcon } from "images/icons/DragDropIcon";
import { OverViewIcon } from "images/icons/OverViewIcon";
import { PlusIcon } from "images/icons/PlusIcon";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import {
  AddCourseButton,
  ChapterState,
  ChaptersWrapper,
  ChapterTitle,
  Courses,
  CoursesContainer,
  CoursesIcons,
  CoursesInfoWrapper,
  CoursesList,
  CoursesSaveSort,
  CoursesTitle,
  EmptyCourses,
  OverView,
  OverViewTitle,
  SubjectSidebar,
  SubjectSidebarContent,
  SubjectTitle,
} from "./CoursesSideBar.styled";
import { _GetSubjectCourses } from "@Services/Subjects";
import { _SortingCourses } from "@Services/Courses";
import { useTranslation } from "react-i18next";
import SubjectCoursesSkeleton from "@Components/Skeletons/SubjectCoursesSkeleton";
import TitleSkeleton from "@Components/Skeletons/TitleSkeleton";
import { useTablesFilters } from "context/TablesFilters.context";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";
import { getSubjectName } from "utils/Strings";

const CoursesSideBar = () => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const divRef = useRef(null);
  const h4Ref = useRef(null);
  const [coursesSort, setCoursesSort] = useState([]);
  const location = useLocation();
  const { setCancleSavingSectionSort } = useContext(TeachersDatasContext);
  const path = location?.pathname.split("/")[location?.pathname.split("/").length - 1];
  const [overviewActive, setOverviewActive] = useState(false);
  const { userData } = userInfo();
  const {
    subjectId,
    setAddCoursePopup,
    subjectStatistics,
    loadingSubjectStatistics,
    subjectCourses,
    refetchSubjectCourses,
    courseId,
    setCourseId,
    subjectCoursesLoading,
  } = useContext(TeachersDatasContext);
  const {
    isSectionsSorted,
    setIsSectionsSorted,
    setWorningSectionsSortPopup,
    setWorningSortType,
    worningSectionsSortPopup,
    worningSortType,
    setSubjectTitleHeight,
  } = useTablesFilters();
  const [isActive, setIsActive] = useState(courseId);
  const [showButtons, setShowButtons] = useState(false);
  const [todo, setTodo] = useState(subjectCourses);

  useEffect(() => {
    if (path === "overview") {
      setOverviewActive(true);
      setIsActive(0);
    } else {
      setOverviewActive(false);
    }
  }, [path]);

  useEffect(() => {
    if (subjectCourses) {
      setTodo(subjectCourses);
    }
  }, [subjectCourses]);

  const onDragEnd = (result: DropResult) => {
    if (result) {
      setShowButtons(true);
    }
    const { source, destination } = result;
    if (!destination) return;
    const items = Array.from(todo);
    const [newOrder] = items.splice(source.index, 1);
    items.splice(destination.index, 0, newOrder);
    setTodo(items);
    setIsSectionsSorted(true);
    setWorningSortType("courses");
  };

  const onSaveSorting = () => {
    _SortingCourses(subjectId, {
      courses: coursesSort,
    });
    setShowButtons(false);
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  const onCancleSorting = () => {
    setTodo(subjectCourses);
    setShowButtons(false);
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  useEffect(() => {
    setCoursesSort(
      todo?.map((course: any, index: number) => ({
        course_id: course.id,
        order_id: index + 1,
      }))
    );
  }, [todo]);

  useEffect(() => {
    if (isSectionsSorted && worningSectionsSortPopup && worningSortType === "courses") {
      divRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [isSectionsSorted, worningSectionsSortPopup, worningSortType]);

  useEffect(() => {
    if (h4Ref.current && h4Ref.current.clientHeight > 36) {
      setSubjectTitleHeight(true);
    } else {
      setSubjectTitleHeight(false);
    }
  }, [h4Ref.current]);

  return (
    <SubjectSidebar isAdmin={userData?.roles.includes(ADMIN)}>
      <SubjectSidebarContent ref={divRef}>
        {!loadingSubjectStatistics ? (
          <SubjectTitle ref={h4Ref}>{getSubjectName(subjectStatistics, language)}</SubjectTitle>
        ) : (
          <TitleSkeleton />
        )}
        <Link to={!isSectionsSorted && `/${subjectId}/overview`}>
          <OverView
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              }
            }}
            overviewActive={overviewActive}
          >
            <OverViewIcon overviewActive={overviewActive} />
            <OverViewTitle overviewActive={overviewActive}>{tCommon("overview")}</OverViewTitle>
          </OverView>
        </Link>
        <Courses>
          <CoursesTitle>{tCommon("courses")}</CoursesTitle>
          {showButtons ? (
            <CoursesSaveSort>
              <AddCourseButton onClick={onCancleSorting}>{tCommon("cancel")}</AddCourseButton>
              <AddCourseButton onClick={onSaveSorting}>{tCommon("save")}</AddCourseButton>
            </CoursesSaveSort>
          ) : (
            <CoursesIcons
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  setAddCoursePopup(true);
                }
              }}
            >
              <PlusIcon />
              <AddCourseButton>{tCommon("add_new")}</AddCourseButton>
            </CoursesIcons>
          )}
        </Courses>
        <Fragment>
          {!subjectCoursesLoading ? (
            <Fragment>
              {subjectCourses?.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="todo">
                    {(provided) => (
                      <CoursesList className="todo" {...provided.droppableProps} ref={provided.innerRef}>
                        {todo?.map((item: any, index: any) => {
                          return (
                            <Draggable key={item?.id} draggableId={item?.id?.toString()} index={index}>
                              {(provided, snapshot) => (
                                <ChaptersWrapper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  isDraged={snapshot.isDragging}
                                >
                                  <Link to={!isSectionsSorted && `/${item?.id}/course`} style={{ width: "100%" }}>
                                    <CoursesContainer
                                      onClick={() => {
                                        if (isSectionsSorted) {
                                          setWorningSectionsSortPopup(true);
                                        } else {
                                          setIsActive(item?.id);
                                          refetchSubjectCourses();
                                          setCourseId(item?.id);
                                          setCancleSavingSectionSort(true);
                                        }
                                      }}
                                      active={isActive == item?.id}
                                    >
                                      <div {...provided.dragHandleProps}>
                                        <DragDropIcon active={isActive == item?.id} color={snapshot.isDragging} />
                                      </div>
                                      <CoursesInfoWrapper>
                                        <ChapterTitle active={isActive == item?.id}>{item?.name}</ChapterTitle>
                                        {!item?.is_active && <ChapterState>{tCommon("inactive")}</ChapterState>}
                                      </CoursesInfoWrapper>
                                    </CoursesContainer>
                                  </Link>
                                </ChaptersWrapper>
                              )}
                            </Draggable>
                          );
                        })}
                      </CoursesList>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <EmptyCourses>{tCommon("no_course_added")}</EmptyCourses>
              )}
            </Fragment>
          ) : (
            <SubjectCoursesSkeleton />
          )}
        </Fragment>
      </SubjectSidebarContent>
    </SubjectSidebar>
  );
};

export default CoursesSideBar;
