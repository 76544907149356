import { CommonButton } from "@Components/common/CommonButton";
import styled, { css } from "styled-components";

export const SectionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.gray5_old};
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
  .ReactCollapse--collapse {
    transition: height 400ms;
  }
`;

export const SectionWrapper = styled.div<{ readonly isOpened?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0px 18px;
  ${({ isOpened }) =>
    isOpened
      ? css`
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
        `
      : css`
          border-radius: 8px;
        `}
`;

export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SectionName = styled.span``;

export const EditButton = styled(CommonButton)`
  height: 28px;
  width: 68px;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
`;

export const ArrowWrapper = styled.div<{ readonly flex?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 52px;
  cursor: pointer;
  ${({ flex }) =>
    flex &&
    css`
      flex: 1;
    `}
`;

export const SectionErrorMessage = styled.p<{ readonly hasError?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
  visibility: ${({ hasError }) => (hasError ? "visible" : "hidden")};
  opacity: ${({ hasError }) => (hasError ? 1 : 0)};
  transition: all 0.3s ease;
`;

export const StyledTable = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.gray5_old};
  border-spacing: 0px;
`;

export const TableHead = styled.thead``;

export const TableHeadTr = styled.tr`
  background: ${({ theme }) => theme.colors.gray7};
`;

export const TableHeadTh = styled.th`
  padding: 12px 16px;
  text-align: start;
  width: 24.5%;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
`;

export const TableBody = styled.tbody`
  width: 100%;
`;

export const TableBodyTr = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
    cursor: pointer;
  }
`;

export const TableBodyth = styled.th`
  padding: 12px 16px;
  width: 20%;
  text-align: start;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
  .link_style {
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    font-size: ${({ theme }) => theme.fontSizes.body14};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.nero};
  }
  .is_balance {
    text-transform: uppercase;
  }
  &:first-child {
    width: 25%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    width: 10%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TeacherCoulmn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ImageContainer = styled.div``;

export const TeacherImg = styled.img`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
  z-index: 20;
`;

export const TeacherImgDiv = styled.div`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  margin-top: -40px;
`;

export const TeacherName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const LectureTypeName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const TeacherId = styled(TeacherName)`
  color: ${({ theme }) => theme.colors.gray3};
`;

export const LectureNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const FileTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
