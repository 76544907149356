import styled from "styled-components";
import OtpInput from "react-otp-input";
import Rodal from "rodal";

export const ButtonContainer = styled.div<{
  readonly textColor?: string;
  readonly backgroundColor?: string;
  readonly fill?: boolean;
  readonly hoverChange?: boolean;
  readonly disabled?: boolean;
  readonly size?: string;
}>`
  z-index: 1;
  ${({ theme, disabled }) =>
    disabled &&
    `
      a,
      button {
        pointer-events: none;
        color: ${theme.colors.white};
        background: ${theme.colors.gray4} !important;
        cursor: not-allowed;
      }
    `}
  a,
    button {
    display: flex;
    border: 0;
    margin-top: 30px;
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fontWeights.font500};
    width: 100%;
    justify-content: center;
    color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.white)};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor ? theme.colors[backgroundColor] : theme.colors.seagule};
    border-radius: ${({ theme }) => theme.buttons.borderRadius};
    font-size: ${({ theme, size }) => (size ? theme.fontSizes[size] : theme.buttons.fontSize)};
    padding: ${({ theme }) => theme.buttons.padding};
    transition: all 0.3s ease-in-out;
    &.not-fill {
      border: 1.5px solid ${({ theme }) => theme.colors.seagule};
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.seagule};
      &:hover {
        border-color: ${({ theme }) => theme.colors.seagule};
        background: inherit;
      }
      &[disabled] {
        color: ${({ theme }) => theme.colors.white};
        border: 1.5px solid ${({ theme }) => theme.colors.gray4};
        background: ${({ theme }) => theme.colors.gray4};
        cursor: not-allowed;
      }
    }
    &:hover {
      background: ${({ theme, hoverChange, backgroundColor }) =>
        hoverChange ? theme.colors.seaguleDarker : theme.colors[backgroundColor]};
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const OtpStyledRodal = styled(Rodal)`
  .rodal-mask {
    background: rgba(0, 0, 0, 0.3);
  }
  .rodal-close {
    display: none;
  }
  .rodal-dialog {
    min-width: 450px !important;
    max-height: 500px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 20px;
  }
`;

export const StepContainer = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${ButtonContainer} {
    button {
      padding: 16px 21px;
    }
  }
`;

export const BackIcon = styled.div`
  position: absolute;
  top: 22px;
  left: 22px;
  cursor: pointer;
  line-height: 0;
  .MuiSvgIcon-root {
    transform: scale(1.4, 1.4);
    fill: ${({ theme }) => theme.colors.gray3};
    width: 24px;
    height: 24px;
  }
`;

export const HeaderContainer = styled.div``;

export const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSizes.h4};
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  color: ${({ theme }) => theme.colors.nero};
  text-align: center;
  margin-bottom: 8px;
`;

export const Paragraph = styled.p<{
  readonly textAlign?: string;
  readonly textColor?: string;
}>`
  font-size: ${({ theme }) => theme.fontSizes.body14};
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.gray2)};
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin-bottom: 10px;
  margin-top: 30px;
  a {
    color: ${({ theme }) => theme.colors.seagule};
    font-weight: ${({ theme }) => theme.fontWeights.font600};
  }
`;

export const ParagraphInner = styled.span<{
  readonly textAlign?: string;
  readonly textColor?: string;
  readonly direction?: string;
}>`
  font-size: inherit;
  font-weight: inherit;
  color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : "inherit")};
  text-align: ${({ textAlign }) => textAlign || "inherit"};
  direction: ${({ direction }) => direction || "inherit"};
  display: inline-flex;
`;

export const ParagraphPlus = styled(Paragraph)<{
  readonly textColor?: string;
}>`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  width: 100%;
  color: ${({ theme, textColor }) => (textColor ? theme.colors[textColor] : theme.colors.nero)};
  text-align: center;
`;

export const Form = styled.form``;

export const InputsHolder = styled.div`
  margin: 30px auto;
`;

export const OtpError = styled.p`
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  color: ${({ theme }) => theme.colors.red};
  margin: 16px auto;
`;

export const CenterOpt = styled.div`
  display: flex;
  justify-content: center;
`;

export const Timer = styled.span`
  color: ${({ theme }) => theme.colors.seagule};
`;

export const OtpHandlerContainer = styled.div`
  direction: ltr !important;
`;

export const OtpHandler = styled(OtpInput)`
  input {
    transition: all 0.3s ease-in-out;
    font-size: ${({ theme }) => theme.fontSizes.body16};
    color: ${({ theme }) => theme.colors.brandColorDimmed};
    background: ${({ theme }) => theme.colors.gray6};
    border: 1px solid ${({ theme }) => theme.colors.gray6};
    border-radius: 10px;
    width: 52px !important;
    height: 52px;
    margin-right: 10px;
    &:focus {
      background: ${({ theme }) => theme.colors.white} !important;
      border: 1px solid ${({ theme }) => theme.colors.seagule} !important;
    }
    @media (max-width: 450px) {
      width: 36px !important;
      height: 36px;
      margin-right: 7px;
    }
  }
`;
