import { ErrorType, StyledRodal } from "@Components/PopupsLayout/PopupsLayout.styled";
import { _DeleteLecture } from "@Services/Lecture";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CancleButton,
  DeleteButton,
  DeletePopupAlert,
  DeletePopupButtons,
  DeletePopupHeader,
  DeletePopupWrapper,
} from "../DeleteSubjectPopup/DeleteSubjectPopup.styled";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
};

const DeleteLecturePopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [displayedError, setDisplayedError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { lectureId, courseId, lectureType, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);

  useEffect(() => {
    if (!isOpen) {
      setDisplayedError("");
    }
  }, [isOpen]);

  const deleteLecturHandler = (id: number | null) => {
    setIsLoading(true);
    _DeleteLecture(id, lectureType, { course_id: courseId }, teacherId)
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
      })
      .catch((err) => setDisplayedError(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledRodal isDelete visible={isOpen} onClose={() => closePopup(false)}>
      <DeletePopupWrapper>
        <DeletePopupHeader>{tCommon("delete_lecture_title")}</DeletePopupHeader>
        <DeletePopupAlert hasError={displayedError !== ""}>{tCommon("delete_lecture_message")}</DeletePopupAlert>
        {displayedError && <ErrorType>{displayedError}</ErrorType>}
        <DeletePopupButtons>
          <CancleButton variant="lightseagule" onClick={() => closePopup(false)}>
            {tCommon("cancel")}
          </CancleButton>
          <DeleteButton variant="default" disabled={isLoading} onClick={() => deleteLecturHandler(lectureId)}>
            {isLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("delete")}
          </DeleteButton>
        </DeletePopupButtons>
      </DeletePopupWrapper>
    </StyledRodal>
  );
};

export default DeleteLecturePopup;
