import React from "react";

const File = () => {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.85352 16.8711H11.1504C11.9577 16.8711 12.5619 16.6654 12.9629 16.2539C13.3691 15.8477 13.5723 15.2383 13.5723 14.4258V7.33203C13.5723 6.99349 13.554 6.70963 13.5176 6.48047C13.4811 6.2513 13.4082 6.04297 13.2988 5.85547C13.1947 5.66276 13.0384 5.45963 12.8301 5.24609L8.52539 0.863281C8.32748 0.660156 8.13477 0.50651 7.94727 0.402344C7.75977 0.292969 7.55404 0.220052 7.33008 0.183594C7.11133 0.141927 6.85612 0.121094 6.56445 0.121094H2.85352C2.04622 0.121094 1.43945 0.326823 1.0332 0.738281C0.632161 1.14974 0.431641 1.76172 0.431641 2.57422V14.4258C0.431641 15.2435 0.632161 15.8555 1.0332 16.2617C1.43945 16.668 2.04622 16.8711 2.85352 16.8711ZM2.9082 15.6133C2.50716 15.6133 2.20247 15.5091 1.99414 15.3008C1.79102 15.0924 1.68945 14.793 1.68945 14.4023V2.59766C1.68945 2.21224 1.79102 1.91276 1.99414 1.69922C2.20247 1.48568 2.50977 1.37891 2.91602 1.37891H6.39258V5.91797C6.39258 6.40755 6.51497 6.77474 6.75977 7.01953C7.00456 7.26432 7.37175 7.38672 7.86133 7.38672H12.3145V14.4023C12.3145 14.793 12.2103 15.0924 12.002 15.3008C11.7988 15.5091 11.4941 15.6133 11.0879 15.6133H2.9082ZM8.00195 6.20703C7.8457 6.20703 7.73372 6.17318 7.66602 6.10547C7.60352 6.03776 7.57227 5.92578 7.57227 5.76953V1.62109L12.0723 6.20703H8.00195Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};

export default File;
