import { LOCALE_COOKIE_NAME } from "@Configs/constants";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ns, resources } from "locales";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns,
    resources,
    supportedLngs: ["en", "ar", "tr"],
    fallbackLng: "en",
    load: "currentOnly",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ["cookies", "localStorage", "sessionStorage", "navigator"],
      lookupCookie: LOCALE_COOKIE_NAME,
      lookupLocalStorage: LOCALE_COOKIE_NAME,
      lookupSessionStorage: LOCALE_COOKIE_NAME,
      caches: ["cookie", "localStorage"],
    },
  });

export default i18n;
