import { css } from "styled-components";

export default css`
  /* -- container -- */
  .rodal,
  .rodal-mask {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    z-index: 200;
  }

  .rodal {
    position: absolute;
  }

  /* -- mask -- */
  .rodal-mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
  }

  /* -- dialog -- */
  .rodal-dialog {
    position: fixed !important;
    top: 0;
    left: 15%;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 101;
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    height: auto !important;
    max-height: 95%;
    max-width: 95%;
    overflow: hidden;
    z-index: 201;
  }

  /* .rodal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
  } */
  .rodal-dialog:focus {
    outline: none;
  }

  /* -- close button -- */
  .rodal-close {
    position: absolute;
    cursor: pointer;
    top: 25px;
    right: 30px;
    width: 16px;
    height: 16px;
  }

  .rodal-close:before,
  .rodal-close:after {
    position: absolute;
    content: "";
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }

  .rodal-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .rodal-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .rodal-close:hover:before,
  .rodal-close:hover:after {
    background: #333;
  }

  /* -- fade -- */
  @-webkit-keyframes rodal-fade-enter {
    from {
      opacity: 0;
    }
  }

  @keyframes rodal-fade-enter {
    from {
      opacity: 0;
    }
  }

  .rodal-fade-enter {
    -webkit-animation: rodal-fade-enter both ease-in;
    animation: rodal-fade-enter both ease-in;
  }

  @-webkit-keyframes rodal-fade-leave {
    to {
      opacity: 0;
    }
  }

  @keyframes rodal-fade-leave {
    to {
      opacity: 0;
    }
  }

  .rodal-fade-leave {
    -webkit-animation: rodal-fade-leave both ease-out;
    animation: rodal-fade-leave both ease-out;
  }

  /* -- zoom -- */
  @-webkit-keyframes rodal-zoom-enter {
    from {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }

  @keyframes rodal-zoom-enter {
    from {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }

  .rodal-zoom-enter {
    -webkit-animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-zoom-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-zoom-leave {
    to {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }

  @keyframes rodal-zoom-leave {
    to {
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }

  .rodal-zoom-leave {
    -webkit-animation: rodal-zoom-leave both;
    animation: rodal-zoom-leave both;
  }

  /* -- slideDown -- */
  @-webkit-keyframes rodal-slideDown-enter {
    from {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
    }
  }

  @keyframes rodal-slideDown-enter {
    from {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
    }
  }

  .rodal-slideDown-enter {
    -webkit-animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-slideDown-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-slideDown-leave {
    to {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
    }
  }

  @keyframes rodal-slideDown-leave {
    to {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
    }
  }

  .rodal-slideDown-leave {
    -webkit-animation: rodal-slideDown-leave both;
    animation: rodal-slideDown-leave both;
  }

  /* -- slideLeft -- */
  @-webkit-keyframes rodal-slideLeft-enter {
    from {
      -webkit-transform: translate3d(-150px, 0, 0);
      transform: translate3d(-150px, 0, 0);
    }
  }

  @keyframes rodal-slideLeft-enter {
    from {
      -webkit-transform: translate3d(-150px, 0, 0);
      transform: translate3d(-150px, 0, 0);
    }
  }

  .rodal-slideLeft-enter {
    -webkit-animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-slideLeft-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-slideLeft-leave {
    to {
      -webkit-transform: translate3d(-150px, 0, 0);
      transform: translate3d(-150px, 0, 0);
    }
  }

  @keyframes rodal-slideLeft-leave {
    to {
      -webkit-transform: translate3d(-150px, 0, 0);
      transform: translate3d(-150px, 0, 0);
    }
  }

  .rodal-slideLeft-leave {
    -webkit-animation: rodal-slideLeft-leave both;
    animation: rodal-slideLeft-leave both;
  }

  /* -- slideRight -- */
  @-webkit-keyframes rodal-slideRight-enter {
    from {
      -webkit-transform: translate3d(150px, 0, 0);
      transform: translate3d(150px, 0, 0);
    }
  }

  @keyframes rodal-slideRight-enter {
    from {
      -webkit-transform: translate3d(150px, 0, 0);
      transform: translate3d(150px, 0, 0);
    }
  }

  .rodal-slideRight-enter {
    -webkit-animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-slideRight-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-slideRight-leave {
    to {
      -webkit-transform: translate3d(150px, 0, 0);
      transform: translate3d(150px, 0, 0);
    }
  }

  @keyframes rodal-slideRight-leave {
    to {
      -webkit-transform: translate3d(150px, 0, 0);
      transform: translate3d(150px, 0, 0);
    }
  }

  .rodal-slideRight-leave {
    -webkit-animation: rodal-slideRight-leave both;
    animation: rodal-slideRight-leave both;
  }

  /* -- slideUp -- */
  @-webkit-keyframes rodal-slideUp-enter {
    from {
      -webkit-transform: translate3d(0, 100px, 0);
      transform: translate3d(0, 100px, 0);
    }
  }

  @keyframes rodal-slideUp-enter {
    from {
      -webkit-transform: translate3d(0, 100px, 0);
      transform: translate3d(0, 100px, 0);
    }
  }

  .rodal-slideUp-enter {
    -webkit-animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-slideUp-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-slideUp-leave {
    to {
      -webkit-transform: translate3d(0, 100px, 0);
      transform: translate3d(0, 100px, 0);
    }
  }

  @keyframes rodal-slideUp-leave {
    to {
      -webkit-transform: translate3d(0, 100px, 0);
      transform: translate3d(0, 100px, 0);
    }
  }

  .rodal-slideUp-leave {
    -webkit-animation: rodal-slideUp-leave both;
    animation: rodal-slideUp-leave both;
  }

  /* -- flip -- */
  @-webkit-keyframes rodal-flip-enter {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    }
    70% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    }
    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  @keyframes rodal-flip-enter {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 60deg);
    }
    70% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    }
    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

  .rodal-flip-enter {
    -webkit-animation: rodal-flip-enter both ease-in;
    animation: rodal-flip-enter both ease-in;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
  }

  @-webkit-keyframes rodal-flip-leave {
    from {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    }
    to {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    }
  }

  @keyframes rodal-flip-leave {
    from {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
    30% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -15deg);
    }
    to {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 45deg);
    }
  }

  .rodal-flip-leave {
    -webkit-animation: rodal-flip-leave both;
    animation: rodal-flip-leave both;
    -webkit-backface-visibility: visible !important;
    backface-visibility: visible !important;
  }

  /* -- rotate -- */
  @-webkit-keyframes rodal-rotate-enter {
    from {
      -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
      transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    }
  }

  @keyframes rodal-rotate-enter {
    from {
      -webkit-transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
      transform: rotate3d(0, 0, 1, -180deg) scale3d(0.3, 0.3, 0.3);
    }
  }

  .rodal-rotate-enter {
    -webkit-animation: rodal-rotate-enter both;
    animation: rodal-rotate-enter both;
    -webkit-transform-origin: center;
    transform-origin: center;
  }

  @-webkit-keyframes rodal-rotate-leave {
    to {
      -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
      transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    }
  }

  @keyframes rodal-rotate-leave {
    to {
      -webkit-transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
      transform: rotate3d(0, 0, 1, 180deg) scale3d(0.3, 0.3, 0.3);
    }
  }

  .rodal-rotate-leave {
    -webkit-animation: rodal-rotate-leave both;
    animation: rodal-rotate-leave both;
    -webkit-transform-origin: center;
    transform-origin: center;
  }

  /* -- door -- */
  @-webkit-keyframes rodal-door-enter {
    from {
      -webkit-transform: scale3d(0, 1, 1);
      transform: scale3d(0, 1, 1);
    }
  }

  @keyframes rodal-door-enter {
    from {
      -webkit-transform: scale3d(0, 1, 1);
      transform: scale3d(0, 1, 1);
    }
  }

  .rodal-door-enter {
    -webkit-animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
    animation: rodal-door-enter both cubic-bezier(0.4, 0, 0, 1.5);
  }

  @-webkit-keyframes rodal-door-leave {
    60% {
      -webkit-transform: scale3d(0.01, 1, 1);
      transform: scale3d(0.01, 1, 1);
    }
    to {
      -webkit-transform: scale3d(0, 1, 0.1);
      transform: scale3d(0, 1, 0.1);
    }
  }

  @keyframes rodal-door-leave {
    60% {
      -webkit-transform: scale3d(0.01, 1, 1);
      transform: scale3d(0.01, 1, 1);
    }
    to {
      -webkit-transform: scale3d(0, 1, 0.1);
      transform: scale3d(0, 1, 0.1);
    }
  }

  .rodal-door-leave {
    -webkit-animation: rodal-door-leave both;
    animation: rodal-door-leave both;
  }
  .dropup,
  .dropend,
  .dropdown,
  .dropstart {
    position: relative;
  }

  .dropdown-toggle.btn {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    &::after {
      display: none;
    }
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
  }
  .dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: 0.125rem;
  }

  .dropdown-menu-start {
    --bs-position: start;
  }
  .dropdown-menu-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-end {
    --bs-position: end;
  }
  .dropdown-menu-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }

  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */;
    }

    .dropdown-menu-sm-end {
      --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */;
    }

    .dropdown-menu-md-end {
      --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */;
    }

    .dropdown-menu-lg-end {
      --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */;
    }

    .dropdown-menu-xl-end {
      --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */;
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto /* rtl:ignore */;
      left: 0 /* rtl:ignore */;
    }

    .dropdown-menu-xxl-end {
      --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0 /* rtl:ignore */;
      left: auto /* rtl:ignore */;
    }
  }
  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  .dropup .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }

  .dropend .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
  }
  .dropend .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-left: 0.125rem;
  }
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-toggle::after {
    vertical-align: 0;
  }

  .dropstart .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
  }
  .dropstart .dropdown-menu[data-bs-popper] {
    margin-top: 0;
    margin-right: 0.125rem;
  }
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropstart .dropdown-toggle::after {
    display: none;
  }
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
  }

  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd;
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
  }

  .dropdown-menu.show {
    display: contents;
    width: 100%;
  }

  .dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }

  .dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #212529;
  }

  .dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, 0.15);
  }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6;
  }
  .dropdown-menu-dark .dropdown-item:hover,
  .dropdown-menu-dark .dropdown-item:focus {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
  }
  .dropdown-menu-dark .dropdown-item.active,
  .dropdown-menu-dark .dropdown-item:active {
    color: #fff;
    background-color: #0d6efd;
  }
  .dropdown-menu-dark .dropdown-item.disabled,
  .dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd;
  }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15);
  }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6;
  }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd;
  }
`;
