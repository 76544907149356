import React from "react";

const UploadPdf = () => {
  return (
    <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5065 28.6234H19.5056C20.8743 28.6234 21.8986 28.2834 22.5786 27.6034C23.2673 26.9234 23.6117 25.9121 23.6117 24.5696V11.519C23.6117 10.1764 23.2673 9.16515 22.5786 8.48515C21.8986 7.80516 20.8743 7.46516 19.5056 7.46516H15.8571V9.57053H19.4663C20.1115 9.57053 20.6127 9.74488 20.9702 10.0936C21.3276 10.4423 21.5063 10.9567 21.5063 11.6367V24.4519C21.5063 25.1319 21.3276 25.6462 20.9702 25.995C20.6127 26.3437 20.1115 26.518 19.4663 26.518H4.53265C3.87881 26.518 3.37754 26.3437 3.02882 25.995C2.68011 25.6462 2.50575 25.1319 2.50575 24.4519V11.6367C2.50575 10.9567 2.68011 10.4423 3.02882 10.0936C3.37754 9.74488 3.87881 9.57053 4.53265 9.57053H8.15492V7.46516H4.5065C3.1378 7.46516 2.10909 7.80516 1.42038 8.48515C0.740387 9.15643 0.400391 10.1677 0.400391 11.519V24.5696C0.400391 25.9209 0.740387 26.9321 1.42038 27.6034C2.10909 28.2834 3.1378 28.6234 4.5065 28.6234ZM11.9995 20.0189C12.139 20.0189 12.2654 19.9971 12.3787 19.9535C12.5008 19.9012 12.6272 19.8096 12.7579 19.6789L17.1779 15.4028C17.3871 15.2023 17.4917 14.9756 17.4917 14.7228C17.4917 14.4525 17.4002 14.2346 17.2171 14.0689C17.0341 13.8946 16.8074 13.8074 16.5371 13.8074C16.2495 13.8074 16.0141 13.912 15.831 14.1212L13.8433 16.2266L12.9672 17.1551L13.0456 15.1935V1.41061C13.0456 1.13164 12.941 0.891902 12.7318 0.691391C12.5313 0.49088 12.2872 0.390625 11.9995 0.390625C11.7205 0.390625 11.4764 0.49088 11.2672 0.691391C11.0667 0.891902 10.9664 1.13164 10.9664 1.41061V15.1935L11.0449 17.1551L10.1557 16.2266L8.18108 14.1212C7.998 13.912 7.75826 13.8074 7.46185 13.8074C7.18288 13.8074 6.95622 13.8946 6.78186 14.0689C6.6075 14.2346 6.52032 14.4525 6.52032 14.7228C6.52032 14.9756 6.62058 15.2023 6.82109 15.4028L11.241 19.6789C11.3805 19.8096 11.5069 19.9012 11.6203 19.9535C11.7423 19.9971 11.8687 20.0189 11.9995 20.0189Z"
        fill="#ABABAB"
      />
    </svg>
  );
};

export default UploadPdf;
