import { _GetUserProfile } from "@Services/Common";
import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { UserInfoContextType } from "types/Teachers";
import Cookies from "js-cookie";
import { logout } from "utils/logout";
import { AUTHORIZED_ROLES, STUDENT } from "constants/index";

const initialState: UserInfoContextType = {
  userData: null,
  refetchUserData: () => null,
  teacherSidebar: false,
  setTeacherSidebar: () => null,
  subjectSidebar: false,
  setSubjectSidebar: () => null,
};

export const UserInfoContext = createContext<UserInfoContextType>(initialState);
export const userInfo = () => useContext(UserInfoContext);

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function UserInfoProvider({ children }: Props): ReactElement {
  const location = useLocation();
  const [teacherSidebar, setTeacherSidebar] = useState(false);
  const [subjectSidebar, setSubjectSidebar] = useState(false);
  const path = location?.pathname.split("/")[location?.pathname.split("/").length - 1];
  const {
    data: userData,
    refetch: refetchUserData,
    error: userNotAuthorized,
  } = useQuery("getUserRole", () => _GetUserProfile());

  useEffect(() => {
    if (!Cookies.get("BAIMS_TOKEN")) {
      window.location.href = `${process.env.REACT_APP_BAIMS_URL}/login?url_redirect=${window.location.host}&url=${window.location.host}&from=web`;
    }
  }, [Cookies.get("BAIMS_TOKEN")]);

  useEffect(() => {
    if (userNotAuthorized !== null) {
      logout();
    }
  }, [userNotAuthorized]);

  useEffect(() => {
    if (userData) {
      const hasAuthorizedRole = userData.roles?.some((role: string) => AUTHORIZED_ROLES.includes(role));
      if (!hasAuthorizedRole) {
        window.location.href = `${process.env.REACT_APP_BAIMS_URL}/home`;
      }
    }
  }, [userData]);

  useEffect(() => {
    if (location.pathname === "/") {
      setTeacherSidebar(false);
      setSubjectSidebar(false);
    } else if (path === "subjects") {
      setTeacherSidebar(true);
      setSubjectSidebar(false);
    } else if (path === "profile") {
      setTeacherSidebar(true);
      setSubjectSidebar(false);
    } else if (path === "overview") {
      setTeacherSidebar(true);
      setSubjectSidebar(true);
    } else if (path === "course") {
      setTeacherSidebar(true);
      setSubjectSidebar(true);
    }
  }, [location.pathname]);

  return (
    <UserInfoContext.Provider
      value={{
        userData,
        refetchUserData,
        teacherSidebar,
        setTeacherSidebar,
        subjectSidebar,
        setSubjectSidebar,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
}
