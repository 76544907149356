import {
  EditWrapper,
  FreePrice,
} from "@Components/SubjectCourses/CourseSections/components/Introduction/Introduction.styled";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Discount,
  FullSubject,
  NewPrice,
  OldPrice,
  OverviewPriceContainer,
  OverviewPriceDetails,
  OverviewPriceTitle,
  PriceContainer,
  PriceDetails,
  PriceEdit,
  PriceEditButton,
  PriceEditButtonContainer,
  PriceEditContainer,
  TotalPrice,
} from "./OverviewPrice.styled";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  openEditSubjectPrice: Dispatch<SetStateAction<boolean>>;
};

const OverviewPrice = ({ openEditSubjectPrice }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const { subjectStatistics } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();

  return (
    <OverviewPriceContainer>
      <OverviewPriceTitle>{tCommon("price")}</OverviewPriceTitle>
      <OverviewPriceDetails>
        <PriceDetails>
          <TotalPrice>{tCommon("total_cost")}</TotalPrice>
          {subjectStatistics?.discount === 100 ? (
            <EditWrapper>
              <FreePrice>{tCommon("free")}</FreePrice>
            </EditWrapper>
          ) : (
            <PriceContainer>
              {subjectStatistics?.discount !== 0 && (
                <NewPrice>
                  {subjectStatistics?.final_price} {subjectStatistics?.currency}
                </NewPrice>
              )}
              {subjectStatistics?.discount !== 0 ? (
                <OldPrice>
                  {subjectStatistics?.price} {subjectStatistics?.currency}
                </OldPrice>
              ) : (
                <NewPrice>
                  {subjectStatistics?.price} {subjectStatistics?.currency}
                </NewPrice>
              )}
            </PriceContainer>
          )}
        </PriceDetails>
        <PriceEdit>
          {subjectStatistics?.discount !== 0 && (
            <PriceEditContainer>
              <FullSubject>{tCommon("full_subject")}</FullSubject>
              <Discount>
                {tCommon("discount")} {subjectStatistics?.discount} %
              </Discount>
            </PriceEditContainer>
          )}
          <PriceEditButtonContainer
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                openEditSubjectPrice(true);
              }
            }}
          >
            <PriceEditButton>{tCommon("edit")}</PriceEditButton>
          </PriceEditButtonContainer>
        </PriceEdit>
      </OverviewPriceDetails>
    </OverviewPriceContainer>
  );
};

export default OverviewPrice;
