import { useTranslation } from "react-i18next";
import {
  Code,
  IntroIconBox,
  PhoneInputContainer,
  PhoneInputHandler,
  PhoneInputWrapper,
  PhonesInput,
  ValidationType,
} from "./PhoneInput.styled";
import { FieldErrors, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { RoundedArrowIcon } from "images/icons/RoundedArrowIcon";
import { Profile } from "types/Teachers";
import { Dispatch, SetStateAction, useEffect } from "react";

interface Props {
  isOpen: boolean;
  register: UseFormRegister<Profile>;
  setValue: UseFormSetValue<Profile>;
  errors: FieldErrors<Profile>;
  disabledPhoneInput: boolean;
  phoneValidationError: string;
  setSelectedCode: Dispatch<SetStateAction<string>>;
  selectedCode: string;
  countryShortCode: string | undefined;
}

const PhoneInput = ({
  isOpen,
  register,
  setValue,
  errors,
  disabledPhoneInput,
  phoneValidationError,
  setSelectedCode,
  selectedCode,
  countryShortCode,
}: Props) => {
  const { t: tCommon } = useTranslation("common");

  useEffect(() => {
    if (selectedCode) {
      setValue("phone", "");
    }
  }, [selectedCode]);

  return (
    <PhoneInputWrapper>
      <PhoneInputContainer isValidPhone={Boolean(errors.phone) || Boolean(phoneValidationError)}>
        <IntroIconBox isDisabled={disabledPhoneInput}>
          <RoundedArrowIcon />
          <PhoneInputHandler
            disabled={disabledPhoneInput}
            onChange={(value, data) => {
              setSelectedCode(data["dialCode"]);
            }}
            country={!isOpen ? countryShortCode?.toLocaleLowerCase() : undefined}
            excludeCountries={["il"]} // Removed 💩 Israel 💩 from countries list
          />
          <Code
            isDisabled={disabledPhoneInput}
            type="text"
            name="code"
            CountryCodeLength={selectedCode?.length}
            value={selectedCode && `+${selectedCode}`}
          />
        </IntroIconBox>
        <PhonesInput
          name="phone"
          type="number"
          isDisabled={disabledPhoneInput}
          disabled={disabledPhoneInput}
          {...register("phone", {
            required: tCommon("required_message"),
            minLength: 7,
            maxLength: 15,
          })}
        />
      </PhoneInputContainer>
      {errors.phone && <ValidationType>{tCommon("enter_phone_number")}</ValidationType>}
      {phoneValidationError && <ValidationType>{phoneValidationError}</ValidationType>}
    </PhoneInputWrapper>
  );
};

export default PhoneInput;
