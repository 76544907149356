//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import NoResultsMessage from "@Components/NoResultsMessage";
import SectionsSkeleton from "@Components/Skeletons/SectionsSkeleton";
import { _GetStatus } from "@Services/Common";
import { _SortingSectionsAndLectures } from "@Services/Sections";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import DNDD from "./components/DNDD";
import Introduction from "./components/Introduction";
import _ from "lodash";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  editSection: Dispatch<SetStateAction<boolean>>;
  editLecture: Dispatch<SetStateAction<boolean>>;
  editIntroduction: Dispatch<SetStateAction<boolean>>;
  addIntroduction: Dispatch<SetStateAction<boolean>>;
  setVideoReadyPopup: Dispatch<SetStateAction<boolean>>;
};

const CourseSections = ({ editSection, editLecture, editIntroduction, addIntroduction, setVideoReadyPopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const {
    courseDetails,
    newData,
    courseId,
    setNewData,
    courseDetailsIsLoading,
    teacherId,
    saveSectionSorting,
    setSectionSortSaveBtn,
    setSortedCollection,
    sortedCollection,
  } = useContext(TeachersDatasContext);
  const { setIsSectionsSorted, setWorningSortType } = useTablesFilters();
  const [sortedSections, setSortedSections] = useState<any>(courseDetails?.sections);
  const [showImage, setShowImage] = useState(false);
  const [vimeoIds, setVimeoIds] = useState([]);

  useEffect(() => {
    if (courseDetails) {
      setVimeoIds(
        courseDetails?.sections
          .map((section) => section.lectures.filter((lecture) => lecture.video?.duration === 0))
          .flat(1)
          .map((lec) => ({
            videoId: lec?.video?.vimeo_video_id,
            account: lec?.video?.vimeo_account,
          }))
      );
    }
  }, [courseDetails]);

  const { data } = useQuery(
    ["vimeoids", vimeoIds],
    () =>
      vimeoIds.map((lecture) =>
        _GetStatus(
          lecture.videoId,
          lecture.account === "turkey"
            ? `bearer ${process.env.REACT_APP_VIMEO_TURKEY_ACCESS_TOKEN}`
            : `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`
        ).then((value) => {
          if (value?.transcode?.status === "complete") {
            setTimeout(() => {
              setVideoReadyPopup(true);
            }, 3000);
          }
        })
      ),
    {
      enabled: vimeoIds?.length > 0,
      refetchInterval: 30000,
    }
  );

  useEffect(() => {
    if (courseDetails) {
      setSortedSections(courseDetails?.sections.filter((section) => section?.is_introductory === false));
    }
  }, [courseDetails]);

  useEffect(() => {
    if (sortedSections?.length === 0) {
      setTimeout(() => {
        setShowImage(true);
      }, 700);
    }
  }, [sortedSections]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderQuoteMap = ({ quoteMap, source, destination }) => {
    const current = [...quoteMap[source.droppableId]];
    const next = [...quoteMap[destination.droppableId]];
    const target = current[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
      const reordered = reorder(current, source.index, destination.index);
      const result = {
        ...quoteMap,
        [source.droppableId]: reordered,
      };
      return {
        quoteMap: result,
      };
    }

    // moving to different list
    // remove from original
    current.splice(source.index, 1);
    // insert into next
    next.splice(destination.index, 0, target);

    const result = {
      ...quoteMap,
      [source.droppableId]: current,
      [destination.droppableId]: next,
    };

    return {
      quoteMap: result,
    };
  };

  const onDragEnd = (result) => {
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...newData.ordered];
        shallow.splice(result.source.index, 1);
        setNewData({ ...newData, ordered: shallow });
        return;
      }

      const column = newData.columns[result.source.droppableId];
      const withQuoteRemoved = [...column];
      withQuoteRemoved.splice(result.source.index, 1);
      const columns = {
        ...newData.columns,
        [result.source.droppableId]: withQuoteRemoved,
      };
      setNewData({ columns: columns, ...newData });
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const ordered = reorder(newData.ordered, source.index, destination.index);

      setNewData({
        ...newData,
        ordered: ordered,
      });
      setSectionSortSaveBtn(true);
      setIsSectionsSorted(true);
      setWorningSortType("sections");
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: newData.columns,
      source,
      destination,
    });

    let updatedData = {
      columns: data.quoteMap,
      ordered: newData.ordered,
    };
    setNewData(updatedData);
    setSectionSortSaveBtn(true);
    setIsSectionsSorted(true);
    setWorningSortType("sections");
  };

  useEffect(() => {
    let updatedOrderedData = [];
    newData?.ordered?.map((item) => {
      updatedOrderedData.push(Number(item.slice(1, item.length)));
    });
    _.uniq(_.flatten(updatedOrderedData));
    var sortedCollectionVAR = _.sortBy(sortedSections, function (item) {
      return updatedOrderedData.indexOf(item.id);
    });

    updatedOrderedData.map((item, index) => {
      var selectedLecture = newData?.columns[item];
      var selectedObj = _.find(sortedCollectionVAR, { id: item });

      if (selectedObj) {
        selectedObj["lectures"] = selectedLecture;
        selectedObj["section_id"] = item;
        selectedObj["order_id"] = index;
      }

      selectedLecture.map((itemInner, indexInner) => {
        if (itemInner) {
          itemInner["order_id"] = indexInner;
          itemInner["lecture_id"] = itemInner.id;
        }
      });
    });

    setSortedCollection(sortedCollectionVAR);
  }, [sortedSections, newData]);

  return (
    <Fragment>
      {!courseDetailsIsLoading ? (
        <Fragment>
          {newData && (
            <Fragment>
              <Introduction editIntroduction={editIntroduction} addIntroduction={addIntroduction} />
              {sortedSections?.length > 0 ? (
                <DNDD editLecture={editLecture} editSection={editSection} newData={newData} onDragEnd={onDragEnd} />
              ) : (
                <Fragment>
                  {showImage && <NoResultsMessage isCoursePage message={tCommon("no_content_added")} />}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      ) : (
        <SectionsSkeleton />
      )}
    </Fragment>
  );
};

export default CourseSections;
