import PhoneInput from "react-phone-input-2";
import styled from "styled-components";

export const PhoneInputWrapper = styled.div``;

export const PhoneInputContainer = styled.div<{
  readonly isValidPhone?: boolean;
}>`
  display: flex;
  direction: ltr !important;
  align-items: center;
  padding: 15.5px 16px;
  border: 1px solid ${({ isValidPhone, theme }) => (isValidPhone ? theme.colors.red : theme.colors.gray6)};
  background: ${({ theme }) => theme.colors.gray6};
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  .react-tel-input {
    width: auto;
    .special-label,
    .form-control {
      display: none;
    }
    .flag-dropdown {
      left: 0 !important;
      .arrow {
        display: none;
      }
      .flag {
        left: 9px !important;
      }
    }
  }
`;

export const IntroIconBox = styled.div<{ readonly isDisabled?: boolean }>`
  display: inherit;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  .react-tel-input .flag-dropdown .flag {
    opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
  }
`;

export const FlagIcon = styled.img``;

export const PhoneInputHandler = styled(PhoneInput)``;

export const PhonesInput = styled.input<{ readonly isDisabled?: boolean }>`
  border: 0;
  padding-left: 12px;
  background: none;
  font-size: ${({ theme }) => theme.fontSizes.body16};
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray3 : theme.colors.brandColorDimmed)};
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  &:-internal-autofill-selected {
    background-color: transparent !important;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;
`;

export const Code = styled.input<{
  readonly isDisabled?: boolean;
  readonly CountryCodeLength?: number;
}>`
  padding: 3.5px 12px 3.5px 44px;
  border: 0;
  border-right: 1px solid ${({ theme }) => theme.colors.gray4};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray3 : theme.colors.brandColorDimmed)};
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  width: ${({ CountryCodeLength }) => (CountryCodeLength === 1 ? "80px" : CountryCodeLength === 2 ? "90px" : "100px")};
  pointer-events: none;
  background: transparent;
`;

export const ValidationType = styled.span<{ readonly isValid?: boolean | any }>`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ isValid, theme }) => (isValid ? theme.colors.gray3 : theme.colors.red)};
  margin-top: 8px;
  padding: 0px 16px;
`;
