import styled, { css } from "styled-components";

export const CourseDetailsContainer = styled.div``;

export const CourseLangWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 2px solid #f3f3f3;
  margin-bottom: 16px;
  margin-top: 5px;
`;

export const LangTabsWrappwer = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
  position: relative;
`;

export const LangTabsWrappwers = styled.div`
  position: relative;
`;

export const LangMenuArrow = styled.div<{
  readonly isOpen?: boolean;
  readonly isDisabled?: boolean;
}>`
  transition: all 0.3s ease;
  transform: ${({ isOpen, isDisabled }) => (!isDisabled && isOpen ? "rotateZ(-180deg)" : "rotateZ(0deg)")};
`;

export const TabContainer = styled.div<{ readonly isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 7px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  &::after {
    content: "";
    position: absolute;
    bottom: -22%;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray8 : theme.colors.seagule)};
  }
`;

export const TabsContainer = styled(TabContainer)<{ readonly isSelected?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  gap: 7px;
  &::after {
    content: "";
    position: absolute;
    bottom: -22%;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: ${({ isSelected, theme }) => (isSelected ? theme.colors.seagule : "transparent")};
  }
`;

export const LangName = styled.span<{ readonly isDisabled?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray8 : theme.colors.seagule)};
`;

export const MenuLangName = styled.label`
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  cursor: pointer;
`;

export const LangTabName = styled.span<{ readonly isSelected?: boolean }>`
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.seagule : theme.colors.gray3)};
`;

export const AddLangWrapper = styled.div<{ readonly isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

export const AddLangButton = styled.span<{ readonly isDisabled?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.gray8 : theme.colors.seagule)};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

export const AddLangMenu = styled.div<{
  readonly isOpen?: boolean;
  readonly isLeft?: boolean;
  readonly isDisabled?: boolean;
}>`
  position: absolute;
  ${({ isLeft }) =>
    !isLeft
      ? css`
          left: 0%;
        `
      : css`
          right: 0%;
        `}
  padding: 16px;
  width: 160px;
  transition: all 0.3s ease;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  gap: 20px;
  ${({ isOpen, isDisabled }) =>
    !isDisabled && isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          top: 122%;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          top: 142%;
        `}
`;

export const RadioBoxWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  cursor: pointer;
  input[type="radio"] {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    appearance: none;
    -webkit-appearance: none;
    min-height: 16px;
    max-height: 16px;
    min-width: 16px;
    max-width: 16px;
    transition: all 0.3s ease;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 50%;
    cursor: pointer;
  }
  input[type="radio"]:after {
    content: "";
    background-color: ${({ theme }) => theme.colors.seagule};
    min-height: 12px;
    max-height: 12px;
    min-width: 12px;
    max-width: 12px;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  input[type="radio"]:checked {
    border: 1px solid ${({ theme }) => theme.colors.seagule};
    &::after {
      opacity: 100;
    }
  }
  label {
    padding-left: 10px;
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    font-size: ${({ theme }) => theme.fontSizes.body16};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.nero};
    cursor: pointer;
  }
`;

export const FieldsWrapper = styled.div`
  flex: 1 1 100%;
  width: 100%;
`;
