import CourseContent from "@Components/SubjectCourses/CourseContent";
import CourseHeader from "@Components/SubjectCourses/CourseHeader";
import CourseNumbers from "@Components/SubjectCourses/CourseNumbers";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import { _GetCourseData, _GetCourseDetails } from "@Services/Courses";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import EditCoursePopup from "@Components/Popups/EditCoursesPopup";
import AddCoursePopup from "@Components/Popups/AddCoursePopup";
import EmptySectionAlertPopup from "@Components/Popups/EmptySectionAlertPopup";
import DeleteCoursePopup from "@Components/Popups/DeleteCoursePopup";
import AddLecturePopup from "@Components/Popups/AddLecturePopup";
import CourseSections from "@Components/SubjectCourses/CourseSections";
import EditSectionPopup from "@Components/Popups/EditSectionPopup";
import DeleteSectionPopup from "@Components/Popups/DeleteSectionPopup";
import EditLecturePopup from "@Components/Popups/EditLecturePopup";
import DeleteLecturePopup from "@Components/Popups/DeleteLecturePopup";
import EditIntroductionPopup from "@Components/Popups/EditIntroductionPopup";
import AddSectionPopup from "@Components/Popups/AddSectionPopup";
import { useTranslation } from "react-i18next";
import { _CreateSection } from "@Services/Sections";
import VimeoVideoReadyPopup from "@Components/Popups/VimeoVideoReadyPopup";
import AddIntroductionPopup from "@Components/Popups/AddIntroductionPopup";
import EditSubjectPricePopup from "@Components/Popups/EditSubjectPricePopup";
import SortingWorningPopup from "@Components/Popups/SortingWorningPopup";
import { useTablesFilters } from "context/TablesFilters.context";

const SubjectCourse = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const { courseid } = useParams();
  const {
    setCourseId,
    refetchCourseDetails,
    addCoursePopup,
    courseDetails,
    setAddCoursePopup,
    editSubjectPrice,
    setEditSubjectPrice,
    setSubjectId,
  } = useContext(TeachersDatasContext);
  const { worningSectionsSortPopup } = useTablesFilters();
  const [editCoursePopup, setEditCoursePopup] = useState(false);
  const [deleteCoursePopup, setDeleteCoursePopup] = useState(false);
  const [addSectionPopup, setAddSectionPopup] = useState(false);
  const [addLecturePopup, setAddLecturePopup] = useState(false);
  const [editLecturePopup, setEditLecturePopup] = useState(false);
  const [emptySectionPopup, setEmptySectionPopup] = useState(false);
  const [editSectionPopup, setEditSectionPopup] = useState(false);
  const [deleteSectionPopup, setDeleteSectionPopup] = useState(false);
  const [deleteLecturePopup, setDeleteLecturePopup] = useState(false);
  const [editIntroductionPopup, setEditIntroductionPopup] = useState(false);
  const [addIntroductionPopup, setAddIntroductionPopup] = useState(false);
  const [videoReadyPopup, setVideoReadyPopup] = useState(false);

  const { data: courseData, refetch: refetchCourseData } = useQuery(["courseData", courseid], () =>
    _GetCourseData(Number(courseid))
  );

  useEffect(() => {
    if (courseid) {
      setCourseId(Number(courseid));
      refetchCourseData();
      refetchCourseDetails();
    }
  }, [courseid, language]);

  useEffect(() => {
    if (courseDetails) {
      setSubjectId(courseDetails?.subject_id);
    }
  }, [courseDetails]);

  return (
    <PagesContainer>
      <CourseHeader openPopup={setEditCoursePopup} />
      <CourseNumbers />
      <CourseContent openSectionPopup={setAddSectionPopup} openLecturePopup={setAddLecturePopup} />
      <CourseSections
        editSection={setEditSectionPopup}
        editLecture={setEditLecturePopup}
        editIntroduction={setEditIntroductionPopup}
        addIntroduction={setAddIntroductionPopup}
        setVideoReadyPopup={setVideoReadyPopup}
      />
      <AddCoursePopup isOpen={addCoursePopup} closePopup={setAddCoursePopup} />
      <EditCoursePopup
        data={courseData}
        isOpen={editCoursePopup}
        closePopup={setEditCoursePopup}
        openDeletePopup={setDeleteCoursePopup}
        refetchData={refetchCourseData}
      />
      <DeleteCoursePopup isOpen={deleteCoursePopup} closePopup={setDeleteCoursePopup} courseid={courseid} />
      <AddSectionPopup isOpen={addSectionPopup} closePopup={setAddSectionPopup} openAlertPopup={setEmptySectionPopup} />
      <EditSectionPopup
        isOpen={editSectionPopup}
        closePopup={setEditSectionPopup}
        openDeletePopup={setDeleteSectionPopup}
      />
      <DeleteSectionPopup isOpen={deleteSectionPopup} closePopup={setDeleteSectionPopup} />
      <AddLecturePopup isOpen={addLecturePopup} closePopup={setAddLecturePopup} />
      <EditLecturePopup
        isOpen={editLecturePopup}
        closePopup={setEditLecturePopup}
        openDeletePopup={setDeleteLecturePopup}
      />
      <AddIntroductionPopup isOpen={addIntroductionPopup} closePopup={setAddIntroductionPopup} />
      <EditIntroductionPopup isOpen={editIntroductionPopup} closePopup={setEditIntroductionPopup} />
      <DeleteLecturePopup isOpen={deleteLecturePopup} closePopup={setDeleteLecturePopup} />
      <EmptySectionAlertPopup isOpen={emptySectionPopup} closePopup={setEmptySectionPopup} />
      <VimeoVideoReadyPopup isOpen={videoReadyPopup} closePopup={setVideoReadyPopup} />
      <EditSubjectPricePopup isOpen={editSubjectPrice} closePopup={setEditSubjectPrice} />
      <SortingWorningPopup isOpen={worningSectionsSortPopup} />
    </PagesContainer>
  );
};

export default SubjectCourse;
