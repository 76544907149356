import React, { Dispatch, Fragment, SetStateAction, useContext } from "react";
import { FilterDropdown, FilterName, FilterSelected, FilterWrapper, RemoveFilter } from "../Breadcrumbs.styled";
import { CloseIcon } from "images/icons/CloseIcon";
import { ListNumberArrowWrapper } from "@Components/TeachersList/TeachersPaginate/TeachersPaginate.styled";
import { RoundedArrowIcon } from "images/icons/RoundedArrowIcon";
import { useTranslation } from "react-i18next";
import { FILTER_TYPE_TEACHERS } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  children?: React.PropsWithChildren<any>;
  filterType: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const BreadcrumbButton = ({ children, filterType, isOpen, setIsOpen }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const {
    setTeacherBreadcrumbFilter,
    teacherBreadcrumbFilter,
    setTeacherCategoryIdFilter,
    subjectBreadcrumbFilter,
    setSubjectBreadcrumbFilter,
    setSubjectCategoryIdFilter,
  } = useTablesFilters();

  return (
    <FilterWrapper>
      {filterType === FILTER_TYPE_TEACHERS ? (
        <Fragment>
          {teacherBreadcrumbFilter && (
            <RemoveFilter
              onClick={() => {
                setTeacherBreadcrumbFilter("");
                setTeacherCategoryIdFilter("");
              }}
            >
              <FilterSelected>{tCommon("bread_crumb")}</FilterSelected>
              <CloseIcon />
            </RemoveFilter>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {subjectBreadcrumbFilter && (
            <RemoveFilter
              onClick={() => {
                setSubjectBreadcrumbFilter("");
                setSubjectCategoryIdFilter("");
              }}
            >
              <FilterSelected>{tCommon("bread_crumb")}</FilterSelected>
              <CloseIcon />
            </RemoveFilter>
          )}
        </Fragment>
      )}
      <FilterDropdown onClick={() => setIsOpen(!isOpen)} opened={isOpen}>
        {filterType === FILTER_TYPE_TEACHERS ? (
          <FilterName>{teacherBreadcrumbFilter ? teacherBreadcrumbFilter : `${tCommon("bread_crumb")}`}</FilterName>
        ) : (
          <FilterName>{subjectBreadcrumbFilter ? subjectBreadcrumbFilter : `${tCommon("bread_crumb")}`}</FilterName>
        )}
        <ListNumberArrowWrapper isOpen={isOpen}>
          <RoundedArrowIcon />
        </ListNumberArrowWrapper>
      </FilterDropdown>
      {children}
    </FilterWrapper>
  );
};

export default BreadcrumbButton;
