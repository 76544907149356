import { useEffect, Fragment } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
} from "@tanstack/react-table";
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";
import TeachersTableFilters from "@Components/TeachersList/TeachersFilters";
import TeachersPagination from "@Components/TeachersList/TeachersPaginate";
import TeachersListHeader from "@Components/TeachersList/TeachersHeader";
import { TeacherListContainer } from "@Components/TeachersList/TeachersHeader/TeachersHeader.styled";
import { useQuery } from "react-query";
import { _GetTeachersList } from "@Services/Teachers";
import TableSkeleton from "@Components/Skeletons/TableSkeleton";
import TableLoadingSkeleton from "@Components/Skeletons/TableLoadingSkeleton";
import { useNavigate } from "react-router-dom";
import TableLayout from "@Components/TableLayout";
import { useTablesFilters } from "context/TablesFilters.context";
import TeachersColumns from "@Components/TablesColumns/TeachersColumns";
import { useTranslation } from "react-i18next";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";
declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank,
  });
  return itemRank.passed;
};

const TeachersDashboard = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation("common");
  const { userData } = userInfo();
  const {
    teacherCategoryIdFilter,
    currenciesFilter,
    openedFilters,
    searchValueFilter,
    sortingTeachers,
    setSortingTeachers,
    pageSizeFilter,
    pageNumberFilter,
    subscriberFiltersValues,
  } = useTablesFilters();

  const isAuthorized = userData?.roles.includes(ADMIN);

  const { data: teachersList, isFetching } = useQuery(
    [
      "teacherslist",
      {
        teacherCategoryIdFilter,
        currenciesFilter,
        searchValueFilter,
        pageSizeFilter,
        pageNumberFilter,
        subscriberFiltersValues,
        sortingTeachers,
        language,
      },
    ],
    () =>
      _GetTeachersList(
        teacherCategoryIdFilter,
        currenciesFilter,
        searchValueFilter,
        pageSizeFilter,
        pageNumberFilter,
        subscriberFiltersValues,
        sortingTeachers
      ),
    {
      enabled: isAuthorized,
    }
  );

  const data = teachersList?.data;
  const columns = TeachersColumns();

  useEffect(() => {
    if (!isAuthorized && userData) {
      navigate(`/${userData.id}/profile`);
    }
  }, [isAuthorized, userData]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting: sortingTeachers,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSortingTeachers,
    sortDescFirst: true,
    enableSortingRemoval: true,
    enableMultiSort: false,
    maxMultiSortColCount: 6,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });

  return (
    <Fragment>
      {isAuthorized && (
        <TeacherListContainer>
          <TeachersListHeader />
          {data ? (
            <Fragment>
              {openedFilters && <TeachersTableFilters data={teachersList?.fields_for_filter} />}
              <TeachersPagination table={table} totalNumbers={teachersList?.total} />
              {!isFetching ? <TableLayout table={table} link="subjects" /> : <TableLoadingSkeleton />}
            </Fragment>
          ) : (
            <TableSkeleton />
          )}
        </TeacherListContainer>
      )}
    </Fragment>
  );
};

export default TeachersDashboard;
