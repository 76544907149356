import ContentLoader from "react-content-loader";

export default function SubjectHeaderSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="120px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="4" ry="4" width="10%" height="100px" />
      <rect x="11%" y="10" rx="4" ry="4" width="15%" height="12px" />
      <rect x="11%" y="40" rx="4" ry="4" width="10%" height="10px" />
      <rect x="11%" y="70" rx="4" ry="4" width="8%" height="10px" />
      <rect x={"89%"} y="0" rx="4" ry="4" width="10%" height="35px" />
    </ContentLoader>
  );
}
