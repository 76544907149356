import ContentLoader from "react-content-loader";

export default function TableSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="750px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="40" rx="8" ry="8" width="100%" height="40px" />
      <rect x="0" y="110" rx="8" ry="8" width="100%" height="600px" />
    </ContentLoader>
  );
}
