import {
  WorningPopupButton,
  WorningPopupContainer,
  WorningPopupMessage,
  WorningPopupTitle,
  WorningPopupWrapper,
} from "./SortingWorningPopup.styled";
import { useTranslation } from "react-i18next";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  isOpen: boolean;
};

const SortingWorningPopup = ({ isOpen }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const { setIsSectionsSorted, setWorningSectionsSortPopup, worningSortType, subjectTitleHeight } = useTablesFilters();

  return (
    <WorningPopupContainer
      opened={isOpen}
      onClick={() => {
        setIsSectionsSorted(false);
        setWorningSectionsSortPopup(false);
      }}
    >
      <WorningPopupWrapper opened={isOpen} position={worningSortType} subjectTitleHeight={subjectTitleHeight}>
        <WorningPopupTitle> {tCommon("save_cancel_title")}</WorningPopupTitle>
        <WorningPopupMessage>
          {worningSortType === "courses" ? tCommon("sorting_courses_message") : tCommon("sorting_sections_message")}
        </WorningPopupMessage>
        <WorningPopupButton
          variant="lightseagule"
          onClick={() => {
            setIsSectionsSorted(false);
            setWorningSectionsSortPopup(false);
          }}
        >
          {tCommon("ok")}
        </WorningPopupButton>
      </WorningPopupWrapper>
    </WorningPopupContainer>
  );
};

export default SortingWorningPopup;
