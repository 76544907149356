import ContentLoader from "react-content-loader";

export default function SubjectOverviewSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="750px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="20" rx="8" ry="8" width="6%" height="15px" />
      <rect x="0" y="60" rx="8" ry="8" width="32%" height="72px" />
      <rect x="34%" y="60" rx="8" ry="8" width="32%" height="72px" />
      <rect x="68%" y="60" rx="8" ry="8" width="32%" height="72px" />
      <rect x="0" y="150" rx="8" ry="8" width="32%" height="72px" />
      <rect x="34%" y="150" rx="8" ry="8" width="32%" height="72px" />
      <rect x="68%" y="150" rx="8" ry="8" width="32%" height="72px" />
      <rect x="0" y="260" rx="8" ry="8" width="6%" height="15px" />
      <rect x="0" y="300" rx="8" ry="8" width="100%" height="76px" />
    </ContentLoader>
  );
}
