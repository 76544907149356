import { _GetCurrencies, _GetCategories } from "@Services/Common";
import { RoundedArrowIcon } from "images/icons/RoundedArrowIcon";
import { CloseIcon } from "images/icons/CloseIcon";
import { ResetIcon } from "images/icons/ResetIcon";
import { Fragment, useState } from "react";
import { useQuery } from "react-query";
import {
  ApplyBtn,
  ApplyBtnText,
  ApplyResetFilterContainer,
  CurrencyShortName,
  CurrencysList,
  FilterDropdown,
  FilterMinMaxContainer,
  FilterName,
  FiltersContainer,
  FilterSelected,
  FilterWrapper,
  HiddenBg,
  RangeInputs,
  RemoveFilter,
  ResetAllButton,
  ResetBtn,
  StyledSlider,
  SubscriperRange,
} from "./TeachersFilters.styled";
import { useTranslation } from "react-i18next";
import { ListNumberArrowWrapper } from "../TeachersPaginate/TeachersPaginate.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { TeachersSubscribeFilters } from "types/Teachers";
import BreadcrumbButton from "@Components/Breadcrumbs/BreadcrumbButton";
import BreadcrumbsMenu from "@Components/Breadcrumbs/BreadcrumbMenu";
import { FILTER_TYPE_TEACHERS } from "constants/index";

type Props = {
  data: TeachersSubscribeFilters;
};

const TeachersFilters = ({ data }: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const {
    setTeacherCategoryIdFilter,
    teacherBreadcrumbFilter,
    currenciesFilter,
    setCurrenciesFilter,
    setTeacherBreadcrumbFilter,
    setPageNumberFilter,
    subscriberFiltersValues,
    setSubscriberFiltersValues,
  } = useTablesFilters();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [subscriperFilter, setSubscriperFilter] = useState(false);
  const [currencyFilter, setCurrencyFilter] = useState(false);
  const [applyDisable, setApplyDisable] = useState(true);
  const minRange =
    subscriberFiltersValues?.length !== 0 ? subscriberFiltersValues[0] : data?.subscribers_count_values.min;
  const maxRange =
    subscriberFiltersValues?.length !== 0 ? subscriberFiltersValues[1] : data?.subscribers_count_values.max;
  const [subscriperRange, setSubscriperRange] = useState<any[]>([minRange, maxRange]);

  const { data: currenciesList, isLoading: currenciesLoading } = useQuery(
    ["currencies", language],
    () => _GetCurrencies(),
    {
      refetchOnMount: false,
    }
  );

  const subscriperApplyHandler = (e, filterRange) => {
    e.preventDefault();
    setSubscriberFiltersValues([filterRange[0], filterRange[1]]);
    setPageNumberFilter(1);
    setSubscriperFilter(false);
  };

  const subscriperResetHandler = (e) => {
    e.preventDefault();
    setSubscriperRange([data?.subscribers_count_values.min, data?.subscribers_count_values.max]);
    setSubscriberFiltersValues([]);
    setPageNumberFilter(1);
    setSubscriperFilter(false);
  };

  return (
    <FiltersContainer>
      <BreadcrumbButton filterType={FILTER_TYPE_TEACHERS} isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen}>
        <BreadcrumbsMenu
          filterType={FILTER_TYPE_TEACHERS}
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      </BreadcrumbButton>
      <Fragment>
        {subscriperFilter && <HiddenBg onClick={() => setSubscriperFilter(false)} />}
        <FilterWrapper>
          {subscriberFiltersValues?.length !== 0 && (
            <RemoveFilter
              onClick={(e) => {
                subscriperResetHandler(e);
                setApplyDisable(true);
              }}
            >
              <FilterSelected>{tCommon("subscribers")}</FilterSelected>
              <CloseIcon />
            </RemoveFilter>
          )}
          <FilterDropdown
            onClick={() => {
              setIsDropdownOpen(false);
              setCurrencyFilter(false);
              setSubscriperFilter(!subscriperFilter);
            }}
            opened={subscriperFilter}
          >
            <FilterName>
              {subscriberFiltersValues?.length !== 0
                ? `${subscriberFiltersValues[0]} - ${subscriberFiltersValues[1]}`
                : `${tCommon("subscribers")}`}
            </FilterName>
            <ListNumberArrowWrapper isOpen={subscriperFilter}>
              <RoundedArrowIcon />
            </ListNumberArrowWrapper>
          </FilterDropdown>
          <SubscriperRange
            onSubmit={(e) => subscriperApplyHandler(e, subscriperRange)}
            subscriperFilter={subscriperFilter}
          >
            <FilterMinMaxContainer>
              <RangeInputs
                type="number"
                value={subscriperRange[0]}
                defaultValue={minRange}
                onChange={(e) => {
                  setSubscriperRange((prevArr) => {
                    const result = [...prevArr];
                    result[0] = Number(e.target.value);
                    return result;
                  });
                  setApplyDisable(false);
                }}
              />
              {tCommon("to")}
              <RangeInputs
                type="number"
                value={subscriperRange[1]}
                defaultValue={maxRange}
                onChange={(e) => {
                  setSubscriperRange((prevArr) => {
                    const result = [...prevArr];
                    result[1] = Number(e.target.value);
                    return result;
                  });
                  setApplyDisable(false);
                }}
              />
            </FilterMinMaxContainer>
            <StyledSlider
              min={minRange}
              max={maxRange}
              value={subscriperRange}
              onChange={(event: Event, newValue: any | any[]) => {
                setSubscriperRange(newValue as any[]);
                setApplyDisable(false);
              }}
            />
            <ApplyResetFilterContainer>
              <ResetBtn
                disabled={applyDisable}
                onClick={(e) => {
                  subscriperResetHandler(e);
                  setApplyDisable(true);
                }}
              >
                <ResetIcon disapleds={applyDisable} />
              </ResetBtn>
              <ApplyBtn disabled={applyDisable} type="submit">
                <ApplyBtnText>{tCommon("apply")}</ApplyBtnText>
              </ApplyBtn>
            </ApplyResetFilterContainer>
          </SubscriperRange>
        </FilterWrapper>
      </Fragment>
      <Fragment>
        {currencyFilter && <HiddenBg onClick={() => setCurrencyFilter(false)} />}
        <FilterWrapper>
          {currenciesFilter && (
            <RemoveFilter
              onClick={() => {
                setCurrenciesFilter("");
                setPageNumberFilter(1);
              }}
            >
              <FilterSelected>{tCommon("currency")}</FilterSelected>
              <CloseIcon />
            </RemoveFilter>
          )}
          <FilterDropdown
            onClick={() => {
              setIsDropdownOpen(false);
              setSubscriperFilter(false);
              setCurrencyFilter(!currencyFilter);
            }}
            opened={currencyFilter}
          >
            <FilterName>
              {currenciesFilter ? currenciesFilter?.toLocaleUpperCase() : `${tCommon("currency")}`}
            </FilterName>
            <ListNumberArrowWrapper isOpen={currencyFilter}>
              <RoundedArrowIcon />
            </ListNumberArrowWrapper>
          </FilterDropdown>
          {!currenciesLoading && (
            <CurrencysList currencyFilter={currencyFilter}>
              {currenciesList.map((cur: string) => (
                <CurrencyShortName
                  isSelected={cur.toLocaleUpperCase() === currenciesFilter}
                  onClick={() => {
                    setCurrenciesFilter(cur.toLocaleUpperCase());
                    setPageNumberFilter(1);
                    setCurrencyFilter(false);
                  }}
                  key={cur}
                >
                  {cur.toLocaleUpperCase()}
                </CurrencyShortName>
              ))}
            </CurrencysList>
          )}
        </FilterWrapper>
      </Fragment>
      {(subscriberFiltersValues?.length !== 0 || currenciesFilter || teacherBreadcrumbFilter) && (
        <ResetAllButton
          onClick={(e) => {
            setIsDropdownOpen(false);
            setSubscriperFilter(false);
            setCurrencyFilter(false);
            setApplyDisable(true);
            setCurrenciesFilter("");
            setTeacherBreadcrumbFilter("");
            setTeacherCategoryIdFilter("");
            subscriperResetHandler(e);
            setPageNumberFilter(1);
          }}
        >
          {tCommon("reset_all")}
        </ResetAllButton>
      )}
    </FiltersContainer>
  );
};

export default TeachersFilters;
