import styled from "styled-components";

export const Wrapper = styled.section`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const Title = styled.h1`
  font-size: 2.8rem;
  margin-bottom: 30px;
`;
export const GoBack = styled.a`
  color: #000;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.brandColor};
`;
