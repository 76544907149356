import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    typography: {
      fontFamily: string;
      fontFamilyAr: string;
      htmlBaseFontPx: number;
      baseLineHeight: number;
    };
    colors: {
      black: string;
      light_black: string;
      seagule: string;
      seagule_hover: string;
      seagule_active: string;
      seaguleDarker: string;
      seagullNeutrals: string;
      seagull10: string;
      brandColor: string;
      brandColorDimmed: string;
      mountainMeadows: string;
      casablanca: string;
      eastSide: string;
      cinderella: string;
      nero: string;
      white: string;
      light_white: string;
      off_white: string;
      textColor: string;
      green: string;
      gray1: string;
      gray2: string;
      gray_new: string;
      gray3: string;
      gray4: string;
      gray5: string;
      gray5_old: string;
      gray6: string;
      gray7: string;
      gray8: string;
      red: string;
      sunSet: string;
      lightRed: string;
      darkSeagule: string;
      mediumSeagule: string;
      purple: string;
      yellow: string;
    };
    fontSizes: {
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      h6: string;
      body18: string;
      body16: string;
      body14: string;
      body12: string;
    };
    buttons: {
      borderRadius: string;
      fontSize: string;
      padding: string;
    };
    fontWeights: {
      font300: number;
      font400: number;
      font500: number;
      font600: number;
      font700: number;
      font800: number;
    };
  }
}

const theme: DefaultTheme = {
  typography: {
    fontFamily: "AvenirNextWorld, sans-serif",
    fontFamilyAr: "AvenirNextWorld, sans-serif",
    htmlBaseFontPx: 10,
    baseLineHeight: 1.5,
  },
  colors: {
    black: "#000",
    light_black: "rgba(0, 0, 0, 0.3)",
    seagule: "#45AAF2",
    seagule_hover: "#43A5EB",
    seagule_active: "#409ee1",
    seaguleDarker: "#259BF0",
    seagullNeutrals: "#E3F2FD",
    seagull10: "#ECF7FE",
    brandColor: "#00CECB",
    brandColorDimmed: "#262626",
    mountainMeadows: "#13AF72",
    casablanca: "#F6C14E",
    eastSide: "#AE8CCC",
    cinderella: "#FBD5D2",
    nero: "#1A1A1A",
    white: "#fff",
    light_white: "rgba(255, 255, 255, 0.2)",
    off_white: "rgba(255, 255, 255, 0.7)",
    textColor: "#262626",
    green: "#13AF72",
    gray1: "#454545",
    gray2: "#8E8E8E",
    gray_new: "#898b8e",
    gray3: "#ABABAB",
    gray4: "#D4D4D4",
    gray5: "#E1E1E1",
    gray5_old: "#EFEFEF",
    gray6: "#F5F5F5",
    gray7: "#FAFAFA",
    gray8: "#C4C4C4",
    red: "#F25151",
    sunSet: "#FFFCFC",
    lightRed: "#feeeee",
    darkSeagule: "#023447",
    mediumSeagule: "#37657E",
    purple: "#6674FF",
    yellow: "#FEC76C",
  },
  fontSizes: {
    h1: "68px",
    h2: "48px",
    h3: "32px",
    h4: "28px",
    h5: "24px",
    h6: "20px",
    body18: "18px",
    body16: "16px",
    body14: "14px",
    body12: "12px",
  },
  buttons: {
    borderRadius: "10px",
    fontSize: "16px",
    padding: "18px 45px",
  },
  fontWeights: {
    font300: 300,
    font400: 400,
    font500: 500,
    font600: 600,
    font700: 700,
    font800: 800,
  },
};

export default theme;
