import styled from "styled-components";

export const MenuSidebar = styled.div<{
  readonly isSubjectSidebarOpen?: boolean;
  readonly isOpen?: boolean;
}>`
  position: relative;
  margin-left: ${({ isOpen }) => (isOpen ? "0px" : "-294px")};
  transition: all 0.3s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ isSubjectSidebarOpen }) => (isSubjectSidebarOpen ? "76px" : "294px")};
  min-width: ${({ isSubjectSidebarOpen }) => (isSubjectSidebarOpen ? "76px" : "294px")};
  background: linear-gradient(191.41deg, #0095ff 0%, #126b8d 52.08%, #093c50 96.35%);
  z-index: 20;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.7);
    backdrop-filter: blur(22.5px);
    border-right: 1px solid ${({ theme }) => theme.colors.light_white};
  }
`;

export const MenuSidebarContent = styled.div<{ readonly isSubjectSidebarOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding: ${({ isSubjectSidebarOpen }) => (isSubjectSidebarOpen ? "28.6px 16.6px" : "24px 16px")};
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const ProfileTitleWrapper = styled.div<{ readonly isSubjectSidebarOpen?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding-bottom: ${({ isSubjectSidebarOpen }) => (isSubjectSidebarOpen ? "30px" : "28px")};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light_white};
`;

export const InstructorData = styled.div`
  max-width: 180px;
  color: ${({ theme }) => theme.colors.white};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
`;

export const ProfileName = styled.h6`
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
`;
export const ProfileEmail = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray5_old};
`;

export const MenuSidebarButons = styled.div<{ readonly isAdmin?: boolean }>`
  display: flex;
  flex-direction: ${({ isAdmin }) => (isAdmin ? "column" : "column-reverse")};
  align-items: flex-start;
  gap: 12px;
  padding: 20px 0px;
  width: 100%;
  visibility: 0;
  .is_active {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.14) 100%);
  }
  .side_button {
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    visibility: 1;
  }
  .not_active {
    color: rgba(255, 255, 255, 0.7);
    visibility: 1;
    width: 100%;
    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const SideBtnText = styled.span``;

export const ButtonWrapper = styled.div<{ readonly openSubjectSidebar?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ openSubjectSidebar }) => (openSubjectSidebar ? "center" : "flex-start")};
  gap: 13.43px;
  padding: ${({ openSubjectSidebar }) => (openSubjectSidebar ? "0px" : "12px 0px 12px 12px")};
  width: ${({ openSubjectSidebar }) => (openSubjectSidebar ? "48px" : "100%")};
  height: 48px;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
  transition: all 0.2s ease-in;
  border-radius: 8px;
  cursor: pointer;
`;

export const BtnText = styled.div`
  padding: 20px 0px;
`;

export const InstructorDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LangsMenusWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;
  cursor: pointer;
`;

export const InstructorImg = styled.img`
  width: 44px;
  height: 44px;
  background: ${({ theme }) => theme.colors.light_white};
  object-fit: cover;
  border-radius: 50%;
`;

export const InstructorImgPlaceholder = styled.div`
  width: 44px;
  height: 44px;
  background: ${({ theme }) => theme.colors.light_white};
  border-radius: 50%;
`;

export const WhiteArrowWrapper = styled.div`
  padding-right: 15px;
`;

export const SidebarSkeletonWrapper = styled.div<{ readonly direction?: boolean }>`
  transform: ${({ direction }) => (direction ? "scale(-1, 1)" : "scale(1, 1)")};
`;
