type Props = {
  width?: string;
  height?: string;
};

export const CloseIcon = ({ width, height }: Props) => {
  return (
    <svg
      width={width ? width : "10"}
      height={height ? height : "10"}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.0111 8.99308C1.0736 9.05211 1.14479 9.09204 1.22465 9.11287C1.30451 9.13371 1.38437 9.13371 1.46423 9.11287C1.54409 9.09204 1.61354 9.05211 1.67256 8.99308L5.0059 5.65975L8.33923 8.99308C8.39826 9.05211 8.4677 9.09204 8.54756 9.11287C8.62742 9.13371 8.70729 9.13371 8.78715 9.11287C8.87048 9.09551 8.94166 9.05558 9.00069 8.99308C9.05972 8.93405 9.09791 8.86461 9.11527 8.78475C9.13611 8.70489 9.13611 8.62503 9.11527 8.54517C9.09791 8.4653 9.05972 8.39586 9.00069 8.33683L5.66736 4.99829L9.00069 1.66496C9.05972 1.60593 9.09965 1.53649 9.12048 1.45662C9.14131 1.37676 9.14131 1.2969 9.12048 1.21704C9.09965 1.13718 9.05972 1.06774 9.00069 1.00871C8.93819 0.946208 8.86701 0.906277 8.78715 0.888916C8.70729 0.868083 8.62742 0.868083 8.54756 0.888916C8.4677 0.906277 8.39826 0.946208 8.33923 1.00871L5.0059 4.34204L1.67256 1.00871C1.61354 0.946208 1.54235 0.906277 1.45902 0.888916C1.37916 0.868083 1.2993 0.868083 1.21944 0.888916C1.13958 0.906277 1.07013 0.946208 1.0111 1.00871C0.952077 1.06774 0.912146 1.13718 0.891313 1.21704C0.873952 1.2969 0.873952 1.37676 0.891313 1.45662C0.912146 1.53649 0.952077 1.60593 1.0111 1.66496L4.34444 4.99829L1.0111 8.33683C0.952077 8.39586 0.912146 8.4653 0.891313 8.54517C0.87048 8.62503 0.868744 8.70489 0.886105 8.78475C0.906938 8.86461 0.948605 8.93405 1.0111 8.99308Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
