import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import PopupsLayout from "@Components/PopupsLayout";
import { ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadIcon from "images/icons/UploadIcon";
import { Fragment } from "react";
import { useQuery } from "react-query";
import { _GetCourseSections } from "@Services/Courses";
import { TeachersDatasContext } from "context/Teachers.context";
import * as tus from "tus-js-client";
import FileIcon from "images/icons/FileIcon";
import { BoldCloseIcon } from "images/icons/BoldCloseIcon";
import {
  UploadFileInput,
  DashboardContainer,
  DeleteFile,
  CenterDiv,
  FileInputWrapper,
  FileNameWrapper,
  MessageContainer,
  VideoProgressMessage,
  VideoProgressContainer,
  Place,
  StyledImage,
  StyledProgress,
  StyledPTag,
  StyledText,
  UploadedMessage,
  UploadedTitle,
  UploadTab,
  UploadTabsWrapper,
  UploadVideoContainer,
  UploadVideoInput,
} from "./AddLecturePopup.styled";
import { _DeleteUploadFile, _GetUploadUrl, _PutPdfFile } from "@Services/Common";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import WaitToPlayIcon from "images/icons/WaitToPlayIcon";
import { _CreateLecture } from "@Services/Lecture";
import UploadPdf from "images/icons/UploadPdf";
import { FILE, VIDEO, docFileTypes, videoFileTypes } from "constants/Types";
import { useTranslation } from "react-i18next";
import CheckIcon from "images/icons/CheckIcon";
import { Lecture } from "types/Lecture";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const AddLecturePopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { courseId, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);
  const { data: getSections } = useQuery(["getsections", isOpen], () => _GetCourseSections(courseId), {
    enabled: isOpen,
  });
  // SELECTED TAB & UPLOAD TYPE
  const [selectedLectureType, setSelectedLectureType] = useState<any>("");
  const [selectedTab, setSelectedTab] = useState(1);
  // VIDEO STATES
  const [videoFile, setVideoFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [vemioVideoId, setVimeoVideoId] = useState("");
  const [urlImage, setUrlImage] = useState<any>();
  const [persantage, setPersantage] = useState("0%");
  const [videoUploadError, setVideoUploadError] = useState("");
  // FILE STATES
  const [docFile, setDocFile] = useState(null);
  const [docFileName, setDocFileName] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileExe, setFileExe] = useState("");
  const [s3FileName, setS3FileName] = useState("");
  const [s3FileUrl, setS3FileUrl] = useState("");
  const [s3FileNameToDelete, setS3FileNameToDelete] = useState("");
  const [uploadFileError, setUploadFileError] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<Lecture>();

  // FILE UPLOAD HANDLER
  const fileUploadhandler = async (eventObject) => {
    setDocFile(eventObject);
  };

  useEffect(() => {
    if (docFile) {
      const lastDotIndex = docFile?.name.lastIndexOf(".");
      setDocFileName(docFile?.name.substring(0, lastDotIndex));
      if (docFileName) {
        setFileName(docFile?.name.substring(0, lastDotIndex));
        setFileExe(docFile?.name.substring(lastDotIndex + 1));
      }
    }
  }, [docFile, docFileName]);

  useEffect(() => {
    if (fileName && fileExe) {
      _GetUploadUrl({ folder_path: process.env.REACT_APP_BUCKET_NAME, key: fileName, content_type: fileExe }).then(
        (res) =>
          _PutPdfFile(res.signed_url, docFile)
            .then((res) => {
              setS3FileName(res?.config.data.name);
              setS3FileUrl(res?.config.url);
              setS3FileNameToDelete(res?.config.url.split("/")[4].split("?")[0].split("%20").join(" "));
            })
            .catch((err) => setUploadFileError(err.message))
      );
    }
  }, [fileName, fileExe]);

  const deleteFile = () => {
    _DeleteUploadFile({
      data: {
        folder_path: process.env.REACT_APP_BUCKET_NAME,
        key: s3FileNameToDelete,
      },
    });
    setDocFile(null);
    setDocFileName([]);
    setFileName("");
    setFileExe("");
    setUploadFileError("");
  };

  // VIDEO UPLOAD HANDLER
  const generateVideoThumbnail = (file: File) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () => {
        const ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const videoUploadhandler = async (eventObject) => {
    const file = eventObject;
    setVideoFile(file);
    const fileSize = file.size.toString();
    const response = await axios({
      method: "post",
      url: `https://api.vimeo.com/me/videos`,
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
      data: {
        upload: {
          approach: "tus",
          size: fileSize,
        },
      },
    });
    setVideoLink(response?.data.link.split("/"));
    const upload = new tus.Upload(file, {
      endpoint: "https://api.vimeo.com/me/videos",
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {},
      onError: function (error) {
        setVideoUploadError("Failed because: " + error);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        setPersantage(percentage + "%");
      },
      onSuccess: function () {
        setVideoUploadError("");
      },
    });
    upload.start();
    generateVideoThumbnail(file).then((res) => setUrlImage(res));
  };

  useEffect(() => {
    if (videoLink && selectedLectureType === VIDEO) {
      setVimeoVideoId(videoLink[videoLink.length - 1]);
    }
  }, [videoLink]);

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
      setSelectedLectureType("");
      setSelectedTab(1);
      // reset uploaded video data
      setVideoLink("");
      setVideoFile(null);
      setVimeoVideoId("");
      setUrlImage("");
      setPersantage("0%");
      setVideoUploadError("");
      // reset uploaded file data
      setDocFile(null);
      setDocFileName([]);
      setUploadFileError("");
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedTab === 1) {
      setValue("vimeo_account", "global");
    }
  }, [selectedTab]);

  const submitForm = (data: Lecture) => {
    const { vimeo_account, ...remainingData } = data;
    setIsLoading(true);
    _CreateLecture(
      selectedLectureType === VIDEO
        ? {
            ...data,
            video_id: Number(selectedTab === 1 ? vemioVideoId : watch("video_id")),
            course_id: courseId,
          }
        : {
            ...remainingData,
            course_id: courseId,
            file_name: s3FileName,
            file_url: s3FileUrl,
          },
      teacherId,
      selectedLectureType
    )
      .then(() => {
        closePopup(false);
        setIsLoading(false);
        refetchCourseDetails();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_lecture")}
          buttonTitle={tCommon("add_lecture")}
          buttonState={!isDirty || uploadFileError !== "" || videoUploadError !== ""}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                <Select
                  id="demo-simple-select"
                  IconComponent={ExpandMoreIcon}
                  value={selectedLectureType}
                  displayEmpty
                  MenuProps={MenuProps}
                  onChange={(e) => setSelectedLectureType(e.target.value)}
                >
                  <MenuItem value="" style={{ visibility: "hidden" }} />
                  <MenuItem value={FILE}>{tCommon("Note_pdf_word")}</MenuItem>
                  <MenuItem value={VIDEO}>{tCommon("video")}</MenuItem>
                </Select>
              </FormControl>
              {selectedLectureType === VIDEO && (
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                  <Controller
                    name="vimeo_account"
                    control={control}
                    defaultValue={"global"}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="demo-simple-select"
                        IconComponent={ExpandMoreIcon}
                        MenuProps={MenuProps}
                        disabled={Boolean(selectedTab === 1)}
                        {...register("vimeo_account")}
                      >
                        <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                        <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              )}
            </InputsWrapper>
            {selectedLectureType === FILE && (
              <DashboardContainer>
                <div className="container">
                  {docFile === null ? (
                    <UploadFileInput>
                      <CenterDiv>
                        <StyledPTag>{tCommon("drop_here")}</StyledPTag>
                        <UploadPdf />
                      </CenterDiv>
                      <FileUploader handleChange={fileUploadhandler} name="file" types={docFileTypes} />
                    </UploadFileInput>
                  ) : (
                    <Fragment>
                      <UploadFileInput>
                        <FileInputWrapper>
                          <FileNameWrapper>
                            <FileIcon />
                            <StyledPTag textEllipsis noMargin>
                              {docFile?.name}
                            </StyledPTag>
                          </FileNameWrapper>
                          <DeleteFile type="button" onClick={() => deleteFile()}>
                            <BoldCloseIcon />
                          </DeleteFile>
                        </FileInputWrapper>
                      </UploadFileInput>
                      {uploadFileError && <ErrorType center>{uploadFileError}</ErrorType>}
                    </Fragment>
                  )}
                </div>
              </DashboardContainer>
            )}
            {selectedLectureType === VIDEO && (
              <UploadVideoContainer>
                <UploadTabsWrapper>
                  <UploadTab onClick={() => setSelectedTab(1)} isActiveTab={selectedTab === 1}>
                    {tCommon("direct_upload")}
                  </UploadTab>
                  <UploadTab onClick={() => setSelectedTab(2)} isActiveTab={selectedTab === 2}>
                    {tCommon("vimeo_id")}
                  </UploadTab>
                </UploadTabsWrapper>
                {selectedTab === 1 && (
                  <DashboardContainer isVideo>
                    <div className="container">
                      {videoFile === null ? (
                        <UploadVideoInput>
                          <CenterDiv>
                            <UploadIcon />
                            <StyledPTag>{tCommon("drag_and_drop")}</StyledPTag>
                            <StyledPTag>{tCommon("video_types")}</StyledPTag>
                          </CenterDiv>
                          <FileUploader handleChange={videoUploadhandler} name="file" types={videoFileTypes} />
                        </UploadVideoInput>
                      ) : (
                        <Fragment>
                          {persantage !== "100%" ? (
                            <VideoProgressContainer>
                              {urlImage ? <StyledImage src={urlImage} alt="" /> : <Place />}
                              <VideoProgressMessage>
                                <StyledText>{tCommon("uploading_video")}</StyledText>
                                <StyledProgress persantage={persantage}>
                                  <span />
                                </StyledProgress>
                                <StyledText>{persantage}</StyledText>
                                <MessageContainer>
                                  <WaitToPlayIcon />
                                  <StyledText>{tCommon("video_upload_message")}</StyledText>
                                </MessageContainer>
                              </VideoProgressMessage>
                            </VideoProgressContainer>
                          ) : (
                            <VideoProgressContainer>
                              {urlImage ? <StyledImage src={urlImage} alt="" /> : <Place />}
                              <VideoProgressMessage>
                                <CheckIcon />
                                <UploadedTitle>{tCommon("video_uploaded_title")}</UploadedTitle>
                                <UploadedMessage>{tCommon("video_uploaded_message")}</UploadedMessage>
                              </VideoProgressMessage>
                            </VideoProgressContainer>
                          )}
                        </Fragment>
                      )}
                      {videoUploadError && <ErrorType center>{videoUploadError}</ErrorType>}
                    </div>
                  </DashboardContainer>
                )}
                {selectedTab === 2 && (
                  <DashboardContainer isVideo>
                    <Input id="filled-basic" label="Vimeo Video ID" variant="filled" {...register("video_id")} />
                  </DashboardContainer>
                )}
              </UploadVideoContainer>
            )}
            {selectedLectureType && (
              <Fragment>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    MenuProps={MenuProps}
                    onChange={(e) => setSelectedLectureType(e.target.value)}
                    {...register("section_id")}
                  >
                    {getSections?.map((sec, index) => (
                      <MenuItem key={index} value={sec?.id}>
                        {sec?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <MultibleLanguagesInputs islecture isOpen={isOpen} errors={errors} register={register} />
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddLecturePopup;
