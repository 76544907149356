import styled from "styled-components";

export const CourseContentWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

export const CourseContentTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const AddingActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const AddButtonText = styled.button<{ readonly disabled?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.seagule};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.gray2 : theme.colors.seagule)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
