export const EditIcon = ({ color }: { color?: boolean }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.71811 17.5H14.6338C15.3173 17.5 15.8464 17.3025 16.2211 16.9076C16.6008 16.5178 16.7906 15.9305 16.7906 15.1458V7.02743L15.5679 8.25012V15.085C15.5679 15.4799 15.4743 15.7786 15.287 15.9811C15.0996 16.1786 14.8769 16.2773 14.6187 16.2773H5.7409C5.36118 16.2773 5.06753 16.1786 4.85995 15.9811C4.65744 15.7786 4.55618 15.4799 4.55618 15.085V6.46545C4.55618 6.07055 4.65744 5.77184 4.85995 5.56932C5.06753 5.36681 5.36118 5.26555 5.7409 5.26555H12.6593L13.882 4.04286H5.71811C4.9283 4.04286 4.33342 4.24031 3.93345 4.63522C3.53348 5.02506 3.3335 5.61236 3.3335 6.3971V15.1458C3.3335 15.9305 3.53348 16.5178 3.93345 16.9076C4.33342 17.3025 4.9283 17.5 5.71811 17.5ZM8.48245 12.5789L9.96334 11.9334L17.0564 4.84786L16.016 3.82263L8.93051 10.9081L8.24702 12.3359C8.21665 12.4017 8.2293 12.4675 8.28499 12.5333C8.34575 12.5941 8.41157 12.6093 8.48245 12.5789ZM17.6184 4.29347L18.1652 3.7315C18.2918 3.5948 18.3551 3.44544 18.3551 3.28343C18.3601 3.11636 18.2968 2.96953 18.1652 2.84296L17.9905 2.6607C17.8741 2.54425 17.7323 2.49109 17.5653 2.50122C17.4032 2.51134 17.259 2.57716 17.1324 2.69867L16.578 3.24546L17.6184 4.29347Z"
        fill={color ? "#45AAF2" : "white"}
      />
    </svg>
  );
};
