const UploadIcon = () => {
  return (
    <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64538 22.068C3.85781 22.068 0.335938 18.4361 0.335938 13.942C0.335938 9.28285 4.11462 5.88938 9.03059 5.88938C10.8282 2.36751 14.2217 0.111328 18.3122 0.111328C23.7784 0.111328 28.2908 4.34858 28.7677 9.68643C31.3541 10.4568 33.2985 12.7497 33.2985 15.7947C33.2985 19.2799 30.7855 22.068 26.4198 22.068H20.6219V19.4266H26.4565C29.2813 19.4266 30.6571 17.9041 30.6571 15.8864C30.6571 14.3089 29.795 12.8414 27.392 12.1994C26.5116 11.961 26.1997 11.5574 26.1263 10.6219C25.7778 5.99945 22.3843 2.75271 18.3122 2.75271C15.2856 2.75271 12.7909 4.40362 11.2501 7.57695C10.8832 8.32903 10.4247 8.60417 9.48917 8.60417C5.21521 8.60417 2.97736 11.0805 2.97736 14.0154C2.97736 17.0236 5.34361 19.4266 8.48028 19.4266H12.7329V22.068H8.64538ZM16.355 8.59846C16.1309 8.65451 15.9186 8.77015 15.7434 8.94539L11.2354 13.4534C10.7201 13.9687 10.7201 14.8041 11.2354 15.3194C11.7506 15.8346 12.5861 15.8346 13.1013 15.3194L15.3574 13.0633V26.4077C15.3574 27.1364 15.9481 27.7271 16.6768 27.7271C17.4055 27.7271 17.9963 27.1364 17.9963 26.4077V13.0643L20.2514 15.3194C20.7666 15.8346 21.6021 15.8346 22.1173 15.3194C22.6326 14.8041 22.6326 13.9687 22.1173 13.4534L17.6305 8.9665C17.3902 8.71535 17.0518 8.55893 16.6768 8.55893H16.6764C16.6299 8.55893 16.5834 8.56137 16.5371 8.56626C16.4759 8.57272 16.415 8.58345 16.355 8.59846Z"
        fill="#8E8E8E"
      />
    </svg>
  );
};

export default UploadIcon;
