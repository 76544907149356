//@ts-nocheck
//@ts-ignore
/* eslint-disable */
import { _GetUserProfile } from "@Services/Common";
import { _GetCourseDetails } from "@Services/Courses";
import { _GetSections } from "@Services/Sections";
import { _GetSubjectCourses, _GetSubjectStatistics } from "@Services/Subjects";
import { _GetTeacherProfile } from "@Services/Teachers";
import { createContext, ReactElement, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { TeachersContext } from "types/Teachers";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { TEACHER_ID, COURSE_ID, SUBJECT_ID, USER_DEVICE_ID } from "constants/index";
import { userInfo } from "./UserInfo.context";

const initialState: TeachersContext = {
  teacherId: null,
  setTeacherId: () => null,
  subjectId: null,
  setSubjectId: () => null,
  courseId: null,
  setCourseId: () => null,
  addCoursePopup: false,
  setAddCoursePopup: () => null,
  editSubjectPrice: false,
  setEditSubjectPrice: () => null,
  teacherProfile: null,
  refetchProfile: () => null,
  profileFetching: () => null,
  courseDetails: null,
  courseDetailsIsLoading: () => null,
  refetchCourseDetails: () => null,
  courseDetailsFetching: () => null,
  subjectStatistics: null,
  loadingSubjectStatistics: () => null,
  refetchSubjectStatistics: () => null,
  subjectStatisticsFeching: () => null,
  subjectCourses: null,
  subjectCoursesLoading: () => null,
  refetchSubjectCourses: () => null,
  lectureId: 0,
  setLectureId: () => null,
  sectionId: null,
  setSectionId: () => null,
  sectionData: null,
  sectionDataRefetch: () => null,
  lectureType: null,
  setLectureType: () => null,
  newData: null,
  setNewData: () => null,
  sectionSortSaveBtn: null,
  setSectionSortSaveBtn: () => null,
  sortedCollection: null,
  setSortedCollection: () => null,
  initDataHandler: () => null,
  cancleSavingSectionSort: null,
  setCancleSavingSectionSort: () => null,
};

export const TeachersDatasContext = createContext<TeachersContext>(initialState);

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function PortalProvider({ children }: Props): ReactElement {
  const {
    i18n: { language },
  } = useTranslation();
  const { teacherSidebar, subjectSidebar } = userInfo();
  const [addCoursePopup, setAddCoursePopup] = useState(false);
  const [sectionSortSaveBtn, setSectionSortSaveBtn] = useState(false);
  const [editSubjectPrice, setEditSubjectPrice] = useState(false);
  const [cancleSavingSectionSort, setCancleSavingSectionSort] = useState(false);
  const [sectionId, setSectionId] = useState(0);
  const [lectureId, setLectureId] = useState(0);
  const [lectureType, setLectureType] = useState<any>("");
  const location = useLocation();
  const path = location?.pathname.split("/")[location?.pathname.split("/").length - 1];
  const [newData, setNewData] = useState(null);
  const [sortedCollection, setSortedCollection] = useState(null);

  // LOCAL STORGE
  useEffect(() => {
    if (typeof window !== "undefined" && !localStorage.getItem(USER_DEVICE_ID)) {
      localStorage.setItem(USER_DEVICE_ID, uuidv4());
    }
  }, []);

  const [teacherId, setTeacherId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(TEACHER_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [subjectId, setSubjectId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(SUBJECT_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [courseId, setCourseId] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.localStorage.getItem(COURSE_ID);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const {
    data: teacherProfile,
    refetch: refetchProfile,
    isFetching: profileFetching,
  } = useQuery(["TeacherProfile", { language, teacherId }], () => _GetTeacherProfile(teacherId), {
    enabled: Boolean(teacherSidebar),
  });

  const {
    data: subjectStatistics,
    isLoading: loadingSubjectStatistics,
    refetch: refetchSubjectStatistics,
    isFetching: subjectStatisticsFeching,
  } = useQuery(["statistics", { language, subjectId }], () => _GetSubjectStatistics(subjectId), {
    enabled: Boolean(subjectSidebar),
  });

  const {
    data: subjectCourses,
    isLoading: subjectCoursesLoading,
    refetch: refetchSubjectCourses,
  } = useQuery(["courses", { language, subjectId }], () => _GetSubjectCourses(subjectId), {
    enabled: Boolean(subjectSidebar),
  });

  const { data: sectionData, refetch: sectionDataRefetch } = useQuery(
    ["sectionsId", { language, sectionId }],
    () => _GetSections(sectionId),
    {
      enabled: sectionId !== 0,
    }
  );

  useEffect(() => {
    if (sectionId !== 0) {
      sectionDataRefetch();
    }
  }, [sectionId]);

  const {
    data: courseDetails,
    isLoading: courseDetailsIsLoading,
    refetch: refetchCourseDetails,
    isFetching: courseDetailsFetching,
  } = useQuery(["courseid", { language, courseId }], () => _GetCourseDetails(courseId), {
    enabled: Boolean(path === "course"),
    onSuccess: (data) => {
      // Restructure Data for DND
      initDataHandler(data);
      setSortedCollection(courseDetails?.sections);
    },
  });

  const initDataHandler = (ARR) => {
    let initData = {
      columns: {},
      // Facilitate reordering of the columns
      ordered: [],
    };
    let obj = {};
    setNewData(initData);
    const sectionsWithoutIntro = ARR?.sections.filter((section) => section?.is_introductory === false);

    for (let i = 0; i < sectionsWithoutIntro.length; i++) {
      obj[String(sectionsWithoutIntro[i].id)] = sectionsWithoutIntro[i].lectures;
      initData.ordered.push([sectionsWithoutIntro[i].name, String(sectionsWithoutIntro[i].id)]);
      Object.assign(initData.columns, obj);
      if (i == sectionsWithoutIntro.length - 1) {
        setNewData(initData);
      }
    }
  };

  useEffect(() => {
    if (cancleSavingSectionSort) {
      refetchCourseDetails();
      setSectionSortSaveBtn(false);
    }
  }, [cancleSavingSectionSort, courseId]);

  useEffect(() => {
    if (courseDetails) {
      setCancleSavingSectionSort(false);
    }
  }, [courseDetails]);

  // LOCAL STORGE
  useEffect(() => {
    window.localStorage.setItem(TEACHER_ID, JSON.stringify(teacherId));
  }, [teacherId]);

  useEffect(() => {
    window.localStorage.setItem(SUBJECT_ID, JSON.stringify(subjectId));
  }, [subjectId]);

  useEffect(() => {
    window.localStorage.setItem(COURSE_ID, JSON.stringify(courseId));
  }, [courseId]);

  return (
    <TeachersDatasContext.Provider
      value={{
        teacherId,
        setTeacherId,
        subjectId,
        setSubjectId,
        courseId,
        setCourseId,
        addCoursePopup,
        setAddCoursePopup,
        teacherProfile,
        refetchProfile,
        profileFetching,
        courseDetails,
        courseDetailsIsLoading,
        refetchCourseDetails,
        courseDetailsFetching,
        subjectStatistics,
        loadingSubjectStatistics,
        refetchSubjectStatistics,
        subjectStatisticsFeching,
        subjectCourses,
        subjectCoursesLoading,
        refetchSubjectCourses,
        lectureId,
        setLectureId,
        sectionId,
        setSectionId,
        sectionData,
        sectionDataRefetch,
        lectureType,
        setLectureType,
        newData,
        setNewData,
        sectionSortSaveBtn,
        setSectionSortSaveBtn,
        sortedCollection,
        setSortedCollection,
        initDataHandler,
        editSubjectPrice,
        setEditSubjectPrice,
        cancleSavingSectionSort,
        setCancleSavingSectionSort,
      }}
    >
      {children}
    </TeachersDatasContext.Provider>
  );
}
