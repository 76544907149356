import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  ImageContainer,
  TeacherCoulmn,
  TeacherId,
  TeacherImg,
  TeacherImgDiv,
  TeacherName,
} from "@Components/TableLayout/TableLayout.styled";

const TeachersColumns = () => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  return [
    {
      id: "display_name",
      header: `${tCommon("name")}`,
      accessorKey: "display_name",
      cell: (row: any) => {
        return (
          <TeacherCoulmn>
            <ImageContainer>
              <TeacherImg src={row.row.original.image} onError={(e) => (e.currentTarget.style.opacity = "0")} />
              <TeacherImgDiv />
            </ImageContainer>
            <TeacherName>{row.row.original.display_name}</TeacherName>
            <TeacherId>#{row.row.original.id}</TeacherId>
          </TeacherCoulmn>
        );
      },
      sortingFn: (rowA, rowB) => {
        const nameA = rowA.original.display_name ? rowA.original.display_name : "";
        const nameB = rowB.original.display_name ? rowB.original.display_name : "";
        return nameA.localeCompare(nameB, language, { sensitivity: "base" });
      },
      sortDescFirst: false,
    },
    {
      id: "viewers_count",
      header: `${tCommon("views")}`,
      accessorKey: "viewers_count",
    },
    {
      id: "subjects_count",
      header: `${tCommon("subjects")}`,
      accessorKey: "subjects_count",
    },
    {
      id: "subscribers_count",
      header: `${tCommon("subscribers")}`,
      accessorKey: "subscribers_count",
    },
    {
      id: "remaining_balance",
      header: `${tCommon("balance")}`,
      accessorFn: (row: any) => `${row.instructor_balances[0]?.remaining_balance}`,
      cell: (row) => (
        <TeacherName>
          {row?.row?.original.instructor_balances.length > 0 ? (
            <Fragment>
              {row?.row?.original.instructor_balances[0].remaining_balance}{" "}
              {row?.row?.original.instructor_balances[0].currency}
            </Fragment>
          ) : (
            <span>0</span>
          )}
        </TeacherName>
      ),
    },
  ];
};

export default TeachersColumns;
