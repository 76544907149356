// LOCAL STORGE
export const TEACHER_ID = "TEACHER_ID";
export const SUBJECT_ID = "SUBJECT_ID";
export const COURSE_ID = "COURSE_ID";
export const USER_DEVICE_ID = "USER_DEVICE_ID";
export const FILTER_TYPE_TEACHERS = "FILTER_TYPE_TEACHERS";
export const FILTER_TYPE_SUBJECTS = "FILTER_TYPE_SUBJECTS";
export const ADMIN = "admin";
export const INSTRUCTOR = "instructor";
export const PRO_INSTRUCTOR = "pro_instructor";
export const STUDENT = "student";
export const AUTHORIZED_ROLES = ["admin", "instructor", "pro_instructor"];

// COOKIES
export const COOKIE_AUTH = "BAIMS_TOKEN";
export const DEFAULT_COOKIE_DOMAIN_NAME = ".baims.com";
export const DEFAULT_COOKIE_DOMAIN_SETTINGS = {
  domain: DEFAULT_COOKIE_DOMAIN_NAME,
};
