export const SearchIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.0703125 6.53487C0.0703125 7.43937 0.238903 8.28767 0.576083 9.07978C0.918615 9.86653 1.3896 10.5596 1.98903 11.1591C2.58846 11.7585 3.28155 12.2268 4.06831 12.564C4.86041 12.9012 5.70604 13.0697 6.60518 13.0697C7.27419 13.0697 7.91109 12.9734 8.51587 12.7807C9.12601 12.5827 9.68797 12.3124 10.2018 11.9699L13.8867 15.6548C14.0044 15.7725 14.1355 15.8582 14.28 15.9117C14.4299 15.9706 14.5851 16 14.7457 16C14.9758 16 15.1792 15.9465 15.3558 15.8394C15.5324 15.7377 15.6716 15.5959 15.7733 15.4139C15.875 15.232 15.9258 15.0286 15.9258 14.8038C15.9258 14.6433 15.8964 14.4907 15.8375 14.3462C15.784 14.2017 15.701 14.0759 15.5886 13.9689L11.9278 10.292C12.3078 9.76752 12.6048 9.18682 12.8189 8.54992C13.0384 7.91303 13.1481 7.24134 13.1481 6.53487C13.1481 5.63573 12.9768 4.79277 12.6343 4.00602C12.2971 3.21392 11.8288 2.51815 11.2294 1.91872C10.6299 1.31393 9.93417 0.84295 9.14206 0.50577C8.35531 0.16859 7.50968 0 6.60518 0C5.70604 0 4.86041 0.16859 4.06831 0.50577C3.28155 0.84295 2.58846 1.31393 1.98903 1.91872C1.3896 2.51815 0.918615 3.21392 0.576083 4.00602C0.238903 4.79277 0.0703125 5.63573 0.0703125 6.53487ZM1.7803 6.53487C1.7803 5.87122 1.90339 5.2477 2.14959 4.66433C2.40114 4.08095 2.74902 3.56715 3.19324 3.12293C3.63746 2.67871 4.15126 2.3335 4.73464 2.08731C5.31801 1.83576 5.94153 1.70998 6.60518 1.70998C7.27419 1.70998 7.90038 1.83576 8.48376 2.08731C9.06713 2.3335 9.57826 2.67871 10.0171 3.12293C10.4613 3.56715 10.8092 4.08095 11.0608 4.66433C11.3123 5.2477 11.4381 5.87122 11.4381 6.53487C11.4381 7.20388 11.3123 7.83007 11.0608 8.41345C10.8092 8.99682 10.4613 9.51062 10.0171 9.95484C9.57826 10.3937 9.06713 10.7389 8.48376 10.9905C7.90038 11.242 7.27419 11.3678 6.60518 11.3678C5.94153 11.3678 5.31801 11.242 4.73464 10.9905C4.15126 10.7389 3.63746 10.3937 3.19324 9.95484C2.74902 9.51062 2.40114 8.99682 2.14959 8.41345C1.90339 7.83007 1.7803 7.20388 1.7803 6.53487Z"
        fill="#454545"
      />
    </svg>
  );
};
