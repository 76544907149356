import ContentLoader from "react-content-loader";

export default function SectionsSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="700px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="80" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="160" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="240" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="320" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="400" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="480" rx="4" ry="4" width="100%" height="60px" />
      <rect x="0" y="560" rx="4" ry="4" width="100%" height="60px" />
    </ContentLoader>
  );
}
