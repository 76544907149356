import React, { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { _GetCategories } from "@Services/Common";
import { useQuery } from "react-query";
import { Category } from "types/Category";
import { useTranslation } from "react-i18next";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import {
  ArrowWrapper,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  RecursiveMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { RightArrowIcon } from "images/icons/RightArrowIcon";

type Props = {
  depth: number;
  hoveredCategory: Category;
  series: string[];
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  setPlacement: any;
  placement: any;
  setDepthNumber?: (setDepthNumber: number) => void;
};

export default function RecursivePortalMenu({
  hoveredCategory,
  depth,
  series,
  setIsDropdownOpen,
  setPlacement,
  placement,
  setDepthNumber,
}: Props) {
  const {
    i18n: { language },
  } = useTranslation();
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const getCatLink = (catSlug: string, catId: number) =>
    setPlacement([
      ...placement,
      { hierarcyText: `${series.join(" / ")} / ${catSlug}`, lastCategoryId: catId, id: Math.random() },
    ]);

  const { data: categoriesData, isLoading } = useQuery(
    [["onboarding-categories", language], { categoryId: hoveredCategory.id }],
    () => _GetCategories(hoveredCategory.id),
    {
      refetchOnMount: false,
      staleTime: 5 * 1000 * 60,
    }
  );

  const handleShallowRouting = (e: React.MouseEvent<Element, MouseEvent>, catId: number, catName: string) => {
    setIsDropdownOpen(false);
    getCatLink(catName, catId);
    setDepthNumber(0);
  };

  useEffect(() => {
    setRootCategoryHover(undefined);
  }, [hoveredCategory]);

  useEffect(() => {
    if (setDepthNumber) {
      setDepthNumber(depth);
    }
  }, [setDepthNumber]);

  return (
    <Fragment>
      <RecursiveMenuWrapper onPopup depth={depth} breadCrumb={placement}>
        <MainExploreDropdown isOpen={Boolean(hoveredCategory)} position="left" isRecursive>
          {isLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categoriesData?.options.map((cat: any) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    onClick={(e) => {
                      handleShallowRouting(e, cat.id, cat.name);
                    }}
                    onMouseEnter={() => setRootCategoryHover(cat)}
                  >
                    <ExploreDropdownContent>
                      {cat.emoji ? <span className="emojie">{cat.emoji}&nbsp;</span> : ""}
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </RecursiveMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && depth < 3 && (
        <RecursivePortalMenu
          depth={depth + 1}
          setDepthNumber={setDepthNumber}
          hoveredCategory={rootCategoryHover}
          series={[...series, rootCategoryHover.name]}
          setIsDropdownOpen={setIsDropdownOpen}
          setPlacement={setPlacement}
          placement={placement}
        />
      )}
    </Fragment>
  );
}
