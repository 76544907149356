import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import {
  AdminImg,
  AdminSidebar,
  AdminSideContent,
  Line,
  ListIconWrapper,
  LogoWrapper,
  TopContent,
} from "./AdminSideBar.styled";
import { BaimsLogo } from "images/logos/BaimsLogo";
import { ListIcon } from "images/icons/ListIcon";
import { useTablesFilters } from "context/TablesFilters.context";
import { userInfo } from "context/UserInfo.context";

type Props = {
  setOpenLogoutMenu: Dispatch<SetStateAction<boolean>>;
};

const AdminSideBar = ({ setOpenLogoutMenu }: Props) => {
  const { userData, setTeacherSidebar, setSubjectSidebar } = userInfo();
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();

  return (
    <AdminSidebar>
      <AdminSideContent>
        <TopContent>
          <LogoWrapper>
            <BaimsLogo />
          </LogoWrapper>
          <Line />
          <Link to={!isSectionsSorted && "/"}>
            <ListIconWrapper
              onClick={() => {
                if (isSectionsSorted) {
                  setWorningSectionsSortPopup(true);
                } else {
                  setTeacherSidebar(false);
                  setSubjectSidebar(false);
                }
              }}
            >
              <ListIcon />
            </ListIconWrapper>
          </Link>
        </TopContent>
        <AdminImg
          src={`${userData?.profile_picture.url}`}
          alt={`${userData?.name}`}
          onClick={() => {
            if (isSectionsSorted) {
              setWorningSectionsSortPopup(true);
            } else {
              setOpenLogoutMenu(true);
            }
          }}
        />
      </AdminSideContent>
    </AdminSidebar>
  );
};

export default AdminSideBar;
