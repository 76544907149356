import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import rodalCss from "./assets/RodalCss";

export const GlobalStyle = createGlobalStyle<{ readonly locale?: string }>`
  ${normalize}
  
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /* outline: 1px solid red; */
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    font-size: ${({ theme }) => theme.typography.htmlBaseFontPx}px;
    height:-webkit-fill-available ;
  }

  body {
    font-size: 1.6rem;
    overflow: hidden;
    line-height: ${({ theme }) => theme.typography.baseLineHeight};
    position: relative;
    cursor: default;
    font-weight: normal;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    background-color: ${({ theme }) => theme.colors.gray7};
    color: ${({ theme }) => theme.colors.black};
    height: 100% ;
  }

  [hidden] {
    display: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  a,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    outline: none;
  }

  button,
  button:focus {
    outline: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  input:-webkit-autofill { 
    -webkit-background-clip: text;
  }


.custom-chrome-picker .chrome-picker > div:nth-child(2) > div:nth-child(1)  > div:nth-child(2) > div:nth-child(2) {
 display: none !important;
} 

.custom-chrome-picker .chrome-picker > div > div:nth-child(1) > div:last-child > div:first-child {
  margin-bottom: 0px !important;
}

.custom-chrome-picker .chrome-picker > div > div:nth-child(1) > div:first-child > div {
  margin-top: 0px !important;
}

.custom-chrome-picker .chrome-picker > div > div:nth-child(1)  {
  align-items: center !important;
} 

.custom-chrome-picker .chrome-picker > div > div:nth-child(2) > div:last-child {
  display: none !important;
}

  ${rodalCss}
`;
