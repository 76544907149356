import styled from "styled-components";

export const StyledTable = styled.table`
  padding: 0px 8px 16px;
  width: 100%;
  border-radius: 8px;
  border-spacing: 0px;
  background: linear-gradient(180deg, #ffffff 0%, #fbfbfb 100%);
  border: 0.7px solid ${({ theme }) => theme.colors.gray5_old};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray5_old};
    position: absolute;
    top: 48px;
    left: 0;
  }
`;

export const TableHead = styled.thead``;

export const TableHeadTr = styled.tr``;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TableHeadTh = styled.th`
  padding: 12px 16px;
  text-align: start;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray3};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
  cursor: pointer;
  user-select: none;
  &:hover {
    color: ${({ theme }) => theme.colors.gray2};
  }
`;

export const TableBody = styled.tbody`
  transform: translatey(8px);
`;

export const TableBodyTr = styled.tr`
  transition: all 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
    cursor: pointer;
  }
`;

export const TableBodyth = styled.th`
  padding: 12px 16px;
  width: 16%;
  text-align: start;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
  .link_style {
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    font-size: ${({ theme }) => theme.fontSizes.body14};
    line-height: 24px;
    color: ${({ theme }) => theme.colors.nero};
    display: flex;
  }
  .is_balance {
    text-transform: uppercase;
  }
  &:first-child {
    width: 25%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child {
    width: 10%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const TeacherCoulmn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ImageContainer = styled.div``;

export const TeacherImg = styled.img`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
  z-index: 20;
`;

export const TeacherImgDiv = styled.div`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  margin-top: -40px;
`;

export const TeacherName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const TeacherId = styled(TeacherName)`
  color: ${({ theme }) => theme.colors.gray3};
`;

export const SortingArrowWrapper = styled.div<{ readonly isUp?: boolean }>`
  transform: ${({ isUp }) => (isUp ? "rotate(180deg)" : "rotate(0deg)")};
  display: inline-block;
  margin: 0 8px;
`;

export const SortingIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  svg:first-child {
    transform: rotate(180deg);
  }
`;
