import { StyledInput } from "@Components/TeachersList/TeachersHeader/TeachersHeader.styled";
import { useState, useEffect } from "react";

export function DebouncedInput({
  value: initialValue,
  defaultValue,
  onChange,
  debounce = 600,
  ...props
}: {
  value: string | number;
  defaultValue?: any;
  onChange: (value: string | number | number[] | string[]) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <StyledInput {...props} value={value} defaultValue={defaultValue} onChange={(e) => setValue(e.target.value)} />
  );
}
