import styled from "styled-components";

export const AnalyticsContainer = styled.div`
  padding: 16px 0px 20px;
`;

export const AnalyticsTitle = styled.h4`
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const BoxsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const AnalyticsStyledBox = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding: 16px 12px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray5_old};
  border-radius: 8px;
`;

export const IconWrapper = styled.div<{ readonly color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: ${({ color }) => color && color};
  border-radius: 50%;
`;

export const NumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NumbersTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray_new};
`;

export const DataNumbers = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;
