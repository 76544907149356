import { stripButton } from "../../utils/StyleUtill";
import styled, { css } from "styled-components";

export const CommonButton = styled.button<{
  readonly variant: "default" | "fullWidth" | "lightseagule";
  readonly disabled?: boolean;
}>`
  ${stripButton};
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body18};
  line-height: 32px;
  border-radius: 8px;
  transition: 0.2s ease background, 0.2s ease box-shadow;
  ${({ variant, theme }) =>
    variant === "default"
      ? css`
          background: linear-gradient(-180deg, #45aaf2 0%, #32a1f0 100%);
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
          color: ${theme.colors.white};
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            background: ${theme.colors.gray8};
          }
        `
      : variant === "fullWidth"
      ? css`
          width: 100%;
          background-color: ${theme.colors.seagule};
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
          color: ${theme.colors.white};
          &:disabled,
          &[disabled] {
            cursor: not-allowed;
            background-color: ${theme.colors.gray8};
          }
        `
      : variant === "lightseagule"
      ? css`
          background: ${({ theme }) => theme.colors.seagullNeutrals};
          color: ${({ theme }) => theme.colors.seagule};
        `
      : css``}
`;
