import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import { _UpdateSubject } from "@Services/Subjects";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Subject } from "types/Subjects";
import { useForm, Controller } from "react-hook-form";
import PopupsLayout from "@Components/PopupsLayout";
import { _GetCurrencies } from "@Services/Common";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { calculateDiscount } from "utils/Forms";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const EditSubjectPricePopup = ({ isOpen, closePopup }: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const { userData } = userInfo();
  const { subjectId, refetchSubjectStatistics, subjectStatistics, teacherId } = useContext(TeachersDatasContext);
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { data: currenciesList } = useQuery(["currencies", language], () => _GetCurrencies());
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors, isDirty },
  } = useForm<Subject>();

  useEffect(() => {
    if (subjectStatistics) {
      reset({
        final_price: subjectStatistics?.final_price,
        currency: subjectStatistics?.currency,
      });
    }
    setSubmitValidation("");
  }, [subjectStatistics, isOpen]);

  const submitForm = (data: Subject) => {
    setIsLoading(true);
    _UpdateSubject(subjectId, { ...data, is_image_changed: false }, teacherId)
      .then(() => {
        closePopup(false);
        refetchSubjectStatistics();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_subject_price")}
          buttonTitle={tCommon("save_changes")}
          buttonState={!isDirty}
          noteMessage={tCommon("edit_price_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("total_price")}
                value={subjectStatistics ? subjectStatistics?.price : ""}
                disabled
              />
              <FormControl variant="filled" fullWidth disabled={true}>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("currency")}</InputLabel>
                <Controller
                  name="currency"
                  control={control}
                  defaultValue={subjectStatistics ? subjectStatistics.currency : ""}
                  render={({ field }) => (
                    <Select {...field} id="demo-simple-select" IconComponent={ExpandMoreIcon} MenuProps={MenuProps}>
                      {currenciesList?.map((cur: string, index: number) => (
                        <MenuItem key={index} value={cur}>
                          {cur.toLocaleUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("final_price")}
                disabled={Boolean(subjectStatistics?.price === 0 || isRegularInstructor)}
                {...register("final_price", {
                  min: {
                    value: 0,
                    message: tCommon("discount_less_validation"),
                  },
                  max: {
                    value: subjectStatistics?.price,
                    message: tCommon("finalprice_validation"),
                  },
                  required: tCommon("required_message"),
                  valueAsNumber: true,
                })}
                error={Boolean(errors.final_price)}
                helperText={errors.final_price && errors.final_price?.message}
              />
              <Input
                id="filled-basic"
                variant="filled"
                label={tCommon("discount")}
                value={`${calculateDiscount(subjectStatistics?.price, watch("final_price"))}%`}
                disabled
              />
            </InputsWrapper>
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default EditSubjectPricePopup;
