import styled, { css } from "styled-components";

export const LogoutMenu = styled.div<{
  readonly openLogoutMenu?: boolean;
  readonly isNotAdmin?: boolean;
}>`
  position: fixed;
  bottom: 28px;
  left: 70px;
  width: 169px;
  /* height: 212px; */
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transition: all 0.4s ease;
  visibility: ${({ openLogoutMenu, isNotAdmin }) => (openLogoutMenu || isNotAdmin ? "visible" : "hidden")};
  opacity: ${({ openLogoutMenu, isNotAdmin }) => (openLogoutMenu || isNotAdmin ? "1" : "0")};
  z-index: 9999;
`;

export const LogMenuHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
`;

export const AdminInfo = styled.div``;

export const AdminName = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const RoleTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const MenuUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px;
  width: 100%;
  &:first-child {
    position: relative;
  }
`;

export const MenuLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 12px 6px;
  &:hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.gray5_old};
  }
`;

export const LiName = styled.span``;

export const ArrowWrapper = styled.div<{ readonly isOpen?: boolean }>`
  cursor: pointer;
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotateZ(-180deg)" : "rotateZ(0deg)")};
`;

export const MenulogoutWrapper = styled(MenuLi)`
  color: ${({ theme }) => theme.colors.red};
`;

export const LangMenu = styled.div<{
  readonly isOpen?: boolean;
  readonly isProfile?: boolean;
}>`
  position: absolute;
  right: -8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.3s ease;
  padding: 8px;
  width: 95px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  ${({ isOpen, isProfile }) =>
    isOpen
      ? css`
          top: ${isProfile ? "45px" : "90px"};
          opacity: 1;
          visibility: visible;
        `
      : css`
          top: ${isProfile ? "55px" : "100px"};
          opacity: 0;
          visibility: hidden;
        `}
`;

export const LangName = styled.span<{ readonly isSelected?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  padding: 10px 8px;
  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.seagule : theme.colors.gray1)};
  width: 100%;
  transition: all 0.3s ease;
  &:hover {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.gray5_old};
    color: ${({ isSelected, theme }) => isSelected && theme.colors.gray1};
  }
`;
