import {
  SubjectCoulmn,
  SubjectId,
  SubjectImageContainer,
  SubjectImg,
  SubjectImgPlaceholder,
  SubjectName,
} from "@Components/RegularHeader/RegularHeader.styled";
import { useTranslation } from "react-i18next";
import { getDefaultSubjectName } from "utils/Strings";

const SubjectsColumns = () => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");

  return [
    {
      id: "name",
      header: `${tCommon("name")}`,
      accessorKey: "name",
      cell: (row: any) => (
        <SubjectCoulmn>
          <SubjectImageContainer>
            <SubjectImg src={row.row.original.image} onError={(e) => (e.currentTarget.style.opacity = "0")} />
            <SubjectImgPlaceholder />
          </SubjectImageContainer>
          <SubjectName>{getDefaultSubjectName(row, language)}</SubjectName>
          <SubjectId>#{row.row.original.id}</SubjectId>
        </SubjectCoulmn>
      ),
      sortingFn: (rowA, rowB) => {
        const nameA = getDefaultSubjectName(rowA, language, true)
          ? getDefaultSubjectName(rowA, language, true)
              .replace(/[0-9\-.,@&]+/g, "")
              .trim()
          : "";
        const nameB = getDefaultSubjectName(rowB, language, true)
          ? getDefaultSubjectName(rowB, language, true)
              .replace(/[0-9\-.,@&]+/g, "")
              .trim()
          : "";

        if (!nameA && !nameB) {
          return 0;
        } else if (!nameA) {
          return 1;
        } else if (!nameB) {
          return -1;
        }

        return nameA.localeCompare(nameB, language, { sensitivity: "base" });
      },
      sortDescFirst: false,
    },
    {
      id: "state",
      header: `${tCommon("state")}`,
      accessorKey: "is_active",
      cell: (row: any) => (
        <SubjectName>{row.row.original.is_active == 1 ? tCommon("active") : tCommon("inactive")}</SubjectName>
      ),
    },
    {
      id: "chapters",
      header: `${tCommon("chapters")}`,
      accessorKey: "courses_count",
    },
    {
      id: "views",
      header: `${tCommon("views")}`,
      accessorKey: "views_count",
    },
  ];
};

export default SubjectsColumns;
