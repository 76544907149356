import Axios from "@Configs/Axios";
import { Profile, SortingValuesType } from "types/Teachers";

export const _GetTeachersList = (
  category_id?: number | string,
  currency?: string,
  searchText?: string,
  pageSizeFilter?: number,
  pageNumberFilter?: number,
  subscriberFiltersValues?: number[],
  sortingTeachers?: SortingValuesType[]
) =>
  Axios.post(`/v1/instructors/filter`, {
    limit: pageSizeFilter,
    skip: (pageNumberFilter - 1) * pageSizeFilter,
    ...(category_id !== "" && { category_id }),
    ...(currency && { currency }),
    ...(searchText && { searchText }),
    ...(subscriberFiltersValues && {
      subscribers_count_min_value: subscriberFiltersValues[0],
      subscribers_count_max_value: subscriberFiltersValues[1],
    }),
    ...(sortingTeachers.length !== 0 && {
      sortingCriteria: sortingTeachers[0].id,
      sortingOrder: sortingTeachers[0].desc ? "DESC" : "ASC",
    }),
  }).then(({ data }) => data);

export const _GetTeacherProfile = (id: number | null) =>
  Axios.get(`/v1/instructors/${id}/detail`).then(({ data }) => data.data);

export const _UpdateTeacherProfile = (id: number | null, data: Profile) => Axios.patch(`/v1/instructors/${id}`, data);
