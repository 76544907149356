import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useQuery } from "react-query";
import { Category } from "types/Category";
import { _GetCategories } from "@Services/Common";
import { useTranslation } from "react-i18next";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import {
  ArrowWrapper,
  BreadcrumbPopupContainer,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  MainExploreMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { RightArrowIcon } from "images/icons/RightArrowIcon";
import RecursivePortalMenu from "./RecursivePortalMenu";

type Props = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  buttonRef?: any;
  setPlacement: any;
  placement: number[];
  currentCurrency: string;
};

const BreadcrumbsMenuPortal = ({
  isDropdownOpen,
  setIsDropdownOpen,
  buttonRef,
  setPlacement,
  placement,
  currentCurrency,
}: Props) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const [depthNumber, setDepthNumber] = useState(0);
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const buttonRect = buttonRef?.current?.getBoundingClientRect();
  const top = buttonRect?.top + 20;
  const left = buttonRect?.left;
  const style =
    language === "ar" ? { top: `${top}px`, right: `${left - 65}px` } : { top: `${top}px`, left: `${left}px` };

  useEffect(() => {
    if (!isDropdownOpen) {
      setDepthNumber(0);
    }
  }, [isDropdownOpen]);

  const { data: categories, isLoading: categoriesLoading } = useQuery(
    ["onboarding-teacher-categories", language],
    () => _GetCategories(),
    {
      refetchOnMount: false,
    }
  );

  const categoriesHandler = (curreny: string) => {
    if (curreny?.toLowerCase() === "kwd") {
      return 1;
    } else if (curreny?.toLowerCase() === "sar") {
      return 4;
    } else if (curreny?.toLowerCase() === "jod") {
      return 130;
    } else if (curreny?.toLowerCase() === "bhd") {
      return 129;
    } else if (curreny?.toLowerCase() === "try") {
      return 211;
    }
  };

  useEffect(() => {
    setRootCategoryHover(undefined);
    if (window) {
      if (isDropdownOpen) {
        window.addEventListener("mousemove", checkHover);
      } else {
        window.removeEventListener("mousemove", checkHover);
      }
    }
  }, [isDropdownOpen]);

  const handleShallowRootCategory = (
    e: React.MouseEvent<Element, MouseEvent>,
    catId: number,
    catName: string,
    catEmoji: string
  ) => {
    setIsDropdownOpen(false);
    setPlacement([...placement, { hierarcyText: catEmoji, lastCategoryId: catId, id: Math.random() }]);
    setDepthNumber(0);
  };

  const checkHover = useCallback((e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  }, []);

  return createPortal(
    <BreadcrumbPopupContainer style={style} depthNumber={depthNumber}>
      <MainExploreMenuWrapper isOpen={isDropdownOpen}>
        <MainExploreDropdown isOpen={isDropdownOpen} position="left">
          {categoriesLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categories?.options.map((cat) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    onClick={(e) => {
                      if (currentCurrency) {
                        if (categoriesHandler(currentCurrency) === cat.id) {
                          handleShallowRootCategory(e, cat.id, cat.name, cat.emoji);
                        }
                      } else {
                        handleShallowRootCategory(e, cat.id, cat.name, cat.emoji);
                      }
                    }}
                    onMouseEnter={() => {
                      if (currentCurrency) {
                        if (categoriesHandler(currentCurrency) === cat.id) {
                          setRootCategoryHover(cat);
                        }
                      } else {
                        setRootCategoryHover(cat);
                      }
                    }}
                  >
                    <ExploreDropdownContent
                      isFirstCat
                      isDisabled={categoriesHandler(currentCurrency) === cat.id}
                      currency={currentCurrency}
                    >
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </MainExploreMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && (
        <RecursivePortalMenu
          depth={0}
          setDepthNumber={setDepthNumber}
          hoveredCategory={rootCategoryHover}
          series={[rootCategoryHover.emoji]}
          setIsDropdownOpen={setIsDropdownOpen}
          setPlacement={setPlacement}
          placement={placement}
        />
      )}
    </BreadcrumbPopupContainer>,
    document.body
  );
};

export default BreadcrumbsMenuPortal;
