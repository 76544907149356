import { TeachersDatasContext } from "context/Teachers.context";
import { CameraIcon } from "images/icons/CameraIcon";
import { GlassIcon } from "images/icons/GlassIcon";
import { ClockIcon } from "images/icons/ClockIcon";
import { NoteIcon } from "images/icons/NoteIcon";
import { SheetIcon } from "images/icons/SheetIcon";
import { UserSegauleIcon } from "images/icons/UserSegauleIcon";
import {
  AnalyticsContainer,
  AnalyticsStyledBox,
  AnalyticsTitle,
  BoxsContainer,
  DataNumbers,
  IconWrapper,
  NumbersContainer,
  NumbersTitle,
} from "./AnalyticsBoxs.styled";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { secondsToHms } from "utils/Time";

const AnalyticsBoxs = () => {
  const { t: tCommon } = useTranslation("common");
  const { subjectStatistics } = useContext(TeachersDatasContext);

  return (
    <AnalyticsContainer>
      <AnalyticsTitle>{tCommon("analytics")}</AnalyticsTitle>
      <BoxsContainer>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(254, 199, 108, 0.1)"}>
            <GlassIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("views")}</NumbersTitle>
            <DataNumbers>{subjectStatistics?.views_count}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(69, 170, 242,0.1)"}>
            <UserSegauleIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("subscribers")}</NumbersTitle>
            <DataNumbers>{subjectStatistics?.subscribers_count}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>

        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(19, 175, 114,0.1)"}>
            <CameraIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("videos")}</NumbersTitle>
            <DataNumbers>{subjectStatistics?.videos_count}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
      </BoxsContainer>
      <BoxsContainer>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(242, 81, 81,0.1)"}>
            <NoteIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("notes")}</NumbersTitle>
            <DataNumbers>{subjectStatistics?.notes_count}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
        <AnalyticsStyledBox>
          <IconWrapper color={"rgba(254, 199, 108,0.1)"}>
            <ClockIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("duration")}</NumbersTitle>
            <DataNumbers>
              {subjectStatistics?.videos_duration !== 0 ? secondsToHms(subjectStatistics?.videos_duration) : 0}
            </DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
        <AnalyticsStyledBox style={{ opacity: 0 }}>
          <IconWrapper color={"rgba(174, 140, 204,0.1)"}>
            <SheetIcon />
          </IconWrapper>
          <NumbersContainer>
            <NumbersTitle>{tCommon("subscriptions")}</NumbersTitle>
            <DataNumbers>{subjectStatistics?.subscriptions_count}</DataNumbers>
          </NumbersContainer>
        </AnalyticsStyledBox>
      </BoxsContainer>
    </AnalyticsContainer>
  );
};

export default AnalyticsBoxs;
