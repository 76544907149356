import ErrorBoundary from "@Components/ErrorBoundry";
import GridConfig from "@Configs/GridConfig";
import QueryClientConfig from "@Configs/QueryClientConfig";
import { GlobalStyle } from "@Style/GlobalStyle";
import Theme from "@Style/Theme";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { QueryClientProvider } from "react-query";
import UserInfoProvider from "context/UserInfo.context";
import PortalProvider from "context/Teachers.context";
import TablesFiltersProvider from "context/TablesFilters.context";
import PagesRoute from "routes";
import { GridThemeProvider } from "styled-bootstrap-grid";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import stylisRTLCSS from "stylis-rtlcss";
import { isRTL } from "utils/misc";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import * as Mui from "@mui/material/styles";
import { prefixer } from "stylis";
import "moment/locale/ar";
import "moment/locale/tr";

const cacheLtr = createCache({
  key: "muiltr",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const ltrTheme = Mui.createTheme({
  direction: "ltr",
  typography: {
    fontFamily: ["AvenirNextWorld", "sans-serif"].join(","),
  },
});
const rtlTheme = Mui.createTheme({
  direction: "rtl",
  typography: {
    fontFamily: ["AvenirNextWorld", "sans-serif"].join(","),
  },
});

function App() {
  const [isRtl, setIsRtl] = useState(false);
  const {
    i18n: { language },
  } = useTranslation("common");

  useEffect(() => {
    if (language === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  }, [language]);

  useLayoutEffect(() => {
    document.body.setAttribute("dir", isRtl ? "rtl" : "ltr");
  }, [isRtl]);

  return (
    <StyleSheetManager stylisPlugins={isRTL(language) ? [stylisRTLCSS] : []}>
      <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
        <Mui.ThemeProvider theme={isRtl ? rtlTheme : ltrTheme}>
          <ThemeProvider theme={Theme}>
            <GridThemeProvider gridTheme={GridConfig}>
              <Fragment>
                <GlobalStyle />
                <ErrorBoundary>
                  <QueryClientProvider client={QueryClientConfig}>
                    <Helmet
                      title="Teacher Portal"
                      htmlAttributes={{
                        dir: isRTL(language) ? "rtl" : "ltr",
                        lang: language,
                      }}
                    />
                    <UserInfoProvider>
                      <TablesFiltersProvider>
                        <PortalProvider>
                          <PagesRoute />
                        </PortalProvider>
                      </TablesFiltersProvider>
                    </UserInfoProvider>
                  </QueryClientProvider>
                </ErrorBoundary>
              </Fragment>
            </GridThemeProvider>
          </ThemeProvider>
        </Mui.ThemeProvider>
      </CacheProvider>
    </StyleSheetManager>
  );
}

export default App;
