import { ErrorType, StyledRodal } from "@Components/PopupsLayout/PopupsLayout.styled";
import { _DeleteSubject } from "@Services/Subjects";
import { TeachersDatasContext } from "context/Teachers.context";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CancleButton,
  DeleteButton,
  DeletePopupAlert,
  DeletePopupButtons,
  DeletePopupHeader,
  DeletePopupWrapper,
} from "./DeleteSubjectPopup.styled";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  subjectid: string | undefined;
};

const DeleteSubjectPopup = ({ isOpen, closePopup, subjectid }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [displayedError, setDisplayedError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { teacherId } = useContext(TeachersDatasContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) {
      setDisplayedError("");
    }
  }, [isOpen]);

  const deleteSubjectHandler = (id: string | undefined, teacherid: number | null) => {
    setIsLoading(true);
    _DeleteSubject(id, teacherid)
      .then(() => {
        closePopup(false);
        navigate(`/${teacherId}/subjects`);
      })
      .catch((err) => setDisplayedError(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <StyledRodal isDelete visible={isOpen} onClose={() => closePopup(false)}>
      <DeletePopupWrapper>
        <DeletePopupHeader>{tCommon("delete_subject_title")}</DeletePopupHeader>
        <DeletePopupAlert hasError={displayedError !== ""}>{tCommon("delete_subject_message")}</DeletePopupAlert>
        {displayedError && <ErrorType>{displayedError}</ErrorType>}
        <DeletePopupButtons>
          <CancleButton variant="lightseagule" onClick={() => closePopup(false)}>
            {tCommon("cancel")}
          </CancleButton>
          <DeleteButton
            variant="default"
            disabled={isLoading}
            onClick={() => deleteSubjectHandler(subjectid, teacherId)}
          >
            {isLoading ? <CircularProgress color="inherit" size={24} /> : tCommon("delete")}
          </DeleteButton>
        </DeletePopupButtons>
      </DeletePopupWrapper>
    </StyledRodal>
  );
};

export default DeleteSubjectPopup;
