import AnalyticsBoxs from "@Components/SubjectOverview/AnalyticsBoxs";
import EditSubjectPopup from "@Components/Popups/EditSubjectPopup";
import OverviewHeader from "@Components/SubjectOverview/OverviewHeader";
import OverviewPrice from "@Components/SubjectOverview/OverviewPrice";
import { PagesContainer } from "@Components/RegularHeader/RegularHeader.styled";
import { _GetSubjectStatistics } from "@Services/Subjects";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Fragment, useContext } from "react";
import SubjectOverviewSkeleton from "@Components/Skeletons/SubjectOverviewSkeleton";
import { TeachersDatasContext } from "context/Teachers.context";
import DeleteSubjectPopup from "@Components/Popups/DeleteSubjectPopup";
import EditSubjectPricePopup from "@Components/Popups/EditSubjectPricePopup";
import AddCoursePopup from "@Components/Popups/AddCoursePopup";
import SortingWorningPopup from "@Components/Popups/SortingWorningPopup";
import { useTablesFilters } from "context/TablesFilters.context";

const SubjectOverview = () => {
  const { subjectid } = useParams();
  const [editSubject, setEditSubject] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const {
    subjectStatisticsFeching,
    setSubjectId,
    addCoursePopup,
    setAddCoursePopup,
    editSubjectPrice,
    setEditSubjectPrice,
  } = useContext(TeachersDatasContext);
  const { worningSectionsSortPopup } = useTablesFilters();

  useEffect(() => {
    if (subjectid) {
      setSubjectId(Number(subjectid));
    }
  }, [subjectid]);

  return (
    <PagesContainer>
      <OverviewHeader openPopup={setEditSubject} />
      {!subjectStatisticsFeching ? (
        <Fragment>
          <AnalyticsBoxs />
          <OverviewPrice openEditSubjectPrice={setEditSubjectPrice} />
        </Fragment>
      ) : (
        <SubjectOverviewSkeleton />
      )}
      <EditSubjectPopup isOpen={editSubject} closePopup={setEditSubject} openDeletePopup={setOpenDeletePopup} />
      <EditSubjectPricePopup isOpen={editSubjectPrice} closePopup={setEditSubjectPrice} />
      <AddCoursePopup isOpen={addCoursePopup} closePopup={setAddCoursePopup} />
      <DeleteSubjectPopup isOpen={openDeletePopup} closePopup={setOpenDeletePopup} subjectid={subjectid} />
      <SortingWorningPopup isOpen={worningSectionsSortPopup} />
    </PagesContainer>
  );
};

export default SubjectOverview;
