import styled from "styled-components";

export const ProfileLogoutMenu = styled.div<{
  readonly isOpen?: boolean;
}>`
  position: fixed;
  bottom: 85px;
  left: 70px;
  width: 210px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transition: all 0.4s ease;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  z-index: 9999;
`;

export const OpenLaneWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
