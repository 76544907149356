import ContentLoader from "react-content-loader";

export default function SubjectCoursesSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height="1000px"
      backgroundColor="rgba(255, 255, 255, 0.1)"
      foregroundColor="rgba(255, 255, 255, 0.15)"
    >
      <rect x="0" y="20" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="80px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="140px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="200px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="260px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="320px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="380px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="440px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="500px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="560px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="620px" rx="4" ry="4" width="100%" height="40px" />
      <rect x="0" y="680px" rx="4" ry="4" width="100%" height="40px" />
    </ContentLoader>
  );
}
