import { RoundedArrowIcon } from "images/icons/RoundedArrowIcon";
import { useEffect, useRef, useState } from "react";
import {
  ListNumberMenu,
  ListNumbersContainer,
  PageSizeNumber,
  PaginateControlContainer,
  PaginationContainer,
  SetectStyled,
  Showing,
  ListNumberArrowWrapper,
} from "./TeachersPaginate.styled";
import Pagination from "@mui/material/Pagination";
import { useClickAway } from "react-use";
import { useTranslation } from "react-i18next";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  table: any;
  totalNumbers: number;
};

const TeachersPaginate = ({ table, totalNumbers }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const pageSizeRef = useRef(null);
  const [listNumber, setListNumber] = useState(false);
  const { pageSizeFilter, setPageSizeFilter, pageNumberFilter, setPageNumberFilter } = useTablesFilters();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumberFilter(value);
  };

  useClickAway(pageSizeRef, () => setListNumber(false));

  useEffect(() => {
    table.setPageSize(pageSizeFilter);
  }, [pageSizeFilter, pageNumberFilter]);

  return (
    <PaginationContainer>
      <ListNumbersContainer>
        <Showing>{tCommon("showing")}</Showing>
        <SetectStyled opened={listNumber} onClick={() => setListNumber(true)}>
          <span>{pageSizeFilter}</span>
          <ListNumberArrowWrapper isOpen={listNumber}>
            <RoundedArrowIcon />
          </ListNumberArrowWrapper>
        </SetectStyled>
        <ListNumberMenu ref={pageSizeRef} listNumber={listNumber}>
          {[10, 20, 30].map((pageSize) => (
            <PageSizeNumber
              isSelected={pageSize === pageSizeFilter}
              key={pageSize}
              onClick={() => {
                setPageSizeFilter(pageSize);
                setPageNumberFilter(1);
                setListNumber(false);
              }}
            >
              {pageSize}
            </PageSizeNumber>
          ))}
        </ListNumberMenu>
        <Showing>{tCommon("results_number", { count: totalNumbers })}</Showing>
      </ListNumbersContainer>
      <PaginateControlContainer>
        <Pagination
          page={pageNumberFilter}
          count={Math.ceil(totalNumbers / pageSizeFilter)}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
        />
      </PaginateControlContainer>
    </PaginationContainer>
  );
};

export default TeachersPaginate;
