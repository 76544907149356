import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import {
  AddPlacmentText,
  AddplacmentWrapper,
  FilterDropdownPortal,
  FilterName,
  FilterWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import { ListNumberArrowWrapper } from "@Components/TeachersList/TeachersPaginate/TeachersPaginate.styled";
import { RoundedArrowIcon } from "images/icons/RoundedArrowIcon";
import { PlusIcon } from "images/icons/PlusIcon";
import { useTranslation } from "react-i18next";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

type Props = {
  children?: React.PropsWithChildren<any>;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  buttonRef: any;
  placement: number[];
  categoriesValidation?: string;
};

const BreadcrumbPortalButton = ({ children, isOpen, setIsOpen, buttonRef, placement, categoriesValidation }: Props) => {
  const { userData } = userInfo();
  const { t: tCommon } = useTranslation("common");
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  return (
    <FilterWrapper onPopup>
      <FilterDropdownPortal
        ref={buttonRef}
        isSeagule={placement?.length >= 1}
        isDisabled={Boolean(isRegularInstructor)}
        hasError={categoriesValidation}
        onClick={() => {
          if (isRegularInstructor) {
            setIsOpen(false);
          } else {
            setIsOpen(!isOpen);
          }
        }}
        opened={isOpen}
      >
        {placement?.length >= 1 ? (
          <AddplacmentWrapper>
            <PlusIcon width="14" height="14" />
            <AddPlacmentText>{tCommon("add_placement")}</AddPlacmentText>
          </AddplacmentWrapper>
        ) : (
          <FilterName>{tCommon("choose_placement")}</FilterName>
        )}
        {placement?.length === 0 && (
          <ListNumberArrowWrapper isOpen={isOpen}>
            <RoundedArrowIcon />
          </ListNumberArrowWrapper>
        )}
      </FilterDropdownPortal>
      {children}
    </FilterWrapper>
  );
};

export default BreadcrumbPortalButton;
