export const WhiteArrowUp = () => {
  return (
    <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00031 0.180908C6.90135 0.180908 6.8102 0.201742 6.72687 0.243408C6.63833 0.279867 6.5576 0.334554 6.48468 0.407471L0.42218 6.59497C0.286764 6.7356 0.219055 6.90747 0.219055 7.1106C0.219055 7.2408 0.250305 7.3606 0.312805 7.46997C0.375305 7.57935 0.461243 7.66528 0.570618 7.72778C0.674784 7.79028 0.794576 7.82153 0.929993 7.82153C1.1227 7.82153 1.29197 7.75382 1.43781 7.61841L7.00031 1.93091L12.5628 7.61841C12.7086 7.75382 12.8779 7.82153 13.0706 7.82153C13.206 7.82153 13.3258 7.79028 13.43 7.72778C13.5394 7.66528 13.6253 7.57935 13.6878 7.46997C13.7503 7.3606 13.7816 7.2408 13.7816 7.1106C13.7816 6.90747 13.7112 6.7356 13.5706 6.59497L7.51593 0.407471C7.44301 0.334554 7.36489 0.279867 7.28156 0.243408C7.19301 0.201742 7.09926 0.180908 7.00031 0.180908Z"
        fill="white"
      />
    </svg>
  );
};
