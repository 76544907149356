import { createContext, ReactElement, useContext, useEffect, useState } from "react";
import { SortingState } from "@tanstack/table-core";
import { TablesFiltersType } from "types/TablesFilters";
import {
  OPENED_FILTERS,
  CURRENCIES_FILTER,
  SUBSCRIBERS_FILTER,
  TEACHER_BREADCRUMB_FILTER,
  TEACHER_CATEGORYID_FILTER,
  SUBJECT_BREADCRUMB_FILTER,
  SUBJECT_CATEGORYID_FILTER,
  PAGE_NUMBER_FILTER,
  PAGE_SIZE_FILTER,
  SEARCH_VALUE_FILTER,
  SORTING_SUBJECTS,
  SORTING_TEACHERS,
  SUBSCRIBER_FILTER_VALUES,
} from "constants/TablesFilters";

const initialState: TablesFiltersType = {
  isSectionsSorted: null,
  setIsSectionsSorted: () => null,
  worningSectionsSortPopup: null,
  setWorningSectionsSortPopup: () => null,
  worningSortType: null,
  setWorningSortType: () => null,
  subjectTitleHeight: null,
  setSubjectTitleHeight: () => null,
  sortingTeachers: null,
  setSortingTeachers: () => null,
  sortingSubjects: null,
  setSortingSubjects: () => null,
  openedFilters: null,
  setOpenedFilters: () => null,
  searchValueFilter: null,
  setSearchValueFilter: () => null,
  teacherBreadcrumbFilter: null,
  setTeacherBreadcrumbFilter: () => null,
  teacherCategoryIdFilter: null,
  setTeacherCategoryIdFilter: () => null,
  subscriberFiltersValues: null,
  setSubscriberFiltersValues: () => null,
  currenciesFilter: null,
  setCurrenciesFilter: () => null,
  pageSizeFilter: null,
  setPageSizeFilter: () => null,
  pageNumberFilter: null,
  setPageNumberFilter: () => null,
  subjectBreadcrumbFilter: null,
  setSubjectBreadcrumbFilter: () => null,
  subjectCategoryIdFilter: null,
  setSubjectCategoryIdFilter: () => null,
};

export const TablesFiltersContext = createContext<TablesFiltersType>(initialState);
export const useTablesFilters = () => useContext(TablesFiltersContext);

type Props = {
  children?: React.PropsWithChildren<any>;
};

export default function TablesFiltersProvider({ children }: Props): ReactElement {
  // SORTING POPUPS
  const [isSectionsSorted, setIsSectionsSorted] = useState(false);
  const [worningSectionsSortPopup, setWorningSectionsSortPopup] = useState(false);
  const [worningSortType, setWorningSortType] = useState(null);
  const [subjectTitleHeight, setSubjectTitleHeight] = useState(false);

  // TABLES SORTING
  const [sortingTeachers, setSortingTeachers] = useState<SortingState>(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SORTING_TEACHERS);
      return localData !== null ? JSON.parse(localData) : [{ id: "display_name", desc: false }];
    }
  });

  const [sortingSubjects, setSortingSubjects] = useState<SortingState>(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SORTING_SUBJECTS);
      return localData !== null ? JSON.parse(localData) : [{ id: "name", desc: false }];
    }
  });

  // TEACHERS TABLE FILTERS
  const [openedFilters, setOpenedFilters] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(OPENED_FILTERS);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [searchValueFilter, setSearchValueFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SEARCH_VALUE_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [teacherBreadcrumbFilter, setTeacherBreadcrumbFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(TEACHER_BREADCRUMB_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [teacherCategoryIdFilter, setTeacherCategoryIdFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(TEACHER_CATEGORYID_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [subscriberFiltersValues, setSubscriberFiltersValues] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SUBSCRIBERS_FILTER);
      return localData !== null ? JSON.parse(localData) : [];
    }
  });

  const [currenciesFilter, setCurrenciesFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(CURRENCIES_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [pageSizeFilter, setPageSizeFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(PAGE_SIZE_FILTER);
      return localData !== null ? JSON.parse(localData) : 10;
    }
  });

  const [pageNumberFilter, setPageNumberFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(PAGE_NUMBER_FILTER);
      return localData !== null ? JSON.parse(localData) : 1;
    }
  });

  // SUBJECTS TABLE FILTER
  const [subjectBreadcrumbFilter, setSubjectBreadcrumbFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SUBJECT_BREADCRUMB_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  const [subjectCategoryIdFilter, setSubjectCategoryIdFilter] = useState(() => {
    if (typeof window !== "undefined") {
      const localData = window.sessionStorage.getItem(SUBJECT_CATEGORYID_FILTER);
      return localData !== null ? JSON.parse(localData) : "";
    }
  });

  // TABLES SORTING
  useEffect(() => {
    window.sessionStorage.setItem(SORTING_TEACHERS, JSON.stringify(sortingTeachers));
  }, [sortingTeachers]);

  useEffect(() => {
    window.sessionStorage.setItem(SORTING_SUBJECTS, JSON.stringify(sortingSubjects));
  }, [sortingSubjects]);

  // TEACHERS TABLE FILTERS
  useEffect(() => {
    window.sessionStorage.setItem(OPENED_FILTERS, JSON.stringify(openedFilters));
  }, [openedFilters]);

  useEffect(() => {
    window.sessionStorage.setItem(SEARCH_VALUE_FILTER, JSON.stringify(searchValueFilter));
  }, [searchValueFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(TEACHER_BREADCRUMB_FILTER, JSON.stringify(teacherBreadcrumbFilter));
  }, [teacherBreadcrumbFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(TEACHER_CATEGORYID_FILTER, JSON.stringify(teacherCategoryIdFilter));
  }, [teacherCategoryIdFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(SUBSCRIBERS_FILTER, JSON.stringify(subscriberFiltersValues));
  }, [subscriberFiltersValues]);

  useEffect(() => {
    window.sessionStorage.setItem(CURRENCIES_FILTER, JSON.stringify(currenciesFilter));
  }, [currenciesFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(PAGE_SIZE_FILTER, JSON.stringify(pageSizeFilter));
  }, [pageSizeFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(PAGE_NUMBER_FILTER, JSON.stringify(pageNumberFilter));
  }, [pageNumberFilter]);

  // SUBJECTS TABLE FILTER
  useEffect(() => {
    window.sessionStorage.setItem(SUBJECT_BREADCRUMB_FILTER, JSON.stringify(subjectBreadcrumbFilter));
  }, [subjectBreadcrumbFilter]);

  useEffect(() => {
    window.sessionStorage.setItem(SUBJECT_CATEGORYID_FILTER, JSON.stringify(subjectCategoryIdFilter));
  }, [subjectCategoryIdFilter]);

  return (
    <TablesFiltersContext.Provider
      value={{
        isSectionsSorted,
        setIsSectionsSorted,
        worningSectionsSortPopup,
        setWorningSectionsSortPopup,
        worningSortType,
        setWorningSortType,
        subjectTitleHeight,
        setSubjectTitleHeight,
        sortingTeachers,
        setSortingTeachers,
        sortingSubjects,
        setSortingSubjects,
        openedFilters,
        setOpenedFilters,
        searchValueFilter,
        setSearchValueFilter,
        teacherBreadcrumbFilter,
        setTeacherBreadcrumbFilter,
        teacherCategoryIdFilter,
        setTeacherCategoryIdFilter,
        subscriberFiltersValues,
        setSubscriberFiltersValues,
        currenciesFilter,
        setCurrenciesFilter,
        pageSizeFilter,
        setPageSizeFilter,
        pageNumberFilter,
        setPageNumberFilter,
        subjectBreadcrumbFilter,
        setSubjectBreadcrumbFilter,
        subjectCategoryIdFilter,
        setSubjectCategoryIdFilter,
      }}
    >
      {children}
    </TablesFiltersContext.Provider>
  );
}
