import styled, { css } from "styled-components";

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
`;

export const ListNumbersContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Showing = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
`;

export const SetectStyled = styled.div<{ readonly opened?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 4px 2px 8px;
  ${({ opened }) =>
    opened
      ? css`
          border: 1px solid ${({ theme }) => theme.colors.seagule};
        `
      : css`
          border: 1px solid ${({ theme }) => theme.colors.gray4};
        `}
  border-radius: 6px;
  cursor: pointer;
`;

export const ListNumberArrowWrapper = styled.div<{ readonly isOpen?: boolean }>`
  width: 24px;
  text-align: center;
  transition: all 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? " rotateX(-180deg) translateY(0px)" : " rotateX(0deg) translateY(-2px)")};
`;

export const ListNumberMenu = styled.div<{ readonly listNumber?: boolean }>`
  position: absolute;
  left: 28%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  width: 135px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  overflow-y: auto;
  transition: all 0.2s ease;
  z-index: 88;
  ${({ listNumber }) =>
    listNumber
      ? css`
          opacity: 1;
          visibility: visible;
          top: 120%;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          top: 100%;
        `}
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PageSizeNumber = styled.span<{ readonly isSelected?: boolean }>`
  padding: 8px;
  width: 100%;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  cursor: pointer;
  &:hover {
    width: 100%;
    background: ${({ theme }) => theme.colors.gray6};
    border-radius: 8px;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.seagule};
      &:hover {
        width: 100%;
        color: ${({ theme }) => theme.colors.nero};
        background: ${({ theme }) => theme.colors.gray6};
        border-radius: 8px;
      }
    `}
`;

export const PaginateControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .Mui-selected {
    background: ${({ theme }) => theme.colors.seagule} !important;
    color: ${({ theme }) => theme.colors.white} !important;
    font-weight: ${({ theme }) => theme.fontWeights.font500};
    font-size: ${({ theme }) => theme.fontSizes.body14};
    line-height: 24px;
    border: none;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px;
  }
  .MuiButtonBase-root {
    background: transparent;
    color: ${({ theme }) => theme.colors.gray1};
    font-weight: ${({ theme }) => theme.fontWeights.font500};
    font-size: ${({ theme }) => theme.fontSizes.body14};
    line-height: 24px;
    border: none;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 4px;
    cursor: pointer;
    padding: 0px;
  }
  .MuiButtonBase-root:hover {
    background: ${({ theme }) => theme.colors.gray6};
  }
  .Mui-selected:hover {
    background: ${({ theme }) => theme.colors.seagule};
    color: ${({ theme }) => theme.colors.white};
  }
  .MuiPagination-ul {
    & > li:first-child {
      background-color: transparent;
      border: none;
      .MuiPaginationItem-icon {
        color: ${({ theme }) => theme.colors.gray2};
        font-weight: ${({ theme }) => theme.fontWeights.font500};
        font-size: ${({ theme }) => theme.fontSizes.body16};
        line-height: 24px;
        &:hover {
          color: ${({ theme }) => theme.colors.nero};
          background: transparent;
        }
      }
      .MuiPaginationItem-root.Mui-disabled {
        opacity: 1;
      }
      .MuiPaginationItem-root:hover {
        background: transparent;
      }
    }
    & > li:last-child {
      background-color: transparent;
      border: none;
      .MuiPaginationItem-icon {
        color: ${({ theme }) => theme.colors.gray2};
        font-weight: ${({ theme }) => theme.fontWeights.font500};
        font-size: ${({ theme }) => theme.fontSizes.body16};
        line-height: 24px;
        &:hover {
          color: ${({ theme }) => theme.colors.nero};
          background: transparent;
        }
      }
      .MuiPaginationItem-root.Mui-disabled {
        opacity: 1;
      }
      .MuiPaginationItem-root:hover {
        background: transparent;
      }
    }
  }
`;
