import React from "react";

const WaitToPlayIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.25781 10.4222L1.67188 3.83626V12.3519C1.67188 12.6904 1.75521 12.9613 1.92188 13.1644C2.09375 13.3727 2.32292 13.4769 2.60938 13.4769C2.76562 13.4769 2.91406 13.4482 3.05469 13.391C3.19531 13.3389 3.34375 13.266 3.5 13.1722L8.25781 10.4222ZM11.0156 8.82845L12.6094 7.90658C12.9375 7.71387 13.1641 7.5446 13.2891 7.39876C13.4193 7.24772 13.4844 7.06803 13.4844 6.8597C13.4844 6.65137 13.4193 6.47428 13.2891 6.32845C13.1641 6.17741 12.9375 6.00814 12.6094 5.82064L3.5 0.547201C3.35938 0.463867 3.22656 0.396159 3.10156 0.344076C2.98177 0.286784 2.85417 0.258138 2.71875 0.258138C2.68229 0.258138 2.65104 0.273763 2.625 0.305013C2.60417 0.331055 2.59115 0.364909 2.58594 0.406576L11.0156 8.82845ZM11.7344 12.5706C11.8542 12.6904 11.9974 12.7503 12.1641 12.7503C12.3307 12.7503 12.4714 12.6904 12.5859 12.5706C12.7005 12.4508 12.7578 12.3102 12.7578 12.1488C12.763 11.9821 12.7057 11.8389 12.5859 11.7191L1.03125 0.172201C0.911458 0.0576172 0.768229 0.000325521 0.601562 0.000325521C0.434896 -0.00488281 0.291667 0.0524089 0.171875 0.172201C0.0572917 0.286784 0 0.430013 0 0.601888C0 0.768555 0.0572917 0.90918 0.171875 1.02376L11.7344 12.5706Z"
        fill="white"
      />
    </svg>
  );
};

export default WaitToPlayIcon;
