import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import PopupsLayout from "@Components/PopupsLayout";
import { ErrorType } from "@Components/PopupsLayout/PopupsLayout.styled";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UploadIcon from "images/icons/UploadIcon";
import { Fragment } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import * as tus from "tus-js-client";
import {
  DashboardContainer,
  CenterDiv,
  MessageContainer,
  VideoProgressMessage,
  VideoProgressContainer,
  Place,
  StyledImage,
  StyledProgress,
  StyledPTag,
  StyledText,
  UploadedMessage,
  UploadedTitle,
  UploadTab,
  UploadTabsWrapper,
  UploadVideoContainer,
  UploadVideoInput,
} from "../AddLecturePopup/AddLecturePopup.styled";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import WaitToPlayIcon from "images/icons/WaitToPlayIcon";
import { _CreateIntroduction } from "@Services/Lecture";
import { videoFileTypes } from "constants/Types";
import { useTranslation } from "react-i18next";
import CheckIcon from "images/icons/CheckIcon";
import { Lecture } from "types/Lecture";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
}

const AddIntroductionPopup = ({ isOpen, closePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const { userData } = userInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(true);
  const [selectedTab, setSelectedTab] = useState(1);
  const [introductionSection, setIntroductionSection] = useState([]);
  const { courseId, courseDetails, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);
  // VIDEO STATES
  const [videoFile, setVideoFile] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [vemioVideoId, setVimeoVideoId] = useState("");
  const [urlImage, setUrlImage] = useState<any>();
  const [persantage, setPersantage] = useState("0%");
  const [videoUploadError, setVideoUploadError] = useState("");
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<Lecture>();

  useEffect(() => {
    if (courseDetails) {
      setIntroductionSection(courseDetails?.sections.filter((section) => section?.is_introductory === true));
    }
  }, [courseDetails]);

  const generateVideoThumbnail = (file: File) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);
      video.onloadeddata = () => {
        const ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  const videoUploadhandler = async (eventObject) => {
    const file = eventObject;
    setVideoFile(file);
    const fileSize = file.size.toString();
    const response = await axios({
      method: "post",
      url: `https://api.vimeo.com/me/videos`,
      headers: {
        Accept: "application/vnd.vimeo.*+json;version=3.4",
        Authorization: `bearer ${process.env.REACT_APP_VIMEO_GLOBAL_ACCESS_TOKEN}`,
        "Content-Type": "application/json",
      },
      data: {
        upload: {
          approach: "tus",
          size: fileSize,
        },
      },
    });
    setVideoLink(response?.data.link.split("/"));
    const upload = new tus.Upload(file, {
      endpoint: "https://api.vimeo.com/me/videos",
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      headers: {},
      onError: function (error) {
        setVideoUploadError("Failed because: " + error);
        setDisabledSubmitBtn(true);
      },
      onProgress: function (bytesUploaded, bytesTotal) {
        const percentage = Math.round((bytesUploaded / bytesTotal) * 100);
        setPersantage(percentage + "%");
      },
      onSuccess: function () {
        setVideoUploadError("");
        setDisabledSubmitBtn(false);
      },
    });
    upload.start();
    generateVideoThumbnail(file).then((res) => setUrlImage(res));
  };

  useEffect(() => {
    if (videoLink) {
      setVimeoVideoId(videoLink[videoLink.length - 1]);
    }
  }, [videoLink]);

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
      setSelectedTab(1);
      setVideoFile(null);
      setVideoLink("");
      setVimeoVideoId("");
      setUrlImage("");
      setPersantage("0%");
      setVideoUploadError("");
      setDisabledSubmitBtn(true);
      reset();
    }
  }, [isOpen]);

  const submitForm = (data: Lecture) => {
    setIsLoading(true);
    _CreateIntroduction(
      introductionSection[0]?.lectures[0]?.id,
      {
        ...data,
        video_id: Number(watch("video_id")) || Number(vemioVideoId),
        section_id: introductionSection && introductionSection[0]?.id,
        course_id: courseId,
        is_video_changed: false,
      },
      teacherId
    )
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("edit_introduction")}
          buttonTitle={tCommon("save_changes")}
          buttonState={disabledSubmitBtn}
          noteMessage={tCommon("intro_note_message")}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            {introductionSection && (
              <Fragment>
                <InputsWrapper>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("select_lecture_type")}</InputLabel>
                    <Select
                      disabled
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      value="video"
                      MenuProps={MenuProps}
                    >
                      <MenuItem value="video">{tCommon("video")}</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                    <Controller
                      name="vimeo_account"
                      control={control}
                      defaultValue={"global"}
                      render={({ field }) => (
                        <Select
                          {...field}
                          id="demo-simple-select"
                          IconComponent={ExpandMoreIcon}
                          MenuProps={MenuProps}
                          disabled={Boolean(selectedTab === 1 || isRegularInstructor)}
                          {...register("vimeo_account")}
                        >
                          <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                          <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </InputsWrapper>
                <UploadVideoContainer>
                  <UploadTabsWrapper>
                    <UploadTab onClick={() => setSelectedTab(1)} isActiveTab={selectedTab === 1}>
                      {tCommon("direct_upload")}
                    </UploadTab>
                    <UploadTab onClick={() => setSelectedTab(2)} isActiveTab={selectedTab === 2}>
                      {tCommon("vimeo_id")}
                    </UploadTab>
                  </UploadTabsWrapper>
                  {selectedTab === 1 && (
                    <DashboardContainer isVideo>
                      <div className="container">
                        {videoFile === null ? (
                          <>
                            {isRegularInstructor ? (
                              <UploadVideoInput style={{ cursor: "not-allowed" }}>
                                <CenterDiv>
                                  <UploadIcon />
                                  <StyledPTag>{tCommon("drag_and_drop")}</StyledPTag>
                                  <StyledPTag>{tCommon("video_types")}</StyledPTag>
                                </CenterDiv>
                              </UploadVideoInput>
                            ) : (
                              <UploadVideoInput>
                                <CenterDiv>
                                  <UploadIcon />
                                  <StyledPTag>{tCommon("drag_and_drop")}</StyledPTag>
                                  <StyledPTag>{tCommon("video_types")}</StyledPTag>
                                </CenterDiv>
                                <FileUploader handleChange={videoUploadhandler} name="file" types={videoFileTypes} />
                              </UploadVideoInput>
                            )}
                          </>
                        ) : (
                          <Fragment>
                            {persantage !== "100%" ? (
                              <VideoProgressContainer>
                                {urlImage ? <StyledImage src={urlImage} alt="" /> : <Place />}
                                <VideoProgressMessage>
                                  <StyledText>{tCommon("uploading_video")}</StyledText>
                                  <StyledProgress persantage={persantage}>
                                    <span />
                                  </StyledProgress>
                                  <StyledText>{persantage}</StyledText>
                                  <MessageContainer>
                                    <WaitToPlayIcon />
                                    <StyledText>{tCommon("video_upload_message")}</StyledText>
                                  </MessageContainer>
                                </VideoProgressMessage>
                              </VideoProgressContainer>
                            ) : (
                              <VideoProgressContainer>
                                {urlImage ? <StyledImage src={urlImage} alt="" /> : <Place />}
                                <VideoProgressMessage>
                                  <CheckIcon />
                                  <UploadedTitle>{tCommon("video_uploaded_title")}</UploadedTitle>
                                  <UploadedMessage>{tCommon("video_uploaded_message")}</UploadedMessage>
                                </VideoProgressMessage>
                              </VideoProgressContainer>
                            )}
                          </Fragment>
                        )}
                        {videoUploadError && <ErrorType center>{videoUploadError}</ErrorType>}
                      </div>
                    </DashboardContainer>
                  )}
                  {selectedTab === 2 && (
                    <DashboardContainer isVideo>
                      <Input
                        id="filled-basic"
                        variant="filled"
                        type="number"
                        className="noArrows"
                        disabled={isRegularInstructor}
                        label={tCommon("vimeo_video_id")}
                        {...register("video_id", {
                          required: {
                            value: selectedTab === 2,
                            message: tCommon("required_message"),
                          },
                          valueAsNumber: true,
                        })}
                        onChange={() => setDisabledSubmitBtn(false)}
                        error={Boolean(errors.video_id)}
                        helperText={errors.video_id && errors.video_id?.message}
                      />
                    </DashboardContainer>
                  )}
                </UploadVideoContainer>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label">{tCommon("selecting_section")}</InputLabel>
                  <Select
                    disabled
                    id="demo-simple-select"
                    IconComponent={ExpandMoreIcon}
                    value="introduction"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="introduction">{tCommon("introduction")}</MenuItem>
                  </Select>
                </FormControl>
              </Fragment>
            )}
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddIntroductionPopup;
