import ContentLoader from "react-content-loader";

export default function ProfileSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="750px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="20" rx="8" ry="8" width="100%" height="100px" />
      <rect x="0" y="140" rx="8" ry="8" width="49%" height="100px" />
      <rect x="51%" y="140" rx="8" ry="8" width="50%" height="100px" />
      <rect x="0" y="260" rx="8" ry="8" width="49%" height="100px" />
      <rect x="51%" y="260" rx="8" ry="8" width="50%" height="100px" />
      <rect x="0" y="380" rx="8" ry="8" width="49%" height="100px" />
      <rect x="51%" y="380" rx="8" ry="8" width="50%" height="100px" />
    </ContentLoader>
  );
}
