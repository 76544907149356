import { configureAxiosLocale } from "@Configs/Axios";
import { ArrowDownIcon } from "images/icons/ArrowDownIcon";
import { LogoutIcon } from "images/icons/LogoutIcon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { logout } from "utils/logout";
import {
  ArrowWrapper,
  MenuUl,
  LangMenu,
  LangName,
  MenulogoutWrapper,
  LiName,
  MenuLi,
} from "../LanguageMenu/LanguageMenu.styled";
import { ProfileLogoutMenu } from "./TeacherLangMenu.styled";

type Props = {
  openLogoutMenu: boolean;
  setOpenLogoutMenu: Dispatch<SetStateAction<boolean>>;
  openLangMenu: boolean;
  setOpenLangMenu: Dispatch<SetStateAction<boolean>>;
};

const TeacherLangMenu = ({ openLogoutMenu, setOpenLogoutMenu, openLangMenu, setOpenLangMenu }: Props) => {
  const [selectedLang, setSelectedLang] = useState("");
  const {
    t: tCommon,
    i18n: { language, changeLanguage },
  } = useTranslation("common");

  useEffect(() => {
    if (language === "en") {
      setSelectedLang("English");
    } else if (language === "ar") {
      setSelectedLang("العربیة");
    } else if (language === "tr") {
      setSelectedLang("Türkçe");
    }
  }, [language]);

  const exitDashHandler = () => {
    window.location.href = `${process.env.REACT_APP_BAIMS_URL}/home`;
  };

  return (
    <ProfileLogoutMenu isOpen={openLogoutMenu}>
      <MenuUl>
        <MenuLi onClick={() => setOpenLangMenu(!openLangMenu)}>
          <LiName>{selectedLang}</LiName>
          <ArrowWrapper isOpen={openLangMenu}>
            <ArrowDownIcon />
          </ArrowWrapper>
          <LangMenu isProfile isOpen={openLangMenu}>
            <LangName
              isSelected={selectedLang === "English"}
              onClick={() => {
                setSelectedLang("English");
                changeLanguage("en");
                configureAxiosLocale("en");
                setOpenLogoutMenu(false);
                setOpenLangMenu(false);
              }}
            >
              {tCommon("english")}
            </LangName>
            <LangName
              isSelected={selectedLang === "العربیة"}
              onClick={() => {
                setSelectedLang("العربیه");
                changeLanguage("ar");
                configureAxiosLocale("ar");
                setOpenLogoutMenu(false);
                setOpenLangMenu(false);
              }}
            >
              {tCommon("arabic")}
            </LangName>
            <LangName
              isSelected={selectedLang === "Türkçe"}
              onClick={() => {
                setSelectedLang("Türkçe");
                changeLanguage("tr");
                configureAxiosLocale("tr");
                setOpenLogoutMenu(false);
                setOpenLangMenu(false);
              }}
            >
              {tCommon("turkey")}
            </LangName>
          </LangMenu>
        </MenuLi>
        <MenuLi
          onClick={() => {
            setOpenLogoutMenu(false);
            exitDashHandler();
          }}
        >
          {tCommon("exit_dashboard")}
        </MenuLi>
        <MenulogoutWrapper
          onClick={() => {
            setOpenLogoutMenu(false);
            logout();
          }}
        >
          <LiName>{tCommon("logout")}</LiName>
          <LogoutIcon />
        </MenulogoutWrapper>
      </MenuUl>
    </ProfileLogoutMenu>
  );
};

export default TeacherLangMenu;
