export function calculateDiscount(price, finalPrice) {
  if (!finalPrice || isNaN(price)) {
    return 0;
  } else {
    const discountRate = ((price - finalPrice) / price) * 100;
    return Math.round(discountRate);
  }
}

export function removeEmptyProperties(obj) {
  const newObj = {};
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === undefined || value === "" || Number.isNaN(value)) {
      continue;
    }
    if (typeof value === "object") {
      const subObj = removeEmptyProperties(value);
      if (Object.keys(subObj).length !== 0) {
        newObj[key] = subObj;
      }
    } else {
      newObj[key] = value;
    }
  }
  return newObj;
}
