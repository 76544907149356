import React from "react";

const RatingStarYellow = () => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.64687 16.6064C3.80833 16.7314 3.99062 16.7731 4.19375 16.7314C4.39687 16.695 4.61823 16.5908 4.85781 16.4189L8.99062 13.3799L13.1312 16.4189C13.3708 16.5908 13.5922 16.695 13.7953 16.7314C13.9984 16.7731 14.1807 16.7314 14.3422 16.6064C14.5036 16.4867 14.6 16.3252 14.6312 16.1221C14.6677 15.9189 14.6365 15.6768 14.5375 15.3955L12.9047 10.5361L17.0766 7.53613C17.3214 7.36426 17.4906 7.18717 17.5844 7.00488C17.6781 6.82259 17.6937 6.63509 17.6312 6.44238C17.5687 6.25488 17.4464 6.11426 17.2641 6.02051C17.0818 5.92676 16.8396 5.88249 16.5375 5.88769L11.4203 5.91894L9.86562 1.03613C9.77187 0.749674 9.65208 0.533529 9.50625 0.387695C9.36562 0.241862 9.19375 0.168945 8.99062 0.168945C8.79271 0.168945 8.62083 0.241862 8.475 0.387695C8.33437 0.533529 8.21719 0.749674 8.12344 1.03613L6.56875 5.91894L1.45156 5.88769C1.14948 5.88249 0.907292 5.92676 0.725 6.02051C0.542708 6.11426 0.420312 6.25488 0.357812 6.44238C0.295313 6.63509 0.310938 6.82259 0.404687 7.00488C0.498437 7.18717 0.667708 7.36426 0.9125 7.53613L5.08437 10.5361L3.45156 15.3955C3.3526 15.6768 3.31875 15.9189 3.35 16.1221C3.38646 16.3252 3.48542 16.4867 3.64687 16.6064Z"
        fill="#FEC76C"
      />
    </svg>
  );
};

export default RatingStarYellow;
