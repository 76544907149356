import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { TeachersDatasContext } from "context/Teachers.context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PopupsLayout from "@Components/PopupsLayout";
import { useForm, Controller } from "react-hook-form";
import { _CreateSection } from "@Services/Sections";
import { Section } from "types/Sections";
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FormContainer, FormWrapper, Input, InputsWrapper, MenuProps, PopupContainer } from "@Components/common/Forms";
import MultibleLanguagesInputs from "@Components/MultibleLanguagesInputs";

interface Props {
  isOpen: boolean;
  closePopup: Dispatch<SetStateAction<boolean>>;
  openAlertPopup: Dispatch<SetStateAction<boolean>>;
}

const AddSectionPopup = ({ isOpen, closePopup, openAlertPopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [submitValidation, setSubmitValidation] = useState("");
  const { courseId, refetchCourseDetails, teacherId } = useContext(TeachersDatasContext);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { isDirty, errors },
  } = useForm<Section>();

  useEffect(() => {
    if (!isOpen) {
      setSubmitValidation("");
      reset();
    }
  }, [isOpen]);

  const submitForm = (data: Section) => {
    setIsLoading(true);
    _CreateSection({ ...data, course_id: courseId }, teacherId)
      .then(() => {
        closePopup(false);
        refetchCourseDetails();
        if (!watch("vimeo_folder_id")) {
          openAlertPopup(true);
        }
      })
      .catch((err) => setSubmitValidation(err?.response.data.message))
      .finally(() => setIsLoading(false));
  };

  return (
    <PopupContainer>
      <FormContainer onSubmit={handleSubmit(submitForm)}>
        <PopupsLayout
          isOpen={isOpen}
          title={tCommon("add_new_section")}
          buttonTitle={tCommon("add_section")}
          buttonState={!isDirty}
          closePopup={closePopup}
          errors={submitValidation}
          isBtnLoading={isLoading}
        >
          <FormWrapper>
            <InputsWrapper>
              <Input
                id="filled-basic"
                variant="filled"
                type="number"
                className="noArrows"
                label={tCommon("vimeo_folder_id")}
                {...register("vimeo_folder_id", {
                  valueAsNumber: true,
                })}
              />
              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-filled-label">{tCommon("vimeo_account_type")}</InputLabel>
                <Controller
                  name="vimeo_account"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="demo-simple-select"
                      IconComponent={ExpandMoreIcon}
                      MenuProps={MenuProps}
                      {...register("vimeo_account")}
                    >
                      <MenuItem value="global">{tCommon("global_account")}</MenuItem>
                      <MenuItem value="turkey">{tCommon("turkey_account")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </InputsWrapper>
            <MultibleLanguagesInputs isSection isOpen={isOpen} errors={errors} register={register} />
          </FormWrapper>
        </PopupsLayout>
      </FormContainer>
    </PopupContainer>
  );
};

export default AddSectionPopup;
