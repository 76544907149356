import { CommonButton } from "@Components/common/CommonButton";
import styled from "styled-components";
import { css } from "styled-components";

export const WorningPopupContainer = styled.div<{ readonly opened?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  transition: all 0.3s ease;
  z-index: 99;
`;

export const WorningPopupWrapper = styled.div<{
  readonly opened?: boolean;
  readonly position?: string;
  readonly subjectTitleHeight?: boolean;
}>`
  position: fixed;
  ${({ position, subjectTitleHeight }) =>
    position === "courses"
      ? css`
          top: ${subjectTitleHeight ? "140px" : "108px"};
          left: 380px;
        `
      : css`
          top: 280px;
          right: 45px;
        `}
  width: 382px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  visibility: ${({ opened }) => (opened ? "visible" : "hidden")};
  opacity: ${({ opened }) => (opened ? 1 : 0)};
  transition: all 0.3s ease;
  padding: 24px;
  z-index: 100;
  &:after {
    content: "";
    position: absolute;
    ${({ position }) =>
      position === "courses"
        ? css`
            top: 60px;
            left: -7px;
          `
        : css`
            top: -7px;
            right: 32px;
          `}

    width: 16px;
    height: 16px;
    border-radius: 3px;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const WorningPopupTitle = styled.h4`
  margin-bottom: 12px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const WorningPopupMessage = styled.p`
  margin-bottom: 28px;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const WorningPopupButton = styled(CommonButton)`
  width: 100%;
`;
