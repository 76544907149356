export const ArrowDownIcon = ({ color }: { color?: boolean }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00026 7.18335C6.08273 7.18335 6.15868 7.16599 6.22813 7.13127C6.30191 7.10088 6.36919 7.05531 6.42995 6.99455L11.482 1.8383C11.5949 1.72111 11.6513 1.57788 11.6513 1.40861C11.6513 1.3001 11.6253 1.20028 11.5732 1.10913C11.5211 1.01799 11.4495 0.94637 11.3583 0.894287C11.2715 0.842204 11.1717 0.816162 11.0589 0.816162C10.8983 0.816162 10.7572 0.872586 10.6357 0.985433L6.00026 5.72502L1.36485 0.985433C1.24332 0.872586 1.10226 0.816162 0.941671 0.816162C0.828824 0.816162 0.728997 0.842204 0.642192 0.894287C0.551046 0.94637 0.479431 1.01799 0.427348 1.10913C0.375265 1.20028 0.349223 1.3001 0.349223 1.40861C0.349223 1.57788 0.407817 1.72111 0.525004 1.8383L5.57058 6.99455C5.63134 7.05531 5.69644 7.10088 5.76589 7.13127C5.83967 7.16599 5.9178 7.18335 6.00026 7.18335Z"
        fill={color ? "#ABABAB" : "#454545"}
      />
    </svg>
  );
};
