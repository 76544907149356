import { CommonButton } from "@Components/common/CommonButton";
import Rodal from "rodal";
import styled from "styled-components";

export const StyledAlertRodal = styled(Rodal)<{
  readonly isVimeoFolder?: boolean;
  readonly isVimeoVideoReady?: boolean;
}>`
  .rodal-mask {
    background: rgba(0, 0, 0, 0.3);
  }
  .rodal-close {
    display: none;
  }
  .rodal-dialog {
    min-width: ${({ isVimeoFolder, isVimeoVideoReady }) =>
      isVimeoFolder ? "360px" : isVimeoVideoReady ? "382px" : "372px"};
    max-width: ${({ isVimeoFolder, isVimeoVideoReady }) =>
      isVimeoFolder ? "360px" : isVimeoVideoReady ? "382px" : "372px"};
    min-height: ${({ isVimeoFolder, isVimeoVideoReady }) =>
      isVimeoFolder ? "210px" : isVimeoVideoReady ? "215px" : "274px"};
    max-height: ${({ isVimeoFolder, isVimeoVideoReady }) =>
      isVimeoFolder ? "210px" : isVimeoVideoReady ? "215px" : "274px"};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 20px;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const AlertPopupHeader = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #1a1a1a;
  margin-bottom: 12px;
`;

export const AlertPopupMessage = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #8e8e8e;
  margin-bottom: 28px;
  text-align: center;
`;

export const AlertPopupButtons = styled.div<{ readonly isVimeoVideoReady?: boolean }>`
  width: ${({ isVimeoVideoReady }) => (isVimeoVideoReady ? "100%" : "132px")};
`;

export const AlertButton = styled(CommonButton)`
  padding: 8px 0px;
`;
