import ContentLoader from "react-content-loader";

export default function RegularHeaderSkeleton() {
  return (
    <ContentLoader speed={2} width={"100%"} height="80px" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="4" ry="4" width="10%" height="20px" />
      <rect x={"89%"} y="0" rx="4" ry="4" width="10%" height="35px" />
      <rect x="0" y="50" rx="4" ry="4" width="20%" height="10px" />
    </ContentLoader>
  );
}
