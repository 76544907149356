import ContentLoader from "react-content-loader";

export default function TitleSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={"100%"}
      height="90px"
      backgroundColor="rgba(255, 255, 255, 0.1)"
      foregroundColor="rgba(255, 255, 255, 0.15)"
    >
      <rect x="0" y="0" rx="4" ry="4" width="100%" height="15px" />
      <rect x="0" y="30px" rx="4" ry="4" width="80%" height="10px" />
    </ContentLoader>
  );
}
