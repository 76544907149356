import styled from "styled-components";

export const AdminSidebar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 76px;
  min-width: 76px;
  background: linear-gradient(191.41deg, #0095ff 0%, #126b8d 52.08%, #093c50 96.35%);
  z-index: 30;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.75);
    backdrop-filter: blur(22.5px);
    border-right: 1px solid ${({ theme }) => theme.colors.light_white};
  }
`;

export const AdminSideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 28.6px 16.6px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 10;
`;

export const TopContent = styled.div``;

export const LogoWrapper = styled.div`
  width: 42.79px;
  height: 42.79px;
  margin-bottom: 32.61px;
  cursor: pointer;
`;

export const Line = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light_white};
`;

export const ListIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.14) 100%);
  border-radius: 8px;
  cursor: pointer;
`;

export const AdminImg = styled.img`
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.light_white};
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
`;
