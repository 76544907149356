import styled from "styled-components";

export const OverviewPriceContainer = styled.div``;

export const OverviewPriceTitle = styled.h4`
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const OverviewPriceDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray5_old};
  border-radius: 8px;
`;

export const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const PriceEdit = styled.div`
  display: flex;
  gap: 16px;
`;

export const TotalPrice = styled.span``;

export const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const PriceEditContainer = styled(PriceDetails)``;

export const FullSubject = styled.span``;

export const NewPrice = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body18};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.nero};
  text-transform: uppercase;
`;

export const OldPrice = styled(NewPrice)`
  color: ${({ theme }) => theme.colors.gray3};
  text-decoration-line: line-through;
  text-transform: uppercase;
`;

export const Discount = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const PriceEditButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 52px;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 6px;
  cursor: pointer;
`;

export const PriceEditButton = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.seagule};
`;
