import {
  AddButton,
  BioDescription,
  BioTitle,
  ButtonText,
  ProfileBio,
  ProfileContentContainer,
} from "./TeacherProfile.styled";
import { Dispatch, SetStateAction } from "react";
import { BigPlusIcon } from "images/icons/BigPlusIcon";

type Props = {
  title: string;
  basic: string;
  optional?: string;
  openPopup: Dispatch<SetStateAction<boolean>>;
  capitalize?: boolean;
};

const TeacherProfile = ({ title, basic, optional, openPopup, capitalize }: Props) => {
  return (
    <ProfileContentContainer>
      <ProfileBio>
        <BioTitle>{title}</BioTitle>
        {basic ? (
          <BioDescription capitalize={capitalize}>{basic}</BioDescription>
        ) : (
          <AddButton onClick={() => openPopup(true)}>
            <BigPlusIcon />
            <ButtonText>Add</ButtonText>
          </AddButton>
        )}
        {optional && <BioDescription>{optional}</BioDescription>}
      </ProfileBio>
    </ProfileContentContainer>
  );
};

export default TeacherProfile;
