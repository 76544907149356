import { CommonButton } from "@Components/common/CommonButton";
import styled from "styled-components";

export const DeletePopupWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 382px;
  height: 246px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  z-index: 202;
`;

export const DeletePopupHeader = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h5};
  line-height: 40px;
  color: ${({ theme }) => theme.colors.nero};
  margin-bottom: 12px;
`;

export const DeletePopupAlert = styled.p<{ readonly hasError?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
  margin-bottom: ${({ hasError }) => (hasError ? "0px" : "28px")};
  text-align: center;
`;

export const DeletePopupButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CancleButton = styled(CommonButton)`
  flex: 1;
  width: 153px;
  height: 46px;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 8px;
  border: none;
  color: ${({ theme }) => theme.colors.seagule};
  cursor: pointer;
`;

export const DeleteButton = styled(CancleButton)`
  background: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.white};
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.red};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.gray8};
  }
`;
