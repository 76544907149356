import { _GetTeacherProfile } from "@Services/Teachers";
import { Dispatch, SetStateAction } from "react";
import AdminSideBar from "./components/AdminSideBar";
import CoursesSideBar from "./components/CoursesSideBar";
import MainSideBar from "./components/MainSideBar";
import { SideBarWrapper } from "./Sidebar.styled";
import { userInfo } from "context/UserInfo.context";
import { ADMIN } from "constants/index";

type Props = {
  setOpenLogoutMenu: Dispatch<SetStateAction<boolean>>;
};

export default function Sidebar({ setOpenLogoutMenu }: Props) {
  const { userData, teacherSidebar, subjectSidebar } = userInfo();

  return (
    <SideBarWrapper>
      {userData?.roles.includes(ADMIN) && <AdminSideBar setOpenLogoutMenu={setOpenLogoutMenu} />}
      {teacherSidebar && <MainSideBar setOpenLogoutMenu={setOpenLogoutMenu} />}
      {subjectSidebar && <CoursesSideBar />}
    </SideBarWrapper>
  );
}
