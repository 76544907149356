// SESSION STORGE
export const SUBSCRIBER_FILTER_VALUES = "SUBSCRIBER_FILTER_VALUES";
export const OPENED_FILTERS = "OPENED_FILTERS";
export const SEARCH_VALUE_FILTER = "SEARCH_VALUE_FILTER";
export const PAGE_SIZE_FILTER = "PAGE_SIZE_FILTER";
export const PAGE_NUMBER_FILTER = "PAGE_NUMBER_FILTER";
export const TEACHER_BREADCRUMB_FILTER = "TEACHER_BREADCRUMB_FILTER";
export const TEACHER_CATEGORYID_FILTER = "TEACHER_CATEGORYID_FILTER";
export const SUBJECT_BREADCRUMB_FILTER = "SUBJECT_BREADCRUMB_FILTER";
export const SUBJECT_CATEGORYID_FILTER = "SUBJECT_CATEGORYID_FILTER";
export const SUBSCRIBERS_FILTER = "SUBSCRIBERS_FILTER";
export const CURRENCIES_FILTER = "CURRENCIES_FILTER";
export const USER_DEVICE_ID = "USER_DEVICE_ID";
export const SORTING_TEACHERS = "SORTING_TEACHERS";
export const SORTING_SUBJECTS = "SORTING_SUBJECTS";
