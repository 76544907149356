import styled, { css } from "styled-components";
import Slider from "@mui/material/Slider";

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  padding-top: 20px;
`;

export const FilterWrapper = styled.div<{ readonly onPopup?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const RemoveFilter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`;

export const FilterSelected = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray3};
  cursor: pointer;
`;

export const ArrowWrapper = styled.div`
  &:lang(ar) {
    transform: rotate(180deg);
  }
`;

export const FilterDropdown = styled.div<{ readonly opened?: boolean }>`
  display: flex;
  align-items: center;
  gap: 14px;
  z-index: 200;
  padding: 4px 12px;
  background: linear-gradient(180deg, #ffffff 0%, #fbfbfb 100%);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05);
  ${({ opened }) =>
    opened
      ? css`
          border: 0.7px solid ${({ theme }) => theme.colors.seagule};
        `
      : css`
          border: 0.7px solid ${({ theme }) => theme.colors.gray5_old};
        `}
  border-radius: 6px;
  cursor: pointer;
`;

export const FilterDropdownPortal = styled.div<{
  readonly opened?: boolean;
  readonly isSeagule?: boolean;
  readonly isDisabled?: boolean;
  readonly hasError?: string;
}>`
  display: flex;
  align-items: center;
  gap: 14px;
  z-index: 200;
  padding: 4px 12px;
  ${({ isSeagule, opened, theme, hasError }) =>
    !isSeagule &&
    css`
      background: ${opened ? "transparent" : theme.colors.gray6};
      ${hasError
        ? css`
            border: 1px solid ${theme.colors.red};
          `
        : css`
            border: 1px solid ${opened ? theme.colors.seagule : theme.colors.gray6};
          `}

      border-radius: 6px;
    `}
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;
          user-select: none;
        `
      : css`
          opacity: 1;
          cursor: pointer;
        `};
`;

export const FilterName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const AddplacmentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11.36px;
`;

export const AddPlacmentText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.seagule};
`;

export const SubscriperRange = styled.form<{ readonly subscriperFilter?: boolean }>`
  position: absolute;
  left: 0;
  top: 120%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 203px;
  height: 164px;
  padding: 16px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 5px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition: all 0.2s ease;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  z-index: 555;
  ${({ subscriperFilter }) =>
    subscriperFilter
      ? css`
          opacity: 1;
          visibility: visible;
          top: 120%;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          top: 100%;
        `}
`;

export const SubscriptionsRange = styled(SubscriperRange)<{ readonly subscriptionFilter?: boolean }>`
  ${({ subscriptionFilter }) =>
    subscriptionFilter
      ? css`
          opacity: 1;
          visibility: visible;
          top: 120%;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          top: 100%;
        `}
`;

export const FilterMinMaxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  input {
    max-width: 65.5px;
    border: 1px solid #a2d4f8;
    border-radius: 4px;
    text-align: center;
  }
`;

export const StyledSlider = styled(Slider)`
  .MuiSlider-rail {
    background: #efefef !important;
    border-radius: 2px !important;
  }
  .MuiSlider-track {
    background: #a2d4f8 !important;
    border: 1px solid #a2d4f8 !important;
  }
  .MuiSlider-thumb {
    width: 12px !important;
    height: 12px !important ;
    background: #45aaf2 !important;
  }
  .MuiSlider-thumb:hover,
  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: none !important;
  }
`;

export const ApplyResetFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;

export const ResetBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: none;
  &:disabled {
    background: ${({ theme }) => theme.colors.gray8};
  }
`;

export const ApplyBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  height: 44px;
  color: ${({ theme }) => theme.colors.seagule};
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 8px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border: none;
  &:disabled {
    background: ${({ theme }) => theme.colors.gray8};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ApplyBtnText = styled.span``;

export const CurrencysList = styled.div<{ readonly currencyFilter?: boolean }>`
  position: absolute;
  left: 0;
  padding: 8px;
  width: 135px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  transition: all 0.2s ease;
  z-index: 555;
  cursor: pointer;
  ${({ currencyFilter }) =>
    currencyFilter
      ? css`
          opacity: 1;
          visibility: visible;
          top: 120%;
        `
      : css`
          opacity: 0;
          visibility: hidden;
          top: 100%;
        `}
`;

export const CurrencyShortName = styled.span<{ readonly isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  padding: 8px;
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  border-radius: 8px;
  text-transform: uppercase;
  &:hover {
    background: ${({ theme }) => theme.colors.gray6};
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.seagule};
      &:hover {
        background: ${({ theme }) => theme.colors.gray6};
        color: ${({ theme }) => theme.colors.nero};
      }
    `}
`;

export const ResetAllButton = styled.button`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.seagule};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const NavbarDropdown = styled.ul<{
  readonly isOpen: boolean;
  position?: "right" | "left";
  isRecursive?: boolean;
}>`
  position: absolute;
  top: 18%;
  ${({ position }) =>
    position
      ? position === "left"
        ? css`
            right: 100%;
          `
        : css`
            right: 0;
          `
      : css`
          left: 50%;
        `}
  width: 100%;
  min-width: 132px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: ${({ position }) =>
    position ? (position === "left" ? "translate(100%, 10px)" : "translate(0, 10px)") : "translate(-50%, -10px)"};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 100;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &:lang(ar) {
    direction: rtl;
  }
  ${({ isOpen, position }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: ${position
        ? position === "left"
          ? "translate(100%, 26px)"
          : "translate(0, 51px)"
        : "translate(-50%, 46px)"};
    `}
`;

export const MainExploreDropdown = styled(NavbarDropdown)`
  min-width: 164px;
  max-width: 164px;
  padding: 8px;
  height: 236px;
  overflow-y: auto;
  cursor: pointer;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ isRecursive }) =>
    isRecursive &&
    css`
      position: static;
      transform: none;
    `}
`;

export const BreadcrumbPopupContainer = styled.div<{
  readonly depthNumber: number;
}>`
  position: absolute;
  transform: ${({ depthNumber }) =>
    depthNumber === 2 ? "translateX(-90px)" : depthNumber === 3 ? "translateX(-175px)" : "translateX(0)"};
  transition: ${({ depthNumber }) => (depthNumber >= 2 ? "all 0.3s ease" : "none")};
  z-index: 1000;
`;

export const CategoryError = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.red};
  margin-top: 8px;
`;

export const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 11;
`;

export const MainExploreMenuWrapper = styled.div<{
  readonly isOpen?: boolean;
  readonly breadCrumb?: string;
}>`
  position: absolute;
  top: 50%;
  width: 100%;
  cursor: pointer;
  height: ${({ isOpen }) => (isOpen ? "calc(100% + 46px)" : "100%")};
  & > ${MainExploreDropdown} {
    top: ${({ breadCrumb }) => (breadCrumb ? "10%" : "0")};
  }
`;

export const RecursiveMenuWrapper = styled.div<{
  readonly depth?: number;
  readonly breadCrumb?: string;
  readonly onPopup?: boolean;
}>`
  padding-left: 4px;
  position: absolute;
  top: ${({ breadCrumb }) => (breadCrumb ? "110%" : "118%")};
  ${({ onPopup }) =>
    onPopup &&
    css`
      transform: translateY(26px);
    `}
  z-index: 100;
  ${({ depth }) =>
    typeof depth !== "undefined" &&
    css`
      left: ${(depth + 1) * 164 + depth * 4}px;
    `}
`;

export const ExploreDropdownLi = styled.li`
  line-height: 1;
  text-align: start;
`;

export const ExploreDropdowLink = styled.span`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 1.4rem;
  line-height: 1.71428571;
  padding: 8px 14px 8px 8px;
  transition: 0.3s ease background-color;
  border-radius: 8px;
  direction: ltr;
  color: ${({ theme }) => theme.colors.nero};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
  .emojie {
    font-size: 2.4rem;
    line-height: 24px;
  }
`;

export const ExploreDropdownContent = styled.div<{
  readonly isFirstCat?: boolean;
  readonly isDisabled?: boolean;
  readonly currency?: string;
}>`
  display: flex;
  flex: 1;
  white-space: normal;
  font-weight: ${({ theme }) => theme.fontWeights.font500};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme, isFirstCat, isDisabled, currency }) =>
    isFirstCat
      ? `${currency ? `${isDisabled ? theme.colors.nero : theme.colors.gray3}` : theme.colors.nero}`
      : theme.colors.nero};
`;

export const CategoriesWrappers = styled.div``;

export const DisplayBreadcrumb = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 12px;
  background: ${({ theme }) => theme.colors.gray6};
  border-radius: 10px;
  margin-bottom: 8px;
`;

export const BreadcrumbText = styled.p<{ readonly isDisabled?: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

export const DeleteCategoryIcon = styled.div<{ readonly isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
`;
