import commonAR from "./ar/common.json";
import commonEN from "./en/common.json";
import commonTR from "./tr/common.json";

export const resources = {
  en: {
    common: commonEN,
  },
  ar: {
    common: commonAR,
  },
  tr: {
    common: commonTR,
  },
};

export const ns = ["common"];
