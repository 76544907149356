import styled from "styled-components";

export const PagesContainer = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  padding: 24px;
  width: 100%;
  /* height: 100vh; */
  min-height: 100vh;
  overflow-y: auto;
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #8e8e8e;
    &:focus {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #8e8e8e;
    }
  }
  .lolol {
    background: #efefef;
    border-radius: 10px;
    &::before {
      border: none;
    }
    &::after {
      border: none;
    }
    &:hover::before {
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
`;

export const SubjectsHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const LastUpdate = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 196px;
  height: 52px;
  color: ${({ theme }) => theme.colors.white};
  background: linear-gradient(180deg, #45aaf2 0%, #32a1f0 100%);
  border-radius: 8px;
  cursor: pointer;
`;

export const ButtonText = styled.button`
  padding: 0px;
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body18};
  line-height: 32px;
  color: ${({ theme }) => theme.colors.white};
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const SubjectCoulmn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const SubjectImageContainer = styled.div``;

export const SubjectImg = styled.img`
  min-width: 36px;
  max-width: 36px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 4px;
  z-index: 20;
`;

export const SubjectImgPlaceholder = styled.div`
  min-width: 36px;
  max-width: 36px;
  min-height: 28px;
  max-height: 28px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray5_old};
  margin-top: -36px;
`;

export const SubjectName = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const SubjectId = styled(SubjectName)`
  color: ${({ theme }) => theme.colors.gray3};
`;
