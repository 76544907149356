import Axios from "@Configs/Axios";
import { Subject } from "types/Subjects";

export const _GetTeacherSubjects = (id: string | undefined, category_id?: any) =>
  Axios.post(`/v1/subjects/instructors/${id}`, {
    limit: 100,
    skip: 0,
    ...(category_id !== "" && { category_id }),
  }).then(({ data }) => data.data);

export const _CreateSubject = (data: Subject) => Axios.post(`/v1/subjects`, data);

export const _UpdateSubject = (subjectId: number | null, data: Subject, teacherId: number | null) =>
  Axios.patch(`/v1/subjects/${subjectId}`, data, {
    headers: {
      subject_id: subjectId,
      instructor_id: teacherId,
    },
  });

export const _GetSubjectCourses = (id: number | null) =>
  Axios.post(`/v1/subjects/${id}/courses`, {
    limit: 100,
    skip: 0,
    fields: ["id", "name", "is_active", "display_name"],
  }).then(({ data }) => data.data);

export const _GetSubjectStatistics = (id: number | undefined) =>
  Axios.get(`/v1/subjects/${id}/statistics`).then(({ data }) => data.data);

export const _DeleteSubject = (id: string | undefined, instructorId: number | null) =>
  Axios.delete(`/v1/subjects/${id}`, {
    headers: {
      subject_id: id,
      instructor_id: instructorId,
    },
  });
