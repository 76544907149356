import {
  EditButtonText,
  EditSubjectBtn,
  OverviewHeaderContainer,
  RatingContainer,
  SubjectCard,
  SubjectImg,
  SubjectImgPlaceHolder,
  SubjectInfoContainer,
  SubjectLastUpdated,
  SubjectTitle,
  RatingNumber,
} from "./OverviewHeader.styled";
import Rating from "@mui/material/Rating";
import { Dispatch, Fragment, SetStateAction, useContext, useState } from "react";
import moment from "moment";
import { TeachersDatasContext } from "context/Teachers.context";
import { useTranslation } from "react-i18next";
import RatingStarYellow from "images/icons/RatingStarYellow";
import RatingEmptyStar from "images/icons/RatingEmptyStar";
import SubjectHeaderSkeleton from "@Components/Skeletons/SubjectHeaderSkeleton";
import { useTablesFilters } from "context/TablesFilters.context";
import { getSubjectName } from "utils/Strings";

type Props = {
  openPopup: Dispatch<SetStateAction<boolean>>;
};

const OverviewHeader = ({ openPopup }: Props) => {
  const {
    t: tCommon,
    i18n: { language },
  } = useTranslation("common");
  const { subjectStatistics, loadingSubjectStatistics } = useContext(TeachersDatasContext);
  const { isSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();

  return (
    <Fragment>
      {!loadingSubjectStatistics ? (
        <OverviewHeaderContainer>
          <SubjectCard>
            {subjectStatistics?.image !== null ? (
              <SubjectImg src={subjectStatistics?.image} alt="subject_name" />
            ) : (
              <SubjectImgPlaceHolder />
            )}
            <SubjectInfoContainer>
              <SubjectTitle>{getSubjectName(subjectStatistics, language)}</SubjectTitle>
              {subjectStatistics?.created_at !== null && (
                <SubjectLastUpdated>{`${tCommon("date_added")} ${moment(subjectStatistics?.created_at).format(
                  "DD/MM/YYYY"
                )}`}</SubjectLastUpdated>
              )}
              <RatingContainer>
                <Rating
                  name="read-only"
                  value={subjectStatistics ? subjectStatistics.rating : 0}
                  precision={0.5}
                  icon={<RatingStarYellow />}
                  emptyIcon={<RatingEmptyStar />}
                  readOnly
                />
                <RatingNumber>{subjectStatistics?.rating}</RatingNumber>
              </RatingContainer>
            </SubjectInfoContainer>
          </SubjectCard>
          <EditSubjectBtn
            onClick={() => {
              if (isSectionsSorted) {
                setWorningSectionsSortPopup(true);
              } else {
                openPopup(true);
              }
            }}
          >
            <EditButtonText>{tCommon("edit_subject")}</EditButtonText>
          </EditSubjectBtn>
        </OverviewHeaderContainer>
      ) : (
        <SubjectHeaderSkeleton />
      )}
    </Fragment>
  );
};

export default OverviewHeader;
