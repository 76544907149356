import styled, { css } from "styled-components";

export const IntroductionContainer = styled.div`
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;
export const IntroWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input[type="checkbox"]:after {
    display: block;
    content: url(require("./check-mark.svg"));
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  input[type="checkbox"]:checked {
    background-color: ${({ theme }) => theme.colors.seagule};
    border: none;
  }
  label {
    font-weight: ${({ theme }) => theme.fontWeights.font400};
    font-size: ${({ theme }) => theme.fontSizes.body16};
    line-height: 28px;
    color: ${({ theme }) => theme.colors.nero};
    cursor: pointer;
  }
`;

export const IntroDetailsWrapper = styled.div``;

export const IntroTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body14};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const IntroDurationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  font-size: ${({ theme }) => theme.fontSizes.body12};
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray2};
`;

export const DurationType = styled.span<{ readonly isEmpty?: boolean }>`
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.font400};
      font-size: ${({ theme }) => theme.fontSizes.body12};
      line-height: 20px;
      color: ${({ theme }) => theme.colors.red};
    `}
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 50%;
`;

export const DurationTime = styled.span``;

export const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 10%;
  justify-content: space-between;
`;

export const FreePrice = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0px 8px;
  background: rgba(69, 170, 242, 0.1);
  border-radius: 13.5627px;
  color: ${({ theme }) => theme.colors.seagule};
`;

export const EditIconWrapper = styled.span`
  width: 28px;
  height: 28px;
  background: ${({ theme }) => theme.colors.seagullNeutrals};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SectionActiosnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const SwitchContainer = styled.div<{ readonly isDisabled?: boolean }>`
  color: ${({ theme }) => theme.colors.seagule};
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const SwitchButton = styled.div<{
  readonly isActive?: boolean;
  readonly isDisabled?: boolean;
}>`
  background: ${({ theme }) => theme.colors.seagule};
  border-radius: 8px;
  width: 32px;
  height: 16px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  padding: 2px 3px;
  cursor: pointer;
  ${({ isActive }) =>
    isActive
      ? css`
          justify-content: flex-end;
          background: ${({ theme }) => theme.colors.seagule};
        `
      : css`
          justify-content: flex-start;
          background: ${({ theme }) => theme.colors.gray8};
        `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Switcher = styled.span`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  max-width: 10.67px;
  min-width: 10.67px;
  max-height: 10.67px;
  min-height: 10.67px;
  display: block;
  z-index: 55;
`;
