import { DebouncedInput } from "@Components/DebouncedInput";
import { FilterIcon } from "images/icons/FilterIcon";
import { SearchIcon } from "images/icons/SearchIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonTitle,
  FilterButton,
  FilterContainer,
  GlobalFilterContainer,
  ListHeader,
  TeacherListHeader,
} from "./TeachersHeader.styled";
import { useTablesFilters } from "context/TablesFilters.context";

const TeachersHeader = () => {
  const { t: tCommon } = useTranslation("common");
  const [keyDown, setKeyDown] = useState<any>();
  const {
    searchValueFilter,
    setSearchValueFilter,
    openedFilters,
    setOpenedFilters,
    pageNumberFilter,
    setPageNumberFilter,
  } = useTablesFilters();

  useEffect(() => {
    if (keyDown === "Backspace" && !searchValueFilter) {
      setPageNumberFilter(1);
    }
  }, [keyDown, searchValueFilter]);

  return (
    <TeacherListHeader>
      <ListHeader>{tCommon("instructors")}</ListHeader>
      <FilterContainer>
        <GlobalFilterContainer>
          <SearchIcon />
          <DebouncedInput
            value={searchValueFilter}
            onKeyDown={(e) => setKeyDown(e.key)}
            onChange={(value) => {
              setSearchValueFilter(String(value));
              if (value === searchValueFilter) {
                setPageNumberFilter(pageNumberFilter);
              } else {
                setPageNumberFilter(1);
              }
            }}
            placeholder={tCommon("search")}
          />
        </GlobalFilterContainer>
        <FilterButton showFilters={openedFilters} onClick={() => setOpenedFilters(!openedFilters)}>
          <FilterIcon showFilters={openedFilters} />
          <ButtonTitle>{tCommon("Search_filters")}</ButtonTitle>
        </FilterButton>
      </FilterContainer>
    </TeacherListHeader>
  );
};

export default TeachersHeader;
