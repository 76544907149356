export const DragDropIcon = ({ active, color }: { active?: boolean; color?: boolean }) => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26386 1.55197C3.26386 2.37043 2.60038 3.03391 1.78193 3.03391C0.963474 3.03391 0.299988 2.37043 0.299988 1.55197C0.299988 0.733524 0.963474 0.0700378 1.78193 0.0700378C2.60038 0.0700378 3.26386 0.733524 3.26386 1.55197ZM1.78193 4.51477C0.963474 4.51477 0.299988 5.17826 0.299988 5.99671C0.299988 6.81516 0.963474 7.47865 1.78193 7.47865C2.60038 7.47865 3.26386 6.81516 3.26386 5.99671C3.26386 5.17826 2.60038 4.51477 1.78193 4.51477ZM1.78193 8.95959C0.963474 8.95959 0.299988 9.62308 0.299988 10.4415C0.299988 11.26 0.963474 11.9235 1.78193 11.9235C2.60038 11.9235 3.26386 11.26 3.26386 10.4415C3.26386 9.62308 2.60038 8.95959 1.78193 8.95959ZM6.22675 3.03382C7.0452 3.03382 7.70869 2.37033 7.70869 1.55188C7.70869 0.733432 7.0452 0.0699463 6.22675 0.0699463C5.4083 0.0699463 4.74481 0.733432 4.74481 1.55188C4.74481 2.37033 5.4083 3.03382 6.22675 3.03382ZM6.22675 4.51477C5.4083 4.51477 4.74481 5.17826 4.74481 5.99671C4.74481 6.81516 5.4083 7.47865 6.22675 7.47865C7.0452 7.47865 7.70869 6.81516 7.70869 5.99671C7.70869 5.17826 7.0452 4.51477 6.22675 4.51477ZM6.22675 8.95959C5.4083 8.95959 4.74481 9.62308 4.74481 10.4415C4.74481 11.26 5.4083 11.9235 6.22675 11.9235C7.0452 11.9235 7.70869 11.26 7.70869 10.4415C7.70869 9.62308 7.0452 8.95959 6.22675 8.95959Z"
        fill={active ? (color ? "#45AAF2" : "#fff") : color ? "#45AAF2" : "#8E8E8E"}
      />
    </svg>
  );
};
