import { CommonButton } from "@Components/common/CommonButton";
import styled from "styled-components";

export const CourseHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray5_old};
`;

export const CourseHeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

export const CourseTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeights.font700};
  font-size: ${({ theme }) => theme.fontSizes.h6};
  line-height: 36px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const CoursePriceWraper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const CourseOldPrice = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  font-size: ${({ theme }) => theme.fontSizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
`;

export const CourseDiscountValue = styled(CourseOldPrice)`
  font-weight: ${({ theme }) => theme.fontWeights.font400};
  color: ${({ theme }) => theme.colors.gray3};
`;

export const CourseNewPrice = styled(CourseDiscountValue)`
  font-weight: ${({ theme }) => theme.fontWeights.font600};
  color: ${({ theme }) => theme.colors.gray3};
  text-decoration: line-through;
`;

export const CourseAddedDate = styled(CourseDiscountValue)``;

export const EditCourseWrapper = styled.div`
  min-width: 196px;
  max-width: 196px;
`;

export const EditCourseButton = styled(CommonButton)``;
