import { AddCourseButton, CoursesSaveSort } from "@Components/Sidebar/components/CoursesSideBar/CoursesSideBar.styled";
import { _SortingSectionsAndLectures } from "@Services/Sections";
import { TeachersDatasContext } from "context/Teachers.context";
import { BigPlusIcon } from "images/icons/BigPlusIcon";
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AddButtonText,
  AddButtonWrapper,
  AddingActionButtonsWrapper,
  CourseContentTitle,
  CourseContentWraper,
} from "./CourseContent.styled";
import { useTablesFilters } from "context/TablesFilters.context";
import { ADMIN, PRO_INSTRUCTOR } from "constants/index";
import { userInfo } from "context/UserInfo.context";

type Props = {
  openSectionPopup: Dispatch<SetStateAction<boolean>>;
  openLecturePopup: Dispatch<SetStateAction<boolean>>;
};

const CourseContent = ({ openSectionPopup, openLecturePopup }: Props) => {
  const { t: tCommon } = useTranslation("common");
  const [disableLectureBtn, setDisableLectureBtn] = useState(false);
  const { userData } = userInfo();
  const {
    courseDetails,
    sectionSortSaveBtn,
    setSectionSortSaveBtn,
    courseId,
    teacherId,
    sortedCollection,
    refetchCourseDetails,
  } = useContext(TeachersDatasContext);
  const { setIsSectionsSorted, setWorningSectionsSortPopup } = useTablesFilters();
  const [isThereSections, setIsThereSections] = useState([]);
  const isRegularInstructor = !userData?.roles.includes(ADMIN) && !userData?.roles.includes(PRO_INSTRUCTOR);

  useEffect(() => {
    if (courseDetails) {
      setIsThereSections(courseDetails?.sections.filter((section) => section?.is_introductory === false));
    }
  }, [courseDetails]);

  useEffect(() => {
    if (isThereSections?.length !== 0) {
      setDisableLectureBtn(false);
    } else {
      setDisableLectureBtn(true);
    }
  }, [isThereSections]);

  const saveSortingHandler = () => {
    _SortingSectionsAndLectures(courseId, teacherId, {
      sections: [...sortedCollection],
    });
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  const cancleSortingHandler = () => {
    refetchCourseDetails();
    setIsSectionsSorted(false);
    setWorningSectionsSortPopup(false);
  };

  return (
    <CourseContentWraper>
      <CourseContentTitle>{tCommon("course_content")}</CourseContentTitle>
      <AddingActionButtonsWrapper>
        {sectionSortSaveBtn ? (
          <CoursesSaveSort isSection>
            <AddCourseButton
              onClick={() => {
                setSectionSortSaveBtn(false);
                cancleSortingHandler();
              }}
            >
              {tCommon("cancel")}
            </AddCourseButton>
            <AddCourseButton
              onClick={() => {
                setSectionSortSaveBtn(false);
                saveSortingHandler();
              }}
            >
              {tCommon("save")}
            </AddCourseButton>
          </CoursesSaveSort>
        ) : (
          <Fragment>
            <AddButtonWrapper>
              <BigPlusIcon isDisabled={disableLectureBtn || isRegularInstructor} />
              <AddButtonText
                disabled={disableLectureBtn || isRegularInstructor}
                onClick={() => {
                  openLecturePopup(true);
                }}
              >
                {tCommon("add_lecture")}
              </AddButtonText>
            </AddButtonWrapper>
            <AddButtonWrapper>
              <BigPlusIcon isDisabled={isRegularInstructor} />
              <AddButtonText
                disabled={isRegularInstructor}
                onClick={() => {
                  openSectionPopup(true);
                }}
              >
                {tCommon("add_section")}
              </AddButtonText>
            </AddButtonWrapper>
          </Fragment>
        )}
      </AddingActionButtonsWrapper>
    </CourseContentWraper>
  );
};

export default CourseContent;
