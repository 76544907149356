import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
export const PageContent = styled.div`
  flex: 1;
  height: 100vh;
  overflow-y: scroll;
`;

export const BackGround = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 999;
`;
