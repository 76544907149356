import { Dispatch, Fragment, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Category } from "types/Category";
import { _GetCategories } from "@Services/Common";
import { useTranslation } from "react-i18next";
import LinkSkeleton from "@Components/Skeletons/LinkSkeleton";
import {
  ArrowWrapper,
  BackGround,
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  MainExploreMenuWrapper,
} from "@Components/Breadcrumbs/Breadcrumbs.styled";
import RecursiveExploreMenu from "@Components/Breadcrumbs/BreadcrumbMenu/RecursiveMenu";
import { RightArrowIcon } from "images/icons/RightArrowIcon";
import { FILTER_TYPE_TEACHERS } from "constants/index";
import { useTablesFilters } from "context/TablesFilters.context";

type Props = {
  isDropdownOpen: boolean;
  setIsDropdownOpen: Dispatch<SetStateAction<boolean>>;
  filterType: string;
};

const BreadcrumbsMenu = ({ isDropdownOpen, setIsDropdownOpen, filterType }: Props) => {
  const {
    i18n: { language },
  } = useTranslation("common");
  const {
    teacherBreadcrumbFilter,
    setTeacherBreadcrumbFilter,
    subjectBreadcrumbFilter,
    setSubjectBreadcrumbFilter,
    setTeacherCategoryIdFilter,
    setSubjectCategoryIdFilter,
  } = useTablesFilters();
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const getCatLink = (catName: string) =>
    filterType === FILTER_TYPE_TEACHERS ? setTeacherBreadcrumbFilter(catName) : setSubjectBreadcrumbFilter(catName);

  const { data: categories, isLoading: categoriesLoading } = useQuery(
    ["onboarding-teacher-categories", language],
    () => _GetCategories(),
    {
      refetchOnMount: false,
    }
  );

  useEffect(() => {
    setRootCategoryHover(undefined);
    if (window) {
      if (isDropdownOpen) {
        window.addEventListener("mousemove", checkHover);
      } else {
        window.removeEventListener("mousemove", checkHover);
      }
    }
  }, [isDropdownOpen]);

  const handleShallowRootCategory = (e: React.MouseEvent<Element, MouseEvent>, catId: number, catName: string) => {
    if (filterType === FILTER_TYPE_TEACHERS) {
      setTeacherCategoryIdFilter(catId);
    } else {
      setSubjectCategoryIdFilter(catId);
    }
    setIsDropdownOpen(false);
    getCatLink(catName);
  };

  const checkHover = useCallback((e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  }, []);

  return (
    <Fragment>
      {isDropdownOpen && <BackGround onClick={() => setIsDropdownOpen(false)} />}
      <MainExploreMenuWrapper isOpen={isDropdownOpen} breadCrumb={teacherBreadcrumbFilter || subjectBreadcrumbFilter}>
        <MainExploreDropdown isOpen={isDropdownOpen} position="left">
          {categoriesLoading
            ? [...new Array(5)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkSkeleton />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categories?.options.map((cat) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    onClick={(e) => handleShallowRootCategory(e, cat.id, cat.name)}
                    onMouseEnter={() => setRootCategoryHover(cat)}
                  >
                    <ExploreDropdownContent>{cat.name}</ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowWrapper>
                        <RightArrowIcon />
                      </ArrowWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </MainExploreMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && (
        <RecursiveExploreMenu
          depth={0}
          categoryType={filterType}
          hoveredCategory={rootCategoryHover}
          series={[rootCategoryHover.name]}
          setIsDropdownOpen={setIsDropdownOpen}
          setBreadCrumb={filterType === FILTER_TYPE_TEACHERS ? setTeacherBreadcrumbFilter : setSubjectBreadcrumbFilter}
          breadCrumb={filterType === FILTER_TYPE_TEACHERS ? teacherBreadcrumbFilter : subjectBreadcrumbFilter}
        />
      )}
    </Fragment>
  );
};

export default BreadcrumbsMenu;
